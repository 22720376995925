<uf-panel *ngIf="user" class="container bg-gray">

    <div class="uf-container-lg bg-solid" [class.pad-top]="!breadcrumbs.length">
        <div *ngIf="breadcrumbs.length" class="uf-app-bar transparent">
            <uf-breadcrumbs class="small left" [breadcrumbs]="breadcrumbs" [minimize]="true">
            </uf-breadcrumbs>
        </div>

        <div *ngIf="!loading" [formGroup]="form" class="uf-grid row-gap-lg medium">

            <div class=" uf-box col-12">

                <div class="uf-app-bar accent">
                    <div class="title">
                        {{shellTK.DetailsTitle | translate}}
                    </div>
                </div>

                <div class="uf-grid pad-sides pad-top pad-bottom">

                    <ng-template [ngIf]="status === userStatus.Pending">
                        <uf-message *ngIf="emailControl.dirty" class="col-12 warning" icon="warningSolid"
                            [content]="discoverTK.UserDetailsPendingStatusUnsavedMessage | translate">
                        </uf-message>
                        <uf-message class="col-12 info" icon="infoSolid"
                            [content]="discoverTK.UserDetailsPendingStatusMessage | translate">
                            <button *ngIf="canInvite && user.email" class=" uf-button small right" (click)="reInvite()"
                                [disabled]="emailControl.dirty">
                                {{discoverTK.UserActionResendInvite | translate}}
                            </button>
                        </uf-message>
                    </ng-template>

                    <uf-user-description *ngIf="userInfo" class="col-12" [userInfo]="userInfo"
                        [filterBy]="descriptionFilter">
                    </uf-user-description>

                    <uf-text *ngIf="!disabledLookup[userInfoControlKeys.Username]" class="col-12 col-md-6"
                        [label]="labelDictionary[userInfoControlKeys.Username]"
                        [formControlName]="userInfoControlKeys.Username">
                        <span class="suffix">*</span>
                    </uf-text>
                    <uf-text *ngIf="!disabledLookup[userInfoControlKeys.FirstName]" class="col-12 col-md-6"
                        [label]="labelDictionary[userInfoControlKeys.FirstName]"
                        [formControlName]="userInfoControlKeys.FirstName">
                        <span class="suffix">*</span>
                    </uf-text>
                    <uf-text *ngIf="!disabledLookup[userInfoControlKeys.LastName]" class="col-12 col-md-6"
                        [label]="labelDictionary[userInfoControlKeys.LastName]"
                        [formControlName]="userInfoControlKeys.LastName">
                        <span class="suffix">*</span>
                    </uf-text>
                    <uf-text *ngIf="!disabledLookup[userInfoControlKeys.Email]" class="col-12 col-md-6"
                        [label]="labelDictionary[userInfoControlKeys.Email]"
                        [formControlName]="userInfoControlKeys.Email">
                        <span class="suffix" *ngIf="emailRequired">*</span>
                    </uf-text>
                    <uf-text *ngIf="!disabledLookup[userInfoControlKeys.Phone]" class="col-12 col-md-6"
                        [label]="labelDictionary[userInfoControlKeys.Phone]"
                        [formControlName]="userInfoControlKeys.Phone">
                    </uf-text>
                    <uf-company-input *ngIf="companyControl && !disabledLookup[userInfoControlKeys.Company]"
                        class="col-12 col-md-6" [formControlName]="userInfoControlKeys.Company"
                        [label]="labelDictionary[userInfoControlKeys.Company]">
                    </uf-company-input>
                </div>

                <div class="uf-grid pad-bottom" *ngIf="!user.isExternal">
                    <uf-password-change class="col-12" *ngIf="passwordControl" [user]="userInfo"
                        [passwordControl]="passwordControl" [oldPasswordControl]="oldPasswordControl"
                        [changePasswordOnNextLoginControl]="changePasswordOnNextLoginControl">
                    </uf-password-change>

                    <uf-status-change class="col-12" *ngIf="lastActivationReasonControl && isActiveControl"
                        [lastActivationReasonControl]="lastActivationReasonControl" [isActiveControl]="isActiveControl"
                        [user]="userInfo">
                    </uf-status-change>
                </div>
            </div>

            <div *ngIf="managerInfo != null" class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">
                        {{ commonTK.ManagerLabel | translate }}
                    </div>
                </div>
                <div class="uf-grid pad">
                    <uf-description-list class="col-12" [items]="managerInfo"></uf-description-list>
                </div>
            </div>

            <div *ngIf="userAuthProviders?.length" class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">
                        {{ discoverTK.UserAuthProviderDetailTitle | translate }}
                    </div>
                </div>
                <div class="pad" *ngFor="let provider of userAuthProviders"
                    [class.border-bottom]="userAuthProviders.length">
                    <uf-description-list>
                        <dt>{{ discoverTK.UserAuthProviderTypeLabel | translate }}:</dt>
                        <dd>{{ provider.type }}</dd>
                        <dt>{{ discoverTK.UserAuthProviderTenantLabel | translate }}:</dt>
                        <dd>{{ provider.tenant }}</dd>
                        <ng-container *ngIf="authProviderInfo[provider.tenant].roles.length">
                            <dt>{{ commonTK.RolesLabel | translate }}:</dt>
                            <dd>{{ authProviderInfo[provider.tenant].roles.join(', ') }}</dd>
                        </ng-container>
                        <ng-container *ngIf="authProviderInfo[provider.tenant].claims.length">
                            <dt>{{ commonTK.ClaimsLabel | translate }}:</dt>
                            <dd>
                                <span *ngFor="let claim of authProviderInfo[provider.tenant].claims">
                                    {{ claim }}<br></span>
                            </dd>
                        </ng-container>
                        <ng-container *ngIf="authProviderInfo[provider.tenant].units?.length">
                            <dt>Units:</dt>
                            <dd>
                                <span *ngFor="let unit of authProviderInfo[provider.tenant].units">
                                    {{ unit }}<br></span>
                            </dd>
                        </ng-container>
                    </uf-description-list>
                </div>
            </div>

            <div class="uf-box col-12 gap-top-sm" *ngIf="unitsControl?.enabled">
                <div class="uf-app-bar flat accent">
                    <div class="title">{{ labelDictionary[userInfoControlKeys.Units] }}</div>
                </div>
                <div class="uf-grid pad-bottom">
                    <uf-hierarchy-input class=" col-12" [control]="unitsControl"
                        [unselectableUnits]="lockedProperties.units">
                    </uf-hierarchy-input>
                </div>
            </div>

            <div class="uf-box col-12" *ngIf="rolesControl.enabled">
                <div class="uf-app-bar accent">
                    <div class="title">{{ commonTK.RolesLabel | translate }}</div>
                </div>
                <div class="pad">
                    <uf-role-input [formControlName]="userInfoControlKeys.Roles" [lockedRoles]="lockedProperties.roles">
                    </uf-role-input>
                </div>
            </div>

            <div class="uf-box col-12" *ngIf="canListClaims">
                <div class="uf-app-bar accent">
                    <div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
                </div>
                <div class="uf-grid">
                    <uf-user-description *ngIf="claimDescriptionFilter.length" [userInfo]="userInfo"
                        [filterBy]="claimDescriptionFilter" class="col-12 pad border-bottom">
                    </uf-user-description>
                    <uf-claim-editor *ngIf="claimsControl?.enabled" class="col-12" [control]="claimsControl"
                        [width]="fieldWidth.Half">
                    </uf-claim-editor>
                </div>
            </div>

            <div class="uf-box col-12 gap-lg-top" *ngIf="isMyProfile && pinService.isEnabled()">
                <div class="uf-app-bar accent">
                    <div class="title">
                        {{discoverTK.PINSettingsLabel | translate}}
                    </div>
                </div>
                <ud-pin-input class="uf-grid pad" [pinTimeout]="pinTimeout"></ud-pin-input>
            </div>
        </div>

        <div class="uf-form-actions gap-top">

            <button *ngIf="isMyProfile && form.enabled" type="button" (click)="save()" class="uf-button primary right">
                {{sharedTermsTK.ActionSave | translate}}
            </button>

            <ng-template *ngIf="!isMyProfile">
                <button (click)="back()" class="uf-button tertiary left">
                    {{sharedTermsTK.ActionCancel | translate}}
                </button>
                <button *ngIf="canDelete" (click)="delete()" type="button" class="uf-button">
                    {{sharedTermsTK.ActionDelete | translate}}
                </button>
                <button *ngIf="form.enabled" (click)="save()" type="button" class="uf-button primary">
                    {{sharedTermsTK.ActionSaveAndClose | translate}}
                </button>
            </ng-template>
        </div>
    </div>

</uf-panel>