import { Component } from '@angular/core';
import { Compound, Definition } from '@unifii/sdk';

import { ViewContent } from './content-types';


@Component({
    selector: 'us-view',
    templateUrl: './view.html'
})
export class ViewComponent implements ViewContent {

    definition: Definition;
    compound: Compound;

}
