
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterEntry, FilterValue, TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, CommonTranslationKey, SharedTermsTranslationKey, ToastService, UfControlGroup, UfFormBuilder, ValidatorFunctions
} from '@unifii/library/common';
import { Claim, ClaimConfig, Client, Company, CompanyClient, CompanyStatus, FieldType, FieldWidth, Option, PermissionAction, UserStatus } from '@unifii/sdk';
import { ClaimEditorController, FormPermissionController } from '@unifii/user-provisioning';

import { CompanyContent } from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';
import { CompanyFormPermissionController } from 'shell/table/companies/company-form-permission-controller';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ClaimSourceType } from '@unifii/user-provisioning';


enum ControlKeys {
    Name = 'name',
    Status = 'status',
    Claims = 'claims'
}

@Component({
    templateUrl: './company-form.html',
    providers: [
        { provide: FormPermissionController, useClass: CompanyFormPermissionController },
        ClaimEditorController
    ]
})
export class CompanyFormComponent implements OnInit, EditedData, CompanyContent {

    readonly sharedTK = SharedTermsTranslationKey;
    readonly commonTK = CommonTranslationKey;
    readonly shellTK = ShellTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;
    readonly controlKeys = ControlKeys;
    readonly fieldWidth = FieldWidth;

    company?: Company; // set by content resolver
    claimConfig: ClaimConfig[];
    edited: boolean;
    error: AppError;
    claims: Claim[] = [];
    form: UfControlGroup;
    breadcrumbs: Breadcrumb[] = [];
    companyStatusOptions: Option[] = Object.keys(CompanyStatus).map(v => ({ identifier: v, name: v }));

    // Descriptions
    descriptionFilter: ControlKeys[];
    claimDescriptionFilter: string[];

    private name: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private companyClient: CompanyClient,
        private client: Client,
        private translate: TranslateService,
        private toastService: ToastService,
        private ufb: UfFormBuilder,
        private errorService: ErrorService,
        private claimEditorController: ClaimEditorController,
        @Inject(Authentication) private auth: Authentication,
        @Optional() private detailComponent: TableDetailComponent,
        @Inject(TablePageConfig) private tableConfig: TablePageConfig,
        @Inject(FormPermissionController) private permissionCtrl: CompanyFormPermissionController,
        @Optional() @Inject(TableContainerManager) private tableManager: TableContainerManager<Company, FilterValue, FilterEntry>
    ) { }

    get claimControl(): UfControlGroup {
        return this.form?.get(ControlKeys.Claims) as UfControlGroup;
    }

    async ngOnInit() {
        const companyStatusField = {
            identifier: 'status',
            label: 'Status',
            type: FieldType.Choice,
            options: [
                { identifier: UserStatus.Active, name: this.translate.instant(CommonTranslationKey.UserActiveLabel) },
                { identifier: UserStatus.Inactive, name: this.translate.instant(CommonTranslationKey.UserInactiveLabel) },
                { identifier: UserStatus.Pending, name: this.translate.instant(CommonTranslationKey.UserPendingLabel) },
            ]
        };

        if (companyStatusField && companyStatusField.options) {
            this.companyStatusOptions = companyStatusField.options;
        }

        if (this.company != null) {
            this.claims = this.company.claims;
            this.name = this.company.name;
        }

        this.breadcrumbs = this.getBreadcrumbs();
        this.form = await this.getControlGroup(this.company);

        this.claimDescriptionFilter = this.getDisableControlKeys(this.claimControl).map(key => `claims.${key}`);

        // Only show description list for existing companies
        if (this.company != null) {
            this.descriptionFilter = this.getDisableControlKeys(this.form) as ControlKeys[];
        }
        if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyClaimsPath(), PermissionAction.List).granted) {
            this.claimConfig = await this.client.getCompanyClaims();
        }
        this.form.valueChanges.subscribe(() => this.edited = true);
    }

    back(url?: string) {
        if (url) {
            this.router.navigateByUrl(url);
            return;
        }

        this.router.navigate(['..'], { relativeTo: this.route });
    }

    async save() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        const company = this.form.getRawValue() as Company;
        company.claims = this.claims;

        try {
            if (this.company) {
                company.id = this.company.id;
                await this.companyClient.updateCompany(company);
                this.toastService.success(this.translate.instant(this.discoverTK.CompanySavedMessage));
                this.tableManager?.updateItem?.next(company);
            } else {
                await this.companyClient.addCompany(company);
                this.toastService.success(this.translate.instant(this.discoverTK.CompanyCreatedMessage));
                this.tableManager?.reload?.next();
            }

            this.edited = false;
            this.back();
        } catch (error) {
            this.toastService.error(error.message || this.errorService.unknownErrorMessage);
        }

    }

    private getBreadcrumbs(): Breadcrumb[] {
        const name = this.tableConfig.table.title;
        const breadCrumbs: Breadcrumb[] = [];
        breadCrumbs.push({ name, urlSegments: [this.detailComponent != null ? '../../' : '../'] });

        if (this.detailComponent) {
            breadCrumbs.push({ name: this.detailComponent.title, urlSegments: ['../'] });
        }

        breadCrumbs.push({ name: this.name ?? this.translate.instant(SharedTermsTranslationKey.NewLabel) });
        return breadCrumbs;
    }

    private async getControlGroup(company?: Company) {
        return this.ufb.group({
            [ControlKeys.Name]: [{ value: company?.name || '', disabled: !this.permissionCtrl.inputEnabled(ControlKeys.Name, company) }, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [ControlKeys.Status]: [{ value: company?.status || '', disabled: !this.permissionCtrl.inputEnabled(ControlKeys.Status, company) }, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [ControlKeys.Claims]: await this.claimEditorController.buildRoot(ClaimSourceType.Company, company?.claims, company)
        });
    }

    private getDisableControlKeys(controlGroup: UfControlGroup): (string | ControlKeys)[] {
        return Object.keys(controlGroup.controls)
            .reduce((keys, key) => {
                if (controlGroup.get(key)?.disabled) {
                    keys.push(key as string | ControlKeys);
                }
                return keys;
            }, [] as (string | ControlKeys)[]);
    }

}