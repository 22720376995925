export enum ShellTranslationKey {
    FormBucketDialogAddFormTitle = 'form_bucket_dialog_add_form_title',
    FilterInputsTitle = 'filter_inputs_title',
    DetailsTitle = 'details_title',
    TableDetailItemLink = 'table_detail_item_link',
    ReportIncrementDaysLabel = 'report_days_label',
    ReportIncrementWeeksLabel = 'report_weeks_label',
    ReportIncrementMonthsLabel = 'report_months_label',
    ReportRangeCustomLabel = 'report_range_custom_label',
    ReportRangeWeekLabel = 'report_range_week_label',
    ReportRangeMonthLabel = 'report_range_month_label',
    ReportRange3MonthsLabel = 'report_range_3_month_label',
    ReportRange6MonthLsabel = 'report_range_6_month_label',
    ReportErrorRetrieveBucketData = 'report_error_retrieve_bucket_data',
    ReportErrorMaxAllowedDateRange = 'report_error_max_allowed_date_range',
    ReportErrorStartDateAfterEndData = 'report_error_start_date_after_end_date',
    ReportDatasetAverage = 'report_dataset_average',
    ReportFilterDateRangeLabel = 'report_filter_date_range_label',
    ReportFilterStartDateLabel = 'report_filter_start_date_label',
    ReportFilterEndDateLabel = 'report_filter_end_date_label',
    ReportFilterDateIntervalsLabel = 'report_filter_date_intervals_label',
    ReportResultDatasetsLabel = 'report_result_datasets_label',
    ReportResultTotalLabel = 'report_result_total_label',
    ReportResultNoDataLabel = 'report_result_no_data_label',
    ContentNodeHomePageTitle = 'content_node_home_page_title',
    ContentNodeHomePageMessage = 'content_node_home_page_message',
    DashboardBucketActionSeeMore = 'dashboard_bucket_action_see_more',
    BarCodeActionScan = 'bar_code_action_scan',
    BarCodeScanLabel = 'bar_code_scan_label',
    BarCodeStatusProgress = 'bar_code_status_progress',
    BarCodeStatusComplete = 'bar_code_status_complete',
    BarCodeStatusError = 'bar_code_status_error',
    FormTriggerErrorFormNotFound = 'form_trigger_error_form_not_found',
    FormTriggerErrorTransitionActionNotAvailable = 'form_trigger_error_transition_action_not_available',
    FormTriggerErrorTransitionNotExecuted = 'form_trigger_error_transition_not_executed',
    FormTriggerErrorSaveFailed = 'form_trigger_error_save_failed',
    FormTriggerFeedbackError = 'form_trigger_feedback_error',
    FormTriggerFeedbackSaved = 'form_trigger_feedback_saved',
    FormTriggerTitle = 'form_trigger_title',
    FormFeedbackSaved = 'form_feedback_saved',
    FormOriginatedBy = 'form_originated_by',
    MenuLinkHomeLabel = 'menu_link_home_label',
    LogOutModalTitle = 'log_out_modal_title',
    LogOutModalMessage = 'log_out_modal_message',
    LogOutModalActionConfirm = 'log_out_modal_action_confirm',
    ChangeLanguageTitle = 'change_language_title',
    NoLanguageAvailableTitle = 'no_language_available_title',
    NoLanguageAvailableMessage = 'no_language_available_message',
    ValidatorLanguageRequired = 'validator_language_required',
    ApplySelectedLanguageModalTitle = 'apply_selected_language_modal_title',
    ApplySelectedLanguageModalMessage = 'apply_selected_language_modal_message',
    AuthenticationPasswordChangeRequiredLabel = 'authentication_password_change_required_label',
    DownloadCompatibleBrowserMessage = 'download_compatible_browser_message',
    UnsavedChangesModalTitle = 'unsaved_changes_modal_title',
    UnsavedChangesModalMessage = 'unsaved_changes_modal_message',
    PushNotificationModalTitle = 'push_notification_modal_title',
    DeleteModalTitle = 'delete_modal_title',
    DeleteModalMessage = 'delete_modal_message',
    DeleteModalSuccess = 'delete_modal_success',
    DeleteModalFail = 'delete_modal_fail',
    ErrorContentNotFound = 'error_content_not_found',
    ErrorNullReference = 'error_null_reference',
    ErrorNotFound = 'error_not_found',
    ErrorLoad = 'error_load',
    ErrorDataLoad = 'error_data_load',
    ErrorNoInternet = 'error_no_internet',
    ErrorCheckConnectivity = 'error_check_connectivity',
    ErrorSave = 'error_save',
    ErrorAccessDenied = 'error_access_denied',
    ErrorRequestUnauthorized = 'error_request_unauthorized',
    ErrorRequestForbidden = 'error_request_forbidden',
    ErrorRequestNotFound = 'error_request_not_found',
    ErrorNoAllowedProjects = 'error_no_allowed_projects',
    ErrorInvalidClient = 'error_invalid_client',
    ErrorUnhandled = 'error_unhandled',
    ErrorCustomNodeNotFoundTitle = 'error_custom_node_not_found_title', // Custom Node
    ErrorCustomNodeNotFoundMessage = 'error_custom_node_not_found_message' // The requested component is not available for this application
}