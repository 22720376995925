import { format, set } from 'date-fns';

import { Injectable } from '@angular/core';
import {
    Context, ContextProvider, DateTimeFunctions, ExpressionFunctions, ExpressionParser, FnsDatetime, FnsDatetimeUtc
} from '@unifii/library/common';
import { AstNode, NodeType } from '@unifii/sdk';


/**
 * @description
 * ASTNodeExpressionParser is utility class that replaces expression AstNode right side value
 * with it's evaluated expression result
 */
@Injectable({
    providedIn: 'root'
})
export class ASTExpressionParser {

    constructor(
        private expressionParser: ExpressionParser,
        private contextProvider: ContextProvider
    ) { }

    parse(input?: AstNode | null): AstNode | undefined {

        if (input == null) {
            return;
        }

        const appContext = this.contextProvider.get();
        const now = format(set(new Date(), { seconds: 0 }), FnsDatetime);
        const context: Context = Object.assign({ self: null, root: {}, now }, appContext);

        // Need deep copy
        const result = JSON.parse(JSON.stringify(input));
        this.transform(result, context);

        return result;
    }

    private transform(node: AstNode, context: Context) {

        if (node.type === NodeType.Expression) {
            node.type = NodeType.Value;
            node.value = this.expressionParser.resolve(node.value, context, {}, 'Filter', ExpressionFunctions);
        }

        // Deprecated should be using NodeType.Expression
        if (node.expression) {
            const expression = (node.args as AstNode[])[1].value;
            (node.args as AstNode[])[1].value = this.expressionParser.resolve(expression, context, {}, 'Filter', ExpressionFunctions);
        }

        // Normalize non-flat value
        if (node.type === NodeType.Value) {
            if (node.value && node.value.tz && node.value.value) {
                // ZonedDateTime
                const date = DateTimeFunctions.parseFallbackISO(node.value.value, FnsDatetimeUtc);
                if (date) {
                    node.value = format(date, FnsDatetime);
                } else {
                    node.value = null;
                }
            }
        }

        if (node.args) {
            for (const arg of node.args) {
                this.transform(arg, context);
            }
        }
    }

}
