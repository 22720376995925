import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterEntry, FilterValue, TableContainerManager } from '@unifii/components';
import { SharedTermsTranslationKey } from '@unifii/library/common';

import { NewItemPath } from 'discover/discover-constants';

import { TableData } from './models';


@Component({
    template: `
        <div ufMasterDetail class="table-page">
            <uf-table-container ufSyncRoute (addItem)="add($event)" class="accent list-md">
                <a *ngIf="prevUrl" info (click)="back(prevUrl)" class="uf-button tertiary left align-left">
                    <uf-icon name="arrowBack"></uf-icon>
                    {{sharedTK.ActionBack | translate}}
                </a>
            </uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `
})
export class TablePageComponent {

    readonly sharedTK = SharedTermsTranslationKey;

    prevUrl?: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(TableContainerManager) private manager: TableContainerManager<TableData, FilterValue, FilterEntry>
    ) {
        const { params } = this.route.snapshot;
        this.prevUrl = params.prevUrl;
    }

    async add(identifier?: string) {
        if (this.manager.addActionCallback != null) {
            this.manager.addActionCallback(identifier);
        } else {
            this.router.navigate([NewItemPath], { relativeTo: this.route });
        }
    }

    back(prevUrl: string) {
        this.router.navigateByUrl(prevUrl);
    }

}
