import { InjectionToken } from '@angular/core';
import { DataPropertyDescriptor } from '@unifii/library/common';
import { Option, Table, TableSourceType } from '@unifii/sdk';


/**
 * @description
 * Configuration required by the TablePageComponent and associated classes
 */
export interface TablePageConfig {
    sourceType: TableSourceType;
    table: Table;
    isSearchable: boolean;
    propertyDescriptors: Map<string, DataPropertyDescriptor>;
    addOptions?: Option[]; // options available when add action triggered
    // bucket specific config
    bucket?: string;
    hasRollingVersion?: boolean;
}

export const TablePageConfig = new InjectionToken<TablePageConfig>('TablePageConfig');
