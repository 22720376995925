
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { CompanyClient, PermissionAction } from '@unifii/sdk';

import { AuthService } from 'shell/services/auth.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';

import { DiscoverContext } from 'discover/discover-context';


@Injectable()
export class CompanyGuard implements CanActivate {

    constructor(
        private context: DiscoverContext,
        private companyClient: CompanyClient,
        @Inject(Authentication) private auth: AuthService
    ) { }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        const companyId = this.auth.userInfo?.company?.id;

        try {
            if (
                companyId &&
                this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyPath(companyId), PermissionAction.Read).granted
            ) {
                const company = await this.companyClient.getCompany(companyId);
                this.context.company = company;
            }
        } catch (err) { }
        return true;
    }


}