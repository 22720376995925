

import { Component, Inject, OnInit } from '@angular/core';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { Compound, ImageProfile, PublishedContent } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { NavigationService } from 'shell/nav/navigation.service';


interface Link {
    label: string;
    href?: string;
    link?: string;
    imgUrl?: string;
}

@Component({
    selector: 'ud-directory-template',
    templateUrl: './directory-template.html',
    styleUrls: ['./directory-template.less']
})
export class DirectoryTemplateComponent implements OnInit {

    logo: string | null;
    description: string | undefined;
    links: Link[] = [];
    compound: Compound; // Set by data Resolver

    constructor(
        private display: DisplayService,
        private errorService: ErrorService,
        private nav: NavigationService,
        @Inject(PublishedContent) private content: PublishedContent
    ) {
    }

    ngOnInit() {
        this.logo = this.getImage(this.compound.icon, { width: 600 });
        this.description = this.compound.description || null;
        this.links = this.getLinks(this.compound.buttons);
    }

    canAccess(link: Link): boolean {
        if (link.link) {
            const segments = link.link.split('/');
            const nodeId = segments.length > 2 ? segments[2] : null;
            const node = this.nav.getNode(nodeId);
            if (node) {
                return this.nav.canAccessNode(node);
            }
        }
        return true;
    }

    private getLinks(buttons: any[]): Link[] {

        return (buttons || []).map(btn => ({
                label: btn.label,
                href: this.getHref(btn.link) || undefined,
                link: this.getLink(btn.link) || undefined,
                imgUrl: this.getImage(btn.icon, { width: 300 }) || undefined
            }));

    }

    private getLink(link: string | undefined): string | null {

        if (link != null && !link.startsWith('https://') && !link.startsWith('http://')) {
            return link;
        }

        return null;
    }

    private getHref(href: string | undefined): string | null {

        if (href != null && (href.startsWith('https://') || href.startsWith('http://'))) {
            return href;
        }

        return null;
    }

    private getImage(profile: ImageProfile[] | undefined, options: { width: number }): string | null {

        if (profile == null || profile[0] == null || profile[0].url == null) {
            return null;
        }

        return this.content.buildImageUrl(profile[0], options);
    }
}
