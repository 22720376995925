import { Injectable, Type } from '@angular/core';
import { FormField } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';
import { FieldType } from '@unifii/sdk';

import { BarcodeMultitextFieldComponent } from './barcode/barcode-multitext-field.component';
import { BarcodeTextFieldComponent } from './barcode/barcode-text-field.component';


@Injectable()
export class UdFormComponentRegistry extends UfFormComponentRegistry {

    get(type: FieldType, tags: Array<string> = []): Type<FormField> {

        // BarCode Text reader
        if (type === FieldType.Text && tags.find(v => v.indexOf('bc:') >= 0) != null) {
            return BarcodeTextFieldComponent;
        }

        // BarCode Multitext reader
        if (type === FieldType.MultiText && tags.find(v => v.indexOf('bc:') >= 0) != null) {
            return BarcodeMultitextFieldComponent;
        }

        return super.get(type, tags);
    }
}