import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    ContextProvider, DataPropertyDescriptor, DataPropertyInfoService, SharedTermsTranslationKey
} from '@unifii/library/common';
import {
    CompanyClient, ErrorType, PermissionAction, Provisioning, PublishedContent, Table, TableDetail, TableSourceType
} from '@unifii/sdk';

import { AppError } from 'shell/errors/errors';
import { BetterFormService } from 'shell/form/better-form.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableData } from 'shell/table/models';

import { EditPath } from 'discover/discover-constants';
import { ContentDataResolver } from 'shell/content/content-data-resolver';


export interface TableDetailData {
    table: Table;
    detail: TableDetail;
    propertyDescriptors: Map<string, DataPropertyDescriptor>;
    item: TableData;
    itemLink?: {
        name: string;
        urlSegments: any[];
    }; // Router link that links to source item
}

@Injectable()
export class TableDetailResolver implements Resolve<TableDetailData | undefined> {

    constructor(
        private provisioning: Provisioning,
        private formService: BetterFormService,
        @Inject(Authentication) private auth: Authentication,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        @Inject(ContentDataResolver) private dataResolver: ContentDataResolver,
        private translate: TranslateService,
        private router: Router,
        private companyClient: CompanyClient
    ) { }

    async resolve(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<TableDetailData | undefined> {

        try {
            const tableIdentifier = route.parent?.paramMap.get('identifier') as string;
            const itemId = route.paramMap.get('id') as string;
            if (!tableIdentifier || !itemId) {
                throw this.notFoundError;
            }

            const tableData = await this.dataResolver.getTableData(tableIdentifier);
            const table = tableData.tablePageConfig.table;

            if (table.detail == null) {
                console.error(`Table "${tableIdentifier}" missing page detail`);
                throw this.notFoundError;
            }

            const detail = table.detail;

            switch (table.sourceType) {
                case TableSourceType.Users: return await this.getUserDetailData(itemId, table, detail, tableData.tablePageConfig.propertyDescriptors);
                case TableSourceType.Company: return await this.getCompanyDetailData(itemId, table, detail, tableData.tablePageConfig.propertyDescriptors);
                case TableSourceType.Bucket: return await this.getBucketDetailData(itemId, table, detail, tableData.tablePageConfig.propertyDescriptors);
            }

        } catch (e) {
            this.router.navigate(['/error', { type: e.type }]);
        }
        return;
    }

    private async getUserDetailData(id: string, table: Table, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

        const item = await this.provisioning.getUser(id);
        let itemLink;

        if (this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+id), PermissionAction.Update, item, this.contextProvider.get()).granted) {
            itemLink = {
                name: this.translate.instant(SharedTermsTranslationKey.ActionEdit),
                urlSegments: [EditPath]
            };
        }

        return {
            table,
            detail,
            item,
            propertyDescriptors,
            itemLink
        };
    }

    private async getCompanyDetailData(id: string, table: Table, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

        table.hideExport = true;
        const item = await this.companyClient.getCompany(id);
        let itemLink;

        if (this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(id), PermissionAction.Update, item, this.contextProvider.get()).granted) {
            itemLink = {
                name: this.translate.instant(SharedTermsTranslationKey.ActionEdit),
                urlSegments: [EditPath]
            };
        }

        return {
            table,
            detail,
            item,
            propertyDescriptors,
            itemLink
        };
    }

    private async getBucketDetailData(id: string, table: Table, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

        this.formService.bucket = table.source as string;
        const item = await this.formService.get(id);

        return {
            table,
            detail,
            item,
            propertyDescriptors,
            itemLink: {
                name: this.translate.instant(SharedTermsTranslationKey.ActionView),
                urlSegments: [EditPath]
            }
        };
    }

    private get notFoundError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorContentNotFound), ErrorType.NotFound);
    }

}