import { FileUploader } from '@unifii/library/common';
import { Client, Progress } from '@unifii/sdk';

import { OfflineQueue } from './offline-queue';


export class OfflineFileUploader implements FileUploader {

    constructor(private offlineQ: OfflineQueue, private dataId: string) { }

    upload(input: File): Promise<Progress> {
        return this.offlineQ.saveAttachment(this.dataId, Client.generateUUID(), input);
    }

    async getUrl(_id: string): Promise<string> {
        return null as any as string;
    }
}