

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Dictionary } from '@unifii/sdk';

import { TenantSettingsService } from 'shell/services/tenant-settings.service';

import { Config } from 'config';


/**
 * Ensure tenant settings are present for the following routes
 *  - Main "/"
 *  - Login "/login"
 *  - Projects "/login/projects"
 *  - Request password change "/login/..."
 */
@Injectable()
export class TenantGuard implements CanActivate {

    constructor(
        private router: Router,
        @Inject(Config) private config: Config,
        private tenantSettings: TenantSettingsService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

        // catch iss param
        const { iss, tenant } = route.queryParams;
        if (iss && tenant) {
            this.tenantSettings.setTenant(tenant);
        }

        // successfully synced tenant settings
        try {
            await this.tenantSettings.sync();
            return true;
        } catch (err) {
            // Not enough tenant info provided
            this.redirect(route.params);
            return false;
        }
    }

    private redirect(params: Dictionary<string>) {
        this.config.unifii.tenant = undefined;
        this.config.unifii.tenantSettings = undefined;

        this.router.navigate(['/login', 'tenant', params]);
    }

}