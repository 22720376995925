import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService, WindowWrapper } from '@unifii/library/common';
import { ProjectInfo } from '@unifii/sdk';

import { TranslationsService } from 'shell/services/translations.service';
import { ShellFeatureFlagService } from 'shell/services/shell-feature-flag.service';
import { ShellTranslationKey } from 'shell/shell.tk';
import { ChangeLanguageComponent, ChangeLanguageModalData } from 'shell/translations/change-language.component';

import { DiscoverContext } from 'discover/discover-context';


@Injectable()
export class ProjectLanguageGuard implements CanActivate {

    constructor(
        private router: Router,
        private context: DiscoverContext,
        private flagService: ShellFeatureFlagService,
        private translationsService: TranslationsService,
        private modalService: ModalService,
        private translate: TranslateService,
        @Inject(WindowWrapper) private window: Window,
    ) { }

    async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        const project = this.context.project as ProjectInfo;
        /**
         * todo: maybe possible to remove !project expression, was causing an app to break on Maydown PROD
         * possibly a timing issue on with a lag with local storage cleared between previous guard and this guard
         * to be on the safe side treating project as if it can be null
         */
        if (!project) {
            this.router.navigate(['/login', 'projects']);
            return false;
        }

        const allowedLanguages = this.translationsService.availableProjectLanguages(project);

        // If no allowedLanguages available show a dialog error and go back to projects
        if (allowedLanguages.length === 0) {
            await this.modalService.openAlert({
                title: this.translate.instant(ShellTranslationKey.NoLanguageAvailableTitle),
                message: this.translate.instant(ShellTranslationKey.NoLanguageAvailableMessage),
            });

            this.router.navigate(['/login', 'projects']);
            return false;
        }

        // No user language set
        if (!this.context.language) {
            return true;
        }

        // User language available for this project
        if (allowedLanguages.findIndex(code => code === this.context.language) >= 0) {
            return true;
        }

        // No translations UI feature, silent select the language
        if (!await this.flagService.isEnabled('translations')) {
            this.translationsService.use(allowedLanguages[0]);
            return false;
        }

        const data: ChangeLanguageModalData = {
            languages: allowedLanguages,
            current: null as any as string,
            guard: true,
            reload: false
        };

        const selected = await this.modalService.openFit<ChangeLanguageModalData, boolean>(ChangeLanguageComponent, data, {
            guard: true
        });

        if (!selected) {
            console.info('ProjectLanguageGuard: No language selected, redirecting to projects');
            this.context.project = null;
            this.router.navigate(['/login', 'projects']);
            return false;
        }

        this.window.location.href = this.window.location.origin;
        return true;
    }
}