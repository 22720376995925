<uf-panel class="container" *ngIf="!printConfig">
    <div class="grid--fixed">
        <div class="header-pane col-1of1">
            <uf-breadcrumbs class="small left" [breadcrumbs]="breadcrumbs" [minimize]="true">
            </uf-breadcrumbs>
            <button class="uf-action" (click)="print()">
                <uf-icon name="print"></uf-icon>
            </button>
        </div>
        <uf-form *ngIf="!error" class="col-1of1--no-padding" [config]="config" [definition]="definition"
            [formData]="formData" (submit)="submit($event)" (cancel)="back()">
        </uf-form>
        <uf-blockquote *ngIf="error" class="col-1of1 error padded" icon="errorSolid" [content]="error.message">
        </uf-blockquote>
    </div>
</uf-panel>

<uf-print-form *ngIf="printConfig" [config]="printConfig" (done)="printConfig = null"></uf-print-form>