import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorType } from '@unifii/sdk';

import { NavigationService } from './navigation.service';


/**
 * Checks for valid node and
 */
@Injectable()
export class NodeGuard implements CanActivate {

    constructor(private router: Router, private nav: NavigationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        const nodeId = route.paramMap.get('nodeId');
        const node = this.nav.getNode(nodeId);

        if (node == null) {
            this.router.navigate(['/error', { type: ErrorType.NotFound }]);
            return false;
        }

        if (!this.nav.canAccessNode(node)) {
            this.router.navigate(['/error', { type: ErrorType.Unauthorized }]);
            return false;
        }

        // Redirect to canonical URL, if it's not
        const commands = this.nav.nodeToCommands(node);
        const segments = this.nav.getSegments(state);

        if (!commands) {
            this.router.navigate(['/']);
            return false;
        }

        // slice '/' off before comparison
        if (!this.isPrefix(commands.slice(1), segments)) {
            console.log('No match, rerouting');

            this.router.navigate(commands);
            return false;
        }

        this.nav.current = node;

        return true;
    }

    private isPrefix(nodeSegments: string[], urlSegments: string[]) {

        if (nodeSegments.length > urlSegments.length) {
            return false;
        }

        for (let i = 0; i < nodeSegments.length; i++) {
            if (nodeSegments[i] !== urlSegments[i]) {
                return false;
            }
        }

        return true;
    }

}