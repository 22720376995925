import { lastValueFrom, Observable } from 'rxjs';

import { InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';


export const canActivateSequence = (...guards: CanActivate[]): CanActivate =>
    new SequenceGuards(guards);

class SequenceGuards implements CanActivate {

    constructor(private guards: CanActivate[] = []) { }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {

        // console.log('SequenceGuard of', this.guards.length);

        for (const guard of this.guards) {

            // console.log('Request', guard.constructor.name);
            const succeed = await this.toPromise(guard.canActivate(_route, _state));
            // console.log('Complete', guard.constructor.name, succeed);

            if (!succeed) {
                return false;
            }
        }

        return true;
    }

    toPromise(v: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree): Promise<boolean | UrlTree> {

        if (v instanceof Promise) {
            return v;
        }

        if (v instanceof Observable) {
            return lastValueFrom(v);
        }

        if (typeof v === 'boolean') {
            return Promise.resolve(v);
        }

        return Promise.resolve(v != null);
    }
}

export const MainSequenceGuards = new InjectionToken<CanActivate>('MainSequenceGuards');
export const SavedUsersSequenceGuard = new InjectionToken<CanActivate>('SavedUsersSequenceGuard');