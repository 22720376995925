import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, DescriptionListItem, ModalService, SharedTermsTranslationKey, UfControlArray, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { Provisioning, UserCreate, UserCreateResult, UserInfo, UserInvite } from '@unifii/sdk';
import { UserCreateFormController, UserInviteFormController } from '@unifii/user-provisioning';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';

import { UserAddComponent } from './user-add.component';
import { AddUserModalInfo, UserInputType } from './user-types';


@Component({
    selector: 'ud-user-upload',
    templateUrl: './user-upload-result.html',
    styleUrls: ['user-upload-result.less', 'user-management.less']
})
export class UserUploadResultComponent implements OnInit {
    @Input() uploadResult: UserCreateResult;
    @Input() inputType: UserInputType;
    @Input() csvUpload: boolean;
    @Input() users: (UserCreate | UserInvite)[];

    @Output() resolved = new EventEmitter<boolean>();

    readonly commonTK = CommonTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;
    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly userInputType = UserInputType;

    form: UfControlArray;

    uploadStatus: DescriptionListItem[];

    userController: UserInviteFormController | UserCreateFormController;

    error: any;
    busy = false;

    private readonly maxUsersToManuallyEdit = 50;

    constructor(
        public context: DiscoverContext,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private provisioning: Provisioning,
        private translate: TranslateService,
        private inviteController: UserInviteFormController,
        private createController: UserCreateFormController,
        private ufb: UfFormBuilder
    ) { }

    ngOnInit() {
        this.init();
    }

    async save() {
        if (this.busy) {
            return;
        }

        // Save
        let responseData: UserCreateResult = {
            successCount: 0,
            errors: []
        };

        try {
            this.busy = true;

            const failedUsers = this.form.controls.map(control => this.userController.toDataModel(control as UfControlGroup));

            if (this.inputType === UserInputType.Create) {
                responseData = await this.provisioning.bulkAddUsers(failedUsers as UserInfo[]);
            } else if (this.inputType === UserInputType.Invite) {
                responseData = await this.provisioning.bulkInviteUsers(failedUsers as UserInvite[]);
            }

            if (!responseData.errors || !responseData.errors.length) {
                this.resolved.emit(true);
            } else {
                this.users = failedUsers;
                this.uploadResult = responseData;
                this.csvUpload = false;
                await this.init();
            }
            this.busy = false;
        } catch (e) {
            console.error(e);
            this.busy = false;

        }
    }

    back() {
        this.router.navigate(['../../'], { relativeTo: this.route });
    }

    async editUser(index: number) {
        const control = this.form.controls[index] as UfControlGroup;
        const savedData = this.userController.toDataModel(control);

        const addModalInfo: AddUserModalInfo = {
            control: await this.userController.buildRoot(savedData as any),
            inputType: this.inputType
        };

        const userControl = await this.modalService.openMedium<AddUserModalInfo, UfControlGroup>(UserAddComponent, addModalInfo);

        if (userControl) {
            this.form.controls[index] = userControl;
        }
        this.form.updateValueAndValidity();
    }

    removeUser(i: number) {
        (this.uploadResult.errors || []).splice(i, 1);
        this.form.removeAt(i);
    }

    private async init() {
        if (this.inputType === UserInputType.Invite) {
            this.userController = this.inviteController;
        } else {
            this.userController = this.createController;
        }

        let failedUsers: (UserInvite | UserCreate)[] = [];

        if (this.uploadResult.errors && this.uploadResult.errors.length <= this.maxUsersToManuallyEdit) {
            if (this.users) {
                failedUsers = this.users.filter((user, index) => this.uploadResult.errors.find(error => error.index === index));
            } else {
                failedUsers = (this.uploadResult.errors.map(error => {
                    if (this.inputType === UserInputType.Create) {
                        return error.user;
                    } else {
                        return error.invitation;
                    }
                }).filter(user => !!user) as (UserCreate | UserInvite)[]);
            }
        }

        this.form = this.ufb.array(await Promise.all(failedUsers.map(async (user) => await this.userController.buildRoot(user as any))));

        this.uploadStatus = [{
            term: this.translate.instant(DiscoverTranslationKey.UsersUploadResultSuccessLabel),
            description: (this.uploadResult.successCount.toString()) as string
        }, {
            term: this.translate.instant(DiscoverTranslationKey.UsersUploadResultFailLabel),
            description: (this.uploadResult.errors ? this.uploadResult.errors.length : '0') as string
        }];
    }
}
