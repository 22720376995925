import { Routes } from '@angular/router';

import { ContentNodeComponent } from 'shell/content/content-node.component';
import { IFrameComponent } from 'shell/content/iframe.component';
import { DashboardComponent } from 'shell/dashboard/dashboard.component';
import { ErrorMessageComponent } from 'shell/nav/error-message.component';
import { NodeGuard } from 'shell/nav/node-guard';
import { NodeComponent } from 'shell/nav/node.component';

import { ExternalRouteGuard } from 'discover/external-route-guard';
import { LoggedInGuard } from 'discover/user-access/logged-in-guard';

import { contentRoutes, customRoutes, mainConfig, mainRoutes, rootRoutes } from 'settings';


export const AppRoutes: Routes = [
    ...rootRoutes,
    // ExternalGuard always redirect or block navigation, never succeed to its component
    {
        path: 'e/:tenant/:projectId', canActivate: [ExternalRouteGuard], component: ErrorMessageComponent, children: [
            { path: '**', component: ErrorMessageComponent }
        ]
    },
    {
        path: '', component: mainConfig.component, canActivate: [...(mainConfig.canActivate as any[])], canDeactivate: [LoggedInGuard],
        children: [
            ...mainRoutes,
            { path: '', component: ContentNodeComponent }, // home page
            { path: 'error', component: ErrorMessageComponent },
            {
                path: 'n/:nodeId', component: NodeComponent, canActivate: [NodeGuard],
                children: [
                    { path: 'dashboard', component: DashboardComponent },
                    {
                        path: 'custom', children: [
                            // for a node route with content identifier (Collection, View, Page) matching 'custom'
                            ...customRoutes,
                            { path: ':identifier', pathMatch: 'full', component: ContentNodeComponent }
                        ]
                    },
                    { path: 'iframe', component: IFrameComponent },
                    ...contentRoutes,
                ]
            }, // end of structure navigation
            ...contentRoutes
        ]
    }
];
