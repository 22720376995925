import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';
import { Compound, Definition, FieldType } from '@unifii/sdk';

import { CollectionComponent } from 'shell/content/collection.component';
import { CollectionItemContent } from 'shell/content/content-types';
import { Authentication } from 'shell/services/authentication';

import { Config } from 'config';


@Component({
    templateUrl: './collection-item.html',
    styleUrls: ['./collection-item.less']
})
export class CollectionItemComponent implements OnInit, CollectionItemContent {

    definition: Definition;
    compound: Compound;
    breadcrumbs: Breadcrumb[] = [];

    constructor(
        @Inject(Authentication) private auth: Authentication,
        @Inject(Config) private config: Config,
        @Optional() private collectionComponent: CollectionComponent
    ) {
    }

    ngOnInit() {
        if (this.collectionComponent != null) {
            this.breadcrumbs = [{ name: this.definition.label, urlSegments: ['../'] }, { name: this.compoundLabel || '' }];
        }
    }

    get compoundLabel(): string | undefined {

        const field = this.definition.fields.find(f => f.type === FieldType.Text);

        if (field != null) {
            return this.compound[field.identifier as string];
        }
        return this.compound.id;
    }

}
