import { animate, style, transition, trigger } from '@angular/animations';
import { Component, forwardRef, Inject, OnInit, Optional, SkipSelf, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageLevel } from '@unifii/library/common';
import { ContentType, ErrorType, StructureNode, StructureNodeType, Table, TableSourceType } from '@unifii/sdk';

import { ContentComponentFactory } from 'shell/content/content-component-factory';
import { AppError } from 'shell/errors/errors';
import { ErrorMessageComponent } from 'shell/nav/error-message.component';
import { NavigationService } from 'shell/nav/navigation.service';
import { ContentDetails } from 'shell/services/content-details';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DetailPath } from 'discover/discover-constants';

import { DiscoverContentType } from './content-types';


interface ContentWrapper<T> {
    component: T;
    type: ContentType | StructureNodeType | DiscoverContentType;
    node?: StructureNode;
    /**
     * TODO: review this at a later point and see if it can removed, is a little message but
     * required for children ContentNodes so they have all the information they need extra data is required for child to render correctly
     */
    tableInfo?: Table;
}


export const fade = trigger('fade', [
    transition(':leave', [
        animate('400ms ease', style({ opacity: 0 }))
    ]),
]);


/**
 * Manage dinamic content inside Structure navigation
 * Load the Content associated with a StructureNode
 * Following node/contents are allowed:
 *
 *  HomePage  (Empty, View, Page, Dashboard, Custom)
 *  Node (View, Page, Collection)
 *  Use ComponentSelector to lookup for the right Component
 */
@Component({
    template: `<div class="skeleton-container" *ngIf="!component" [ngClass]="skeletonClassName" @fade></div>`,
    styleUrls: ['./content-node.less'],
    animations: [fade]
})
export class ContentNodeComponent<T> implements OnInit, EditedData {

    type: ContentType | StructureNodeType | DiscoverContentType;
    skeletonClassName: string;
    tableInfo?: Table;
    component: T | ErrorMessageComponent; // public so skeleton knows to hide itself

    constructor(
        private container: ViewContainerRef,
        private nav: NavigationService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private contentDetails: ContentDetails,
        @Inject(ContentComponentFactory) private contentFactory: ContentComponentFactory,
        @SkipSelf() @Optional() @Inject(forwardRef(() => ContentNodeComponent)) private parent?: ContentWrapper<any>,
        @Optional() @Inject(TablePageConfig) private tablePageConfig?: TablePageConfig
    ) { }

    async ngOnInit() {
        if (this.node && !this.canAccessNode(this.parent?.node ?? this.node)) {
            this.createErrorComponent(this.unauthorizedError);
            return;
        }

        try {
            const { params } = this.route.snapshot;

            this.type = this.route.snapshot.data?.contentType; // type can be set directly on route
            const bucket = params.bucket ?? this.tablePageConfig?.bucket;
            const definitionIdentifier = this.node?.definitionIdentifier ?? params.definitionIdentifier ?? this.getValidIdentifier(params.identifier, this.parent);
            const id = this.node?.id ?? params.id ?? this.getParentParam('id'); // todo: remove node id make conditional
            const hasRollingVersion = this.tablePageConfig?.hasRollingVersion ?? false;

            if (this.type == null) {
                this.type = await this.getContentType(this.node?.type, this.parent, definitionIdentifier);
            }

            this.skeletonClassName = this.getSkeletonClassName(this.type, definitionIdentifier, this.node?.tags);

            this.component = await this.contentFactory.create(this.container, this.type, {
                identifier: definitionIdentifier,
                id,
                bucket,
                tags: this.node?.tags,
                hasRollingVersion
            });

        } catch (e) {
            if (this.node?.nodeId === '0') {
                this.createHomePage();
            } else {
                console.warn(e);
                this.createErrorComponent(e);
            }
        }
    }

    get edited(): boolean {
        // todo: add strict types
        return (this.component as any)?.edited === true;
    }

    private getParentParam(param: string): string | undefined {
        return this.route.snapshot.parent?.paramMap.get(param) ?? undefined;
    }

    private canAccessNode(node: StructureNode): boolean {
        if (node.nodeId === '0') {
            // Home page is always accessible
            return true;
        }
        return this.nav.getNodeAccessInfo(node)?.matchACLs === true;
    }

    private getValidIdentifier(identifier: string, parent?: ContentWrapper<any>,): string | undefined {
        //  collection items require parents identifier
        if (parent?.type === ContentType.Collection) {
            return this.getParentParam('identifier');
        }
        return identifier;
    }

    private async getContentType(
        structureNodeType?: StructureNodeType,
        parent?: ContentWrapper<any>,
        identifier?: string
    ): Promise<ContentType | DiscoverContentType | StructureNodeType> {
        if (structureNodeType != null) {
            switch (structureNodeType) {
                case StructureNodeType.Dashboard: return StructureNodeType.Dashboard;
                case StructureNodeType.IFrame: return StructureNodeType.IFrame;
                case StructureNodeType.View: return ContentType.View;
                case StructureNodeType.Page: return ContentType.Page;
                case StructureNodeType.Collection: return ContentType.Collection;
                case StructureNodeType.CollectionItem: return ContentType.CollectionItem;
                case StructureNodeType.FormBucket: return ContentType.Table;
                case StructureNodeType.Form: return ContentType.Form;
                default: return StructureNodeType.Custom;
            }
        }

        if (parent?.type === ContentType.Collection) {
            return ContentType.CollectionItem;
        }

        if (parent?.type === ContentType.Table) {
            switch (this.tablePageConfig?.sourceType) {
                case TableSourceType.Company: return DiscoverContentType.Company;
                case TableSourceType.Users: return DiscoverContentType.User;
                default: return ContentType.Form;
            }
        }

        if (identifier) {
            const nodeType = await this.contentDetails.getContentType(identifier);
            if (nodeType) {
                return nodeType;
            }
        }
        throw this.notFoundError;
    }

    private createErrorComponent(error: AppError) {
        this.component = this.createComponent();
        this.component.error = error;
        this.component.message = error.message;
    }

    private createHomePage() {
        this.component = this.createComponent();
        this.component.title = this.translate.instant(ShellTranslationKey.ContentNodeHomePageTitle);
        this.component.message = this.translate.instant(ShellTranslationKey.ContentNodeHomePageMessage);
        this.component.level = MessageLevel.Info;
    }

    private createComponent(): ErrorMessageComponent {
        const ref = this.container.createComponent(ErrorMessageComponent, { index: 0, injector: this.container.injector });
        return ref.instance;
    }

    private getSkeletonClassName(type: ContentType | StructureNodeType | DiscoverContentType, identifier?: string, tags?: string[]): string {
        /**
         * Since detail is loaded in a resolver, when it's loading it's parent route we show the detail skeleton
         * if it's not detail we don't show anything because it's going to load the children route and then it's going to show the proper skeleton
         */
        const activeChildRoute = this.route.snapshot.children[0];
        if (activeChildRoute != null) {
            const path = activeChildRoute?.routeConfig?.path;
            if (path === DetailPath) {
                return 'uf-container detail';
            }
            return '';
            // todo: confirm that no skeleton should show when there are no children
            // if (type === ContentType.Table) {
            //     return '';
            // }
        }

        if ((identifier === 'directory-template' || (tags || []).includes('directory')) || type === ContentType.Collection) {
            return 'uf-container-md directory-template';
        }

        switch (type) {
            case ContentType.Table:
                return 'container table';
            case ContentType.Page:
            case ContentType.CollectionItem:
                return 'page-wrap page';
            case ContentType.View:
                return 'grid--fixed body-copy page';
            case ContentType.Form:
            case DiscoverContentType.User:
            case DiscoverContentType.UserProfile:
            case DiscoverContentType.Company:
                return 'uf-container-lg form';
            case ContentType.Detail:
                return 'uf-container detail';
            case StructureNodeType.Dashboard:
                return 'uf-container-md dashboard';
            default:
                return 'skeleton';
        }
    }

    get node(): StructureNode | undefined {
        if (this.parent == null) {
            return this.nav.current ?? undefined;
        }
        return;
    }

    private get unauthorizedError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized), ErrorType.Unauthorized);
    }

    private get notFoundError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorContentNotFound), ErrorType.NotFound);
    }

}
