import { ApplicationRef, Component, EventEmitter, Inject, Output } from '@angular/core';
import { UfControl, WindowWrapper } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';
import { Field } from '@unifii/sdk';

import { ShellTranslationKey } from 'shell/shell.tk';

import { DeviceService } from 'capacitor/device.service';


// TODO: update with @maslick/koder

@Component({
    templateUrl: './barcode-multitext-field.html',
    styleUrls: ['./barcode.less']
})
export class BarcodeMultitextFieldComponent implements FormField {

    @Output() contentChange: EventEmitter<any>;

    readonly shellTK = ShellTranslationKey;

    isMobile: boolean;
    content: any;
    field: Field;
    control: UfControl;
    loading: boolean;

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private applicationRef: ApplicationRef,
        private deviceService: DeviceService,
    ) {
        this.isMobile = this.deviceService.isNative;
    }

    changed(v: any) {
        this.contentChange.emit(v);
    }

    /**
     * Scan a barcode via the cordova plugin
     * https://github.com/phonegap/phonegap-plugin-barcodescanner
     */
    scan() {
        // if (!this.window.cordova || !this.window.cordova.plugins || !this.window.cordova.plugins.barcodeScanner) {
        //     console.error('NOT FOUND: cordova.plugins.barcodeScanner');
        //     return;
        // }

        // const scanSucess = (result: phonegapBarcode.BarcodeScanResult) => {
        //     this.control.markAsTouched();
        //     this.content = result.text;
        //     this.control.setValue(this.content);
        //     this.applicationRef.tick();
        // };

        // const scanFail = (error: any) => {
        //     console.error('Faled', error);
        // };

        // this.window.cordova.plugins.barcodeScanner.scan(scanSucess, scanFail);
    }
}
