import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { TenantSettings, UserInfo } from '@unifii/sdk';

import { DiscoverOptions } from 'discover/discover-options';

import { Config } from 'config';

import { Authentication } from './services/authentication';


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor(
        @Inject(Config) private config: DiscoverOptions,
        private injector: Injector
    ) {

        if (!config.sentryDSN) {
            return;
        }

        Sentry.init({
            dsn: config.sentryDSN,
            environment: config.env,
            release: this.config.version,
            // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
            // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
            // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
            integrations: [new Sentry.Integrations.TryCatch({
                XMLHttpRequest: false,
            })],
        });
    }

    handleError(error: any) {

        const extractedError = this.extractError(error) || 'Handled unknown error';

        // log the error to console for immediate feedback.
        console.error(extractedError);

        // no sentry guard
        if (!Sentry.getCurrentHub().getClient()) {
            return;
        }

        // tenant opped-out guard
        if (this.tenantSettings && this.tenantSettings.isCrashReportingEnabled !== true) {
            return;
        }

        // set tags at time of error
        Sentry.configureScope((scope) => {

            if (this.config.unifii.appId) {
                scope.setTag('app-id', this.config.unifii.appId);
            }

            if (this.config.productVersion) {
                scope.setTag('project-version', this.config.productVersion);
            }

            if (this.config.unifii.baseUrl && this.config.unifii.tenant) {
                scope.setTag('tenantUrl', this.config.unifii.baseUrl);
            }

            if (this.config.unifii.projectId) {
                scope.setTag('projectId', this.config.unifii.projectId);
            }

            if (this.config.env !== 'dev' && this.user) {
                scope.setUser({ id: this.user.id });
            }

            // return username for dev
            if (this.config.env === 'dev' && this.user) {
                scope.setUser({ id: this.user.id, username: this.user.username });
                scope.setTag('username', this.user.username);
            }

        });

        // Capture handled exception and send it to Sentry.
        Sentry.captureException(extractedError);

    }

    private extractError(error: any) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null;
    }

    private get user(): UserInfo | undefined {

        const auth = this.injector.get(Authentication);

        if (auth && auth.userInfo && auth.userInfo.id) {
            return auth.userInfo;
        }

        return undefined;
    }

    private get tenantSettings(): TenantSettings {
        return this.config.unifii.tenantSettings as TenantSettings;
    }
}