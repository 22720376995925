import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Directive, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ufMasterDetail]'
})
export class MasterDetailDirective implements OnDestroy {

    @HostBinding('class.master-detail') isDetails: boolean;

    subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {

        this.subscriptions.push(
            this.router.events.pipe(filter(e => e instanceof NavigationEnd))
                .subscribe(() => {
                    this.isDetails = !!this.route.children.length;
                })
        );

        this.isDetails = !!this.route.children.length;
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}