import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    ContextProvider, ExpressionParser, SafeUrlFunctions, SortStatus, TableComponent, TableConfig, TableDataSource, WindowWrapper
} from '@unifii/library/common';
import {
    AstNode, Client, ColumnDescriptor, Company, CompanyClient, FormData, NodeType, PermissionAction, Provisioning, Query, Table, TableDetailModule,
    TableSourceType, UserInfo
} from '@unifii/sdk';

import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { BetterFormService } from 'shell/form/better-form.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableDetailContextProvider } from 'shell/table-detail/table-detail-context-provider';
import { ASTExpressionParser } from 'shell/table/ast-expression-parser';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableData } from 'shell/table/models';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';

import { DetailPath } from 'discover/discover-constants';

import { Config } from 'config';


@Component({
    templateUrl: './table-module.html',
    selector: 'us-table-module',
    styleUrls: ['../dashboard/dashboard-table.less'],
    providers: [BetterFormService]
})
export class TableModuleComponent implements OnInit {

    @ViewChild(TableComponent) pageTable: TableComponent<any>;

    @Input() module: TableDetailModule;
    @Input() item: FormData | UserInfo;
    @Input() detailContextProvider: TableDetailContextProvider;

    readonly shellTK = ShellTranslationKey;

    title: string;
    tableConfig: TableConfig<any>;
    customColumns: ColumnDescriptor[] = [];
    tableLink: any[];
    error: AppError | undefined;
    dataSource: TableDataSource<TableData> | null = null;

    constructor(
        private provisioning: Provisioning,
        private expParser: ExpressionParser,
        private formService: BetterFormService,
        @Inject(Config) private config: Config,
        @Inject(Authentication) private auth: Authentication,
        @Inject(WindowWrapper) private window: Window,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        private router: Router,
        private client: Client,
        private errorService: ErrorService,
        private tableColumnFactory: TableColumnFactory<FormData>,
        @Inject(ContentDataResolver) private dataResolver: ContentDataResolver
    ) { }

    async ngOnInit(): Promise<void> {
        try {

            // Guard for non matching roles with those requested by the TableModule
            if (this.module.roles?.length && !this.module.roles.some(r => (this.auth.userInfo?.roles || []).includes(r))) {
                return;
            }

            const { tablePageConfig } = await this.dataResolver.getTableData(this.module.identifier);
            const { table, propertyDescriptors } = tablePageConfig;

            const filter = this.mergeFilters(table.filter, this.module.filter);
            this.dataSource = this.createDataSource(table, filter);

            this.title = table.title;
            this.customColumns = (table?.columns || []).filter((column: any) => column.heading != null || column.variations);

            this.tableConfig = {
                columns: this.tableColumnFactory.create(table.columns ?? [], propertyDescriptors, table.sourceType),
                pageSize: this.module.limit ?? 5,
                rowAction: (item: TableData) => {
                    if (this.canRouteToItem(item, table.sourceType, table.source)) {
                        this.routetToItem(item.id as string, table);
                    }
                }
            };

            /**
             * Generate link to table including params:
             *  - prevUrl: link to return to current table module route
             *  - $cf (context fitler): an encoded query that will pass the module filters to the
             */
            const params: { prevUrl: string; $cf?: string } = { prevUrl: this.window.location.pathname };
            if (filter != null) {
                const encoded = SafeUrlFunctions.encodeObj(filter);
                params.$cf = encoded;
            }
            this.tableLink = ['/', table.identifier, params];

        } catch (e) {
            this.error = this.errorService.mergeError(e, this.errorService.unknownErrorMessage);
        }
    }

    private createDataSource(table: Table, filter?: AstNode): TableDataSource<TableData> {
        const query = filter != null ? new Query().fromAst(filter) : undefined;
        const sort = SortStatus.fromString(table?.defaultSort) || undefined;

        switch (table.sourceType) {
            case TableSourceType.Users:
                return new UsersTableDataSource(this.provisioning, query, undefined, sort);
            case TableSourceType.Company:
                const companyClient = new CompanyClient(this.client);
                return new CompanyTableDataSource(companyClient, query, undefined, sort);
            case TableSourceType.Bucket: {
                this.formService.bucket = table.source as string;
                return new BucketTableDataSource(this.formService, query, undefined, sort);
            };
        }
    }

    private routetToItem(id: string, table: Table) {
        const params: { id?: string; prevUrl: string } = { prevUrl: this.window.location.pathname };
        let urlSegments: any[];

        // TODO: should be able to route directly detail would make this logic simpler
        if (table.detail) {
            params.id = id;
            urlSegments = [this.module.identifier, DetailPath, params];
        } else {
            urlSegments = [this.module.identifier, id, params];
        }
        this.router.navigate(urlSegments);
    }

    private mergeFilters(tableFilter?: AstNode, moduleFilter?: AstNode): AstNode | undefined {
        const astParser = new ASTExpressionParser(this.expParser, this.detailContextProvider);

        tableFilter = tableFilter != null ? astParser.parse(tableFilter) : undefined;
        moduleFilter = moduleFilter != null ? astParser.parse(moduleFilter) : undefined;

        if (tableFilter != null && moduleFilter != null) {
            return {
                type: NodeType.Combinator,
                op: 'and',
                args: [tableFilter, moduleFilter]
            };
        }
        if (tableFilter != null) {
            return tableFilter;
        }
        return moduleFilter;
    }

    private canRouteToItem(item: TableData, tableSource: TableSourceType, bucket?: string): boolean {
        switch (tableSource) {
            case TableSourceType.Bucket: return this.auth.getGrantedInfo(
                PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, bucket as string, item.id as string),
                PermissionAction.Read,
                item,
                this.contextProvider.get()
            ).granted;
            case TableSourceType.Company: return this.auth.getGrantedInfo(
                PermissionsFunctions.getCompanyPath(item.id),
                PermissionAction.Read,
                item,
                this.contextProvider.get()
            ).granted;
            case TableSourceType.Users: return this.auth.getGrantedInfo(
                PermissionsFunctions.getUserPath(item.id ? +item.id : undefined),
                PermissionAction.Read,
                item,
                this.contextProvider.get()
            ).granted;
        }
    }

}