import { Inject, Injectable } from '@angular/core';

import { format } from 'date-fns';
import { ChartType } from 'chart.js';

import { Client, DataSourceType, FieldType, Option, PermissionAction, Provisioning, PublishedContent } from '@unifii/sdk';
import { FnsDate, HierarchyUnitProvider } from '@unifii/library/common';
import { FilterLoader } from '@unifii/components';

import { Authentication } from 'shell/services/authentication';
import { CollectionOptionsLoader } from 'shell/common/filters/collection-options-loader';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { UserOptionsLoader } from 'shell/common/filters/user-options-loader';
import { UserClaimOptionsLoader } from 'shell/common/filters/user-claim-options-loader';
import { DiscoverOptions } from 'discover/discover-options';
import { DiscoverContent } from 'discover/discover-content.service';
import { Config } from 'config';

export interface ReportConfig {
    identifier: string;
    title: string;
    chartType: ChartType | 'table';
    width: number;
    dateFilters?: ReportDateFilterConfig;
    customFilters?: ReportCustomFilerConfig[];
    xAxis?: ReportAxisConfig;
    yAxis?: ReportAxisConfig;
    legend?: ReportLegendConfig;
}

export interface ReportAxisConfig {
    label?: string;
    stacked?: boolean;
    ticks?: { min?: number; max?: number; precision?: number }; // TODO min, max not inline with chartjs 3.x types
}

export interface ReportLegendConfig {
    position: 'left' | 'right' | 'top' | 'bottom';
    align: 'start' | 'end';
    display: boolean;
}

export interface ReportDateFilterConfig {
    startDate?: boolean;
    endDate?: boolean;
    presetRanges?: boolean;
    intervals?: boolean;
}
export interface ReportCustomFilerConfig { // TODO extend FilterEntry in the future
    identifier: string;
    label: string;
    type: FieldType;
    range?: boolean;
    options?: Option[];
    loader?: ReportDataSourceLoaderConfig; // TODO change to loaderConfig
}

export interface ReportDataSourceLoaderConfig {
    type: DataSourceType;
    id?: string;
    identifierProperty?: string;
    nameProperty?: string;
}

export interface ReportData {
    labels: (string | { value: string })[];
    datasets: ReportDataset[];
}

export interface ReportDataset {
    label?: string;
    data: any[];
    color?: string | string[];
    tension?: number;
}

@Injectable()
export class ReportService {

    constructor(
        @Inject(Config) private config: DiscoverOptions,
        @Inject(PublishedContent) private content: DiscoverContent,
        @Inject(HierarchyUnitProvider) private hierarchyUnitProvider: HierarchyUnitProvider,
        private client: Client,
        private provisioning: Provisioning,
        private auth: Authentication,
    ) { }

    async getConfig(reportId: string): Promise<ReportConfig | undefined> {

        const configUrl = this.url([reportId, 'config']);
        if (configUrl) {
            return await this.client.get(configUrl);
        }
        return;
    }

    async getData(reportId: string, filters: any): Promise<ReportData | undefined> {

        const dataUrl = this.url([reportId, 'data']);
        if (dataUrl) {
            return await this.client.get(dataUrl, { ...filters, today: format(new Date(), FnsDate) });
        }
        return;
    }

    createFilterLoader(fieldType: FieldType, loaderConfig?: ReportDataSourceLoaderConfig): FilterLoader | undefined {
        if (fieldType === FieldType.Hierarchy) {
            return this.hierarchyUnitProvider;
        }

        switch (loaderConfig?.type) {
            case DataSourceType.Collection: return new CollectionOptionsLoader(
                loaderConfig.id as string,
                loaderConfig.identifierProperty as string,
                loaderConfig.nameProperty as string,
                this.content
            );
            case DataSourceType.Users:
                if (!this.auth?.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).granted) {
                    return;
                }
                return new UserOptionsLoader(
                    loaderConfig.identifierProperty as string,
                    loaderConfig.nameProperty as string,
                    this.provisioning
                );
            case DataSourceType.UserClaims: return new UserClaimOptionsLoader(
                loaderConfig.id as string,
                this.client
            );
            default: return;
        }
    }

    private url(parts: string[] = []): string | undefined {
        if (this.config.unifii.reportingApiUrl && this.config.unifii.projectId && this.config.unifii.tenant) {
            parts = parts.map(p => encodeURIComponent(p));
            parts.unshift('charts');
            if (this.config.unifii.preview) {
                parts.unshift('preview');
            }
            parts.unshift(this.config.unifii.projectId);
            parts.unshift(this.config.unifii.tenant);
            parts.unshift(this.config.unifii.reportingApiUrl);

            return parts.join('/');
        }
        return;
    }

}
