import { lastValueFrom } from 'rxjs';
import { flatMap, map, toArray } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    Compound, ContentType, Definition, Dictionary, FieldType, ImageProfile, NodeType, Page, PublishedContent, Query, Schema, Structure, Table
} from '@unifii/sdk';

import { ContentDb, IndexedDbWrapper, TenantDb } from 'shell/offline/indexeddb-wrapper';
import { buildOfflineImageUrl, ContentInfo, ContentStores, TenantStores } from 'shell/offline/offline-model';

import { Config } from 'config';


@Injectable()
export class OfflineContent implements PublishedContent {

    constructor(
        @Inject(Config) private config: Config,
        @Inject(TenantDb) private tenantDb: IndexedDbWrapper,
        @Inject(ContentDb) private contentDb: IndexedDbWrapper,
        private sanitizer: DomSanitizer
    ) { }

    getStructure(): Promise<Structure> {
        return this.contentDb.get<Structure>(ContentStores.Structure, 1);
    }

    async getView(id: string): Promise<Compound> {
        const c = await this.getViewCompound(id);
        const d = await this.getViewDefinition(id);
        return this.updateCompoundImageUrls(c, d);
    }

    getViewDefinition(identifier: string): Promise<Definition> {
        return this.contentDb.get<Definition>(ContentStores.ViewDefinitions, identifier);
    }

    async getPage(id: string): Promise<Page> {

        const page = await this.contentDb.get<Page>(ContentStores.Pages, id);

        for (const f of page.fields) {
            if (f.type === FieldType.ImageList) {
                for (const v of f.value) {
                    v.url = await this.replaceUrl(v);
                }
            }
        }

        return page;
    }

    getCollectionDefinition(identifier: string): Promise<Definition> {
        return this.contentDb.get<Definition>(ContentStores.Collections, identifier);
    }

    getCollections(): Promise<Definition[]> {
        return lastValueFrom(this.contentDb.getValues<Definition>(ContentStores.Collections).pipe(toArray()));
    }

    async queryCollection(identifier: string, query?: Query): Promise<Compound[]> {

        const definition = await this.getCollectionDefinition(identifier);
        return lastValueFrom(this.contentDb.getValues<Compound>(identifier)
            .pipe(
                toArray(),
                map(data => this.applyQuery<Compound>(data, query)),
                flatMap(data => Promise.all(data.map(c => this.updateCompoundImageUrls(c, definition))))
            ));
    }

    async getCollectionItem(identifier: string, id: string): Promise<Compound> {

        const definition = await this.getCollectionDefinition(identifier);
        const compound = await this.contentDb.get<Compound>(identifier, id);
        return this.updateCompoundImageUrls(compound, definition);
    }

    getBucket(identifier: string): Promise<Schema> {
        return this.contentDb.get<Schema>(ContentStores.Buckets, identifier);
    }

    queryForms(_?: Query): Promise<Definition[]> {
        return lastValueFrom(this.contentDb.getValues<Definition>(ContentStores.Forms).pipe(toArray()));
    }

    getForm(identifier: string, version?: any): Promise<Definition> {

        if (!this.config.unifii.preview && version) {
            return this.contentDb.get<Definition>(ContentStores.FormVersions, `${identifier}.${version}`);
        }

        return this.contentDb.get<Definition>(ContentStores.Forms, identifier);
    }

    async queryTables(_?: Query): Promise<Table[]> {
        try {
            return lastValueFrom(this.contentDb.getValues<Table>(ContentStores.Tables).pipe(toArray()));
        } catch (e) {
            return [];
        }
    }

    async getTable(id: string): Promise<Table> {
        try {
            return this.contentDb.get<Table>(ContentStores.Tables, id);
        } catch (e) {
            return undefined as any as Table;
        }
    }

    async getIdentifiers(): Promise<Dictionary<{ type: ContentType }>> {
        try {
            return this.contentDb.get<Dictionary<{ type: ContentType }>>(ContentStores.Identifiers, 1);
        } catch (e) {
            return {} as Dictionary<{ type: ContentType }>;
        }
    }

    buildImageUrl(imageProfile: ImageProfile): string | null {
        return imageProfile.url || null;
    }

    getCurrentVersion(): Promise<ContentInfo> {
        return this.tenantDb.get<ContentInfo>(TenantStores.Projects, this.config.unifii.projectId);
    }

    /******************************************** PRIVATE ****************************************************/

    private getViewCompound(id: string): Promise<Compound> {
        return this.contentDb.get<Compound>(ContentStores.Views, id);
    }

    private async updateCompoundImageUrls(compound: Compound, definition: Definition): Promise<Compound> {
        const targets = (definition.fields || [])
            .filter(f => f.type === FieldType.ImageList)
            .map(f => f.identifier as string);

        for (const identifier of targets) {
            const imageList: ImageProfile[] = compound[identifier];
            if (imageList) {
                for (const ip of imageList) {
                    ip.url = await this.replaceUrl(ip);
                }
            }
        }

        return compound;
    }

    private async replaceUrl(imageProfile: ImageProfile): Promise<string> {
        const url = buildOfflineImageUrl(imageProfile);

        try {
            const asset = await this.tenantDb.get<{ type: string; data: ArrayBuffer }>(TenantStores.Assets, url);
            const blob = new Blob([asset.data], { type: asset.type });

            return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob)) as string;
        } catch (e) {
            console.warn('Missing image', url);
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';
        }
    }

    private applyQuery<T>(data: T[], query?: Query): T[] {
        let filtered = data;
        if (query) {
            // Filter
            for (const arg of (query.args || [])) {
                switch (arg.op) {
                    case 'in':
                        if (arg.args[0].type === NodeType.Identifier && arg.args[0].value === 'id') {
                            filtered = filtered.filter(d => (arg.args[1].value as string[]).includes((d as Dictionary<any>).id));
                        }
                        break;
                    case 'eq':
                        if (arg.args[0].type === NodeType.Identifier) {
                            filtered = filtered.filter(d => arg.args[1].value === (d as Dictionary<any>)[arg.args[0].value]);
                        }
                        break;
                }
            }
            // Sort
            const sort = query.args.find(arg => arg.op === 'sort');
            if (sort) {
                let field = sort.args[0].value as string;
                const asc = field.startsWith('+');
                field = field.startsWith('+') || field.startsWith('-') ? field.substr(1) : field;
                filtered = filtered.sort((d1: Dictionary<any>, d2: Dictionary<any>) => (asc ? d1[field] > d2[field] : d1[field] < d2[field]) ? 1 : -1);
            }
        }
        return filtered as T[];
    }

}
