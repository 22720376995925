<div class="chart">
    <uf-progress class="large"></uf-progress>
    <div class="row">
        <h4 *ngIf="reportConfig.title" class="grow">{{ reportConfig.title }}</h4>
        <button type="button" *ngIf="reportConfig.chartType !== 'table'" class="uf-action tertiary"
            (click)="downloadChartAsImage()">
            <uf-icon name="download"></uf-icon>
        </button>
    </div>
    <us-chart *ngIf="chartConfig" [config]="chartConfig"></us-chart>
    <div class="table" *ngIf="reportConfig.chartType === 'table' && reportData">
        <table class="uf-table accent">
            <thead>
                <tr>
                    <th *ngFor="let label of reportData.labels">
                        {{ $any(label).value ?? label }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dataset of reportData.datasets">
                    <td *ngFor="let datum of dataset.data"
                        [ngClass]="{ 'bold': datum.bold === true, 'italic': datum.italic === true}">
                        {{ datum.value !== undefined ? datum.value : datum }}
                    </td>
                </tr>
                <tr *ngIf="!reportData.datasets || !reportData.datasets.length">
                    <td [attr.colspan]="reportData.labels.length" class="empty">
                        {{ shellTK.ReportResultNoDataLabel | translate}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>