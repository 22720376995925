import { Context, Scope } from '@unifii/library/common';
import { DataSourceLoader } from '@unifii/library/smart-forms';
import { DataSeed, DataSource, Provisioning, Query, UserInfo } from '@unifii/sdk';


/**
 * @description
 * UsersUsernameLoader a DataSourceLoader that uses 'username' to loader user information when
 * the an id is not available
 */
export class UsersUsernameLoader implements DataSourceLoader {

    dataSource: DataSource;

    private searchAbortController: AbortController | null;

    constructor(
        private provisioning: Provisioning,
        private roles?: string[]
    ) { }

    async search(q?: string): Promise<DataSeed[]> {
        if (this.searchAbortController) {
            this.searchAbortController.abort();
            await new Promise(resolve => setTimeout(resolve, 0)); // To guaranteed previous abort is digested
        }
        this.searchAbortController = new AbortController();

        let query = new Query();
        query = query.sort('username');
        if (q && q.trim().length) {
            query = query.q(q);
        }

        if (this.roles?.length) {
            query = query.and(Query.contains('roles', this.roles));
        }

        const users = await this.provisioning.queryUsers(query, this.searchAbortController.signal);
        return users.map(this.mapToSeed);
    }

    async get(username: string): Promise<DataSeed | null> {
        const user = await this.provisioning.getUserByUsername(username) ?? null;
        if (user != null) {
            return this.mapToSeed(user);
        }
        return null;
    }

    mapToSeed = (userInfo: UserInfo): DataSeed => ({ _id: userInfo.username, _display: userInfo.username, ...userInfo });

    getOptions(_context?: Context | undefined, _scope?: Scope | undefined): Promise<any> {
        throw new Error('method not implemented');
    }

}
