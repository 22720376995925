<div *ngIf="field" class="barcode-wrap">
    <div class="barcode-content">
        <div class="input-wrap">
            <uf-text [control]="control" [value]="content" [label]="field.label" (valueChange)="changed($event)"
                [placeholder]="field.placeholder" [maxLength]="field.maxLength" [disabled]="field.isReadOnly">
            </uf-text>
        </div>
        <uf-help *ngIf="field?.help" [content]="field.help"></uf-help>
    </div>
    <button class="uf-button small primary" type="button" (click)="scan()" *ngIf="isMobile">
        {{shellTK.BarCodeScanLabel | translate}}
    </button>
    <uf-spinner *ngIf="loading"></uf-spinner>
</div>