<div class="uf-app-bar">
    <a class="uf-action tertiary" (click)="close()">
        <uf-icon name="close"></uf-icon>
    </a>
    <h3>{{ discoverTK.UserAddAction | translate }}</h3>
</div>
<form *ngIf="form" (ngSubmit)="addUser()" class="col grow" [formGroup]="form">
    <uf-panel class="grow">
        <div class="uf-grid pad">
            <uf-text class="col-12" [formControlName]="userInfoControlKeys.FirstName"
                [label]="labelDictionary[userInfoControlKeys.FirstName]">
                <span class="suffix">*</span>
            </uf-text>
            <uf-text class="col-12" [formControlName]="userInfoControlKeys.LastName"
                [label]="labelDictionary[userInfoControlKeys.LastName]">
                <span class="suffix">*</span>
            </uf-text>
            <uf-text class="col-12" [formControlName]="userInfoControlKeys.Email"
                [label]="labelDictionary[userInfoControlKeys.Email]">
                <span class="suffix" *ngIf="emailRequired">*</span>
            </uf-text>
            <uf-phone class="col-12" [formControlName]="userInfoControlKeys.Phone"
                [label]="labelDictionary[userInfoControlKeys.Phone]">
            </uf-phone>
            <uf-text *ngIf="usernameControl" class="col-12" [formControlName]="userInfoControlKeys.Username"
                [label]="labelDictionary[userInfoControlKeys.Username]">
                <span class="suffix" *ngIf="data.inputType == userInputType.Create">*</span>
                <uf-help *ngIf="data.inputType == userInputType.Invite"
                    [content]="discoverTK.UserCanChangeUsernameHelp | translate"></uf-help>
            </uf-text>
            <uf-checkbox class="col-12" *ngIf="canChangeUsernameControl?.enabled"
                [formControlName]="userInfoControlKeys.CanChangeUsername"
                [label]="labelDictionary[userInfoControlKeys.CanChangeUsername]"></uf-checkbox>
            <uf-password-advanced class="col-12" *ngIf="passwordControl"
                [label]="labelDictionary[userInfoControlKeys.Password]"
                [formControlName]="userInfoControlKeys.Password">
                <span class="suffix">*</span>
            </uf-password-advanced>
            <uf-password-indicator class="col-12" *ngIf="passwordControl"
                [style.visibility]="!passwordControl.value ? 'hidden' : 'visible'"
                [formControlName]="userInfoControlKeys.Password">
            </uf-password-indicator>
            <uf-hierarchy-input *ngIf="unitsControl" class="col-12" [label]="labelDictionary[userInfoControlKeys.Units]"
                [control]="unitsControl">
            </uf-hierarchy-input>
            <uf-role-input class="col-12" *ngIf="rolesControl" [formControlName]="userInfoControlKeys.Roles"
                labelSuffix="*" [label]="labelDictionary[userInfoControlKeys.Roles]">
            </uf-role-input>
            <uf-company-input class="col-12" *ngIf="companyControl" [formControlName]="userInfoControlKeys.Company"
                [label]="labelDictionary[userInfoControlKeys.Company]">
            </uf-company-input>
        </div>
        <div class="uf-grid condensed">
            <uf-claim-editor class="col-12 pad-sm-bottom" *ngIf="claimsControl && claimsControl.enabled"
                [control]="claimsControl" [width]="fieldWidth.Half">
            </uf-claim-editor>
        </div>
    </uf-panel>
    <div class="uf-form-actions">
        <button class="uf-button tertiary left" type="button" (click)="close()">{{ sharedTermsTK.ActionCancel |
            translate }}</button>
        <button class="uf-button primary" type="submit" ufAutofocus>{{ sharedTermsTK.ActionAdd | translate }}</button>
    </div>
</form>