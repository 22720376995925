import { lastValueFrom, Observable, Subject } from 'rxjs';
import { filter, toArray } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { Bucket, Client, Definition, Error, ErrorType, FormData, Progress, UserInfo } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { FileInfo, FileState, FormDataState, FormInfo } from 'shell/offline/forms/interfaces';
import { IndexedDbWrapper } from 'shell/offline/indexeddb-wrapper';
import { Authentication } from 'shell/services/authentication';

import { Config } from 'config';


const FormDataStore = 'FormData';
const FormMetaStore = 'FormMeta';
const FileStore = 'Files';
const FileMetaStore = 'FileMeta';

const abortedError: Error = { type: ErrorType.AbortError, message: 'AbortError' };

export interface UploadFormProgress extends Progress {
    formData?: FormData;
}

@Injectable()
export class OfflineQueue {

    initialized = false;
    lockedFormInfo: Map<string, boolean> = new Map();

    private openedName: string | null = null;
    private _db: IndexedDbWrapper | null = null;
    private _additions = new Subject<void>();
    private _deletions = new Subject<void>();

    constructor(
        private client: Client,
        @Inject(Config) private config: Config,
        @Inject(Authentication) private auth: Authentication,
        private errorService: ErrorService
    ) { }

    get additions(): Observable<void> {
        return this._additions;
    }

    get deletions(): Observable<void> {
        return this._deletions;
    }

    emitAddition() {
        this._additions.next();
    }

    emitDeletion() {
        this._deletions.next();
    }

    async saveAttachment(dataId: string, fileId: string, file: File, initialState: FileState = FileState.Pending): Promise<Progress> {

        this.ensureDb();

        const ev = await this.readFile(file);
        const buffer = (ev.target as FileReader).result;

        const info: FileInfo = {
            id: fileId,
            name: file.name,
            properties: {
                type: file.type,
                lastModified: file.lastModified,
            },
            size: file.size,
            storedAt: new Date(),
            state: initialState,
        };

        await this.db.put(FileMetaStore, info, `${dataId}:${info.id}`);
        await this.db.put(FileStore, buffer, info.id);

        return { id: fileId, total: ev.total, done: ev.total };
    }

    async save(formData: FormData, form: Definition, notify = true): Promise<FormData> {

        this.ensureDb();

        const fileInfos = await lastValueFrom(this.listFiles(formData.id as string).pipe(toArray()));
        const size = this.sum(fileInfos.map(fi => fi.size));

        let info = await this.getFormInfo(formData.id as string);
        if (info == null) {
            info = {
                id: formData.id as string,
                projectId: this.config.unifii.projectId,
                preview: this.config.unifii.preview,
                bucket: form.bucket as string,
                storedAt: new Date(),
                form,
                state: formData._state as string,
                result: formData._result as string,
                status: FormDataState.Pending,
                size: 0, // updated later
            };
        }

        info.size = size + JSON.stringify(formData).length;
        info.status = FormDataState.Pending;

        await this.saveFormInfo(formData.id as string, info);
        await this.db.put(FormDataStore, formData, formData.id);

        if (notify) {
            this.emitAddition();
        }

        return formData;
    }

    list(): Observable<FormInfo> {
        this.ensureDb();

        return this.db.getValues(FormMetaStore);
    }

    count(): Promise<number> {
        this.ensureDb();

        return this.db.count(FormMetaStore);
    }

    async delete(dataId: string, notify = true) {
        const info = await this.getFormInfo(dataId);
        if (info == null) {
            return;
        }

        // delete all attachments
        const range = IDBKeyRange.bound(dataId + ':', dataId + ';');
        const results = await lastValueFrom(this.db.getAll<string, FileInfo>(FileMetaStore, range).pipe(toArray()));

        for (const kvp of results) {
            await this.db.delete(FileStore, kvp.value.id);
            await this.db.delete(FileMetaStore, kvp.key);
        }

        await this.db.delete(FormDataStore, dataId);
        await this.db.delete(FormMetaStore, dataId);

        if (notify) {
            this.emitDeletion();
        }
    }

    async deleteFile(fileId: string) {
        this.ensureDb();
        return this.db.delete(FileStore, fileId);
    }

    upload(dataId: string, progressCallback?: (progress: UploadFormProgress) => void, signal?: AbortSignal): Promise<UploadFormProgress> {

        if (signal && signal.aborted) {
            Promise.reject(abortedError);
        }

        return new Promise<UploadFormProgress>(async (resolve, reject) => {

            try {

                if (signal) {
                    signal.addEventListener('abort', () => reject(abortedError));
                }

                const formInfo = await this.getFormInfo(dataId);

                // Without a formInfo will emit a complete immediately
                if (!formInfo) {
                    resolve(undefined as any);
                    return;
                }

                const formProgress: Progress = { total: formInfo.size, done: 0 };

                // get attachments
                const fileInfos = await lastValueFrom(this.listFiles(formInfo.id).pipe(toArray()));
                console.log('Files #', fileInfos.length);


                for (const fi of fileInfos) {

                    console.log('Uploading...', fi.name);
                    try {
                        const completedProgress = await this.uploadFile(formInfo, fi, progress => {
                            if (progressCallback) {
                                progressCallback({ total: formProgress.total, done: formProgress.done + progress.done });
                            }
                        }, signal);

                        console.log('Uploading done!');

                        formProgress.done = formProgress.done + completedProgress.done;
                        if (progressCallback) {
                            progressCallback(formProgress);
                        }
                    } catch (error) {
                        const errorObject = (error.currentTarget || error.target || {});
                        if (errorObject.status !== 409) {
                            return reject(error);
                        }
                    }
                }

                const bucket = new Bucket(this.client, formInfo);
                let data = await this.db.get<FormData>(FormDataStore, formInfo.id);
                console.log('OfflineQueue.upload save...');

                data = await bucket.save(data);
                console.log('OfflineQueue.upload saved!');
                await this.delete(dataId, false);

                resolve({
                    total: formInfo.size,
                    done: formProgress.done + JSON.stringify(data).length,
                    formData: data
                } as UploadFormProgress);

            } catch (error) {
                console.warn('OfflineQueue.upload catched error!');
                reject(error);
            }
        });
    }

    getData(dataId: string): Promise<FormData> {
        this.ensureDb();
        return this.db.get(FormDataStore, dataId);
    }

    getFormInfo(dataId: string): Promise<FormInfo> {
        this.ensureDb();
        return this.db.get(FormMetaStore, dataId);
    }

    getFileInfo(dataId: string, fileId: string): Promise<FileInfo> {
        this.ensureDb();
        return this.db.get(FileMetaStore, `${dataId}:${fileId}`);
    }

    updateFileInfo(dataId: string, fileInfo: FileInfo) {
        this.ensureDb();
        return this.db.put(FileMetaStore, fileInfo, `${dataId}:${fileInfo.id}`);
    }

    async prune() {
        this.ensureDb();
        const formIds = new Set(await lastValueFrom(this.db.getKeys<string>(FormMetaStore).pipe(toArray())));

        const unusedfileKeys = await lastValueFrom(this.db.getKeys<string>(FileMetaStore).pipe(
            filter(key => !formIds.has(key.split(':')[0])),
            toArray()
        ));

        for (const key of unusedfileKeys) {
            await this.deleteFile(key);
        }
    }

    private get db(): IndexedDbWrapper {
        return this._db as IndexedDbWrapper;
    }

    private listFiles(dataId: string): Observable<FileInfo> {
        this.ensureDb();
        return this.db.getValues(FileMetaStore, IDBKeyRange.bound(dataId + ':', dataId + ':\uffff'));
    }

    private sum(numbers: number[]): number {
        return numbers.reduce((acc, s) => acc + s, 0);
    }

    private uploadFile(info: FormInfo, fileInfo: FileInfo, progressCallback?: (progress: Progress) => void, signal?: AbortSignal): Promise<Progress> {

        if (signal && signal.aborted) {
            Promise.reject(abortedError);
        }

        return new Promise<Progress>(async (resolve, reject) => {

            try {
                if (signal) {
                    signal.addEventListener('abort', () => reject(abortedError));
                }

                if (fileInfo.state === FileState.Uploaded) {
                    resolve({ id: fileInfo.id, done: fileInfo.size, total: fileInfo.size });
                    return;
                }

                this.ensureDb();
                const buf = await this.db.get<ArrayBuffer>(FileStore, fileInfo.id);

                if (buf == null) {
                    // already uploaded and deleted
                    resolve({ id: fileInfo.id, done: fileInfo.size, total: fileInfo.size });
                    return;
                }

                const bucket = new Bucket(this.client, info);
                const file = this.convertToFile(buf, fileInfo);

                // Upload attachment
                const progress = await bucket.uploadAttachment(file, fileInfo.id, progressCallback, signal);

                // cap progress to actual file size, as the uploads are slightly bigger
                progress.done = Math.min(progress.done, fileInfo.size);
                progress.total = Math.min(progress.total, fileInfo.size);
                fileInfo.state = FileState.Uploaded;

                await this.db.put(FileMetaStore, fileInfo, `${info.id}:${fileInfo.id}`);
                console.log('Finished file', fileInfo.name);

                resolve({
                    total: progress.total,
                    done: progress.total
                });
            } catch (e) {
                console.warn('OfflineQueue.uploadFile catched error', e);
                reject(e);
            }
        });
    }

    private convertToFile(buf: ArrayBuffer, fileInfo: FileInfo): File {
        try {
            return new File([buf], fileInfo.name, fileInfo.properties);
        } catch (e) {
            // IE11 screw up;
            console.warn('File constructor failed:', e);

            const file: any = new Blob([buf], fileInfo.properties);
            file.name = fileInfo.name;
            file.lastModified = fileInfo.properties.lastModified;

            return file as File; // I hearby proclaim you File.
        }
    }

    /** Lazy init when needed */
    private ensureDb() {
        const name = this.buildDbName();

        if (this.openedName === name && this.db != null) {
            return;
        }

        // We're not closing the previous database here
        // The assumption is that we will not have many open in 1 session

        this._db = new IndexedDbWrapper(this.errorService);
        this.db.db = new Promise((resolve, reject) => {
            const request = indexedDB.open(this.buildDbName(), 1);

            request.onerror = e => reject(e);
            request.onsuccess = () => resolve(request.result);
            request.onupgradeneeded = () => {
                // Form data, key is the form data id
                request.result.createObjectStore(FormDataStore);

                // Form metadata, key is form data id
                request.result.createObjectStore(FormMetaStore);

                // File buffers, key is file id
                request.result.createObjectStore(FileStore);

                // File metadata, key is {dataId}_{fileId}
                request.result.createObjectStore(FileMetaStore);
            };
        });

        this.openedName = name;
    }

    private buildDbName(): string {
        const parts: string[] = [
            'UfOfflineForms',
            this.config.unifii.tenant as string,
            this.config.unifii.projectId
        ];

        if (this.config.unifii.preview) {
            parts.push('preview');
        }

        if (this.auth.isAuthenticated) {
            parts.push((this.auth.userInfo as UserInfo).id as string);
        }

        return parts.join('-');
    }

    private readFile(file: File): Promise<ProgressEvent<FileReader>> {

        return new Promise((resolve, reject) => {

            const reader = new FileReader();

            reader.onerror = e => reject(e);
            reader.onload = e => resolve(e);

            reader.readAsArrayBuffer(file);
        });
    }

    private saveFormInfo(dataId: string, info: FormInfo) {
        return this.db.put(FormMetaStore, info, dataId);
    }
}
