import { Injectable } from '@angular/core';
import { ClaimConfig, Client, Company, CompanyClient, Provisioning, Query, Role, SortDirection, UserInfo } from '@unifii/sdk';
import { UserProvisioningProvider } from '@unifii/user-provisioning';

@Injectable()
export class UserProvisioning implements UserProvisioningProvider {

    private rolesPromise?: Promise<Role[]>;
    private userClaimsPromise?: Promise<ClaimConfig[]>;
    private companyClaimsPromise?: Promise<ClaimConfig[]>;

    constructor(
        private provisioning: Provisioning,
        private companyClient: CompanyClient,
        private client: Client
    ) { }

    async queryRoles(q?: string): Promise<Role[]> {
        if (this.rolesPromise == null) {
            this.rolesPromise = this.provisioning.getRoles();
        }

        if (q == null) {
            return this.rolesPromise;
        }

        return (await this.rolesPromise || []).filter(r => r.name.toLowerCase().includes(q.toLowerCase()));
    }

    queryCompanies(q?: string): Promise<Company[]> {
        const query = new Query();
        query.sort('name', SortDirection.Ascending);
        if (q) {
            query.q(q);
        }
        return this.companyClient.queryCompanies(query);
    }

    getUserClaimConfig(): Promise<ClaimConfig[]> {
        if (this.userClaimsPromise == null) {
            this.userClaimsPromise = this.client.getUserClaims();
        }

        return this.userClaimsPromise;
    }

    getCompanyClaimConfig(): Promise<ClaimConfig[]> {
        if (this.companyClaimsPromise == null) {
            this.companyClaimsPromise = this.client.getCompanyClaims();
        }

        return this.companyClaimsPromise;
    }

    queryUsers(q: Query): Promise<UserInfo[]> {
        return this.provisioning.queryUsers(q);
    }

    getUserByUsername(username: string): Promise<UserInfo> {
        return this.provisioning.getUserByUsername(username);
    }

    clearCache() {
        this.rolesPromise = undefined;
        this.userClaimsPromise = undefined;
        this.companyClaimsPromise = undefined;
    }
}