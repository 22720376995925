import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ContextProvider } from '@unifii/library/common';
import { PermissionAction, ProvisioningFunctions, UserInfo, UserStatus } from '@unifii/sdk';
import { FormPermissionController, UserInfoKey } from '@unifii/user-provisioning';

import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';

import { Config } from 'config';


export enum UserFormResourceType {
    Me, Users
}

export interface UserFormPermissionConfig {
    resourceType: UserFormResourceType;
    action: PermissionAction.Add | PermissionAction.Invite | PermissionAction.Update;
}

export const UserFormPermissionConfig = new InjectionToken<UserFormPermissionConfig>('UserFormPermissionConfig');

/**
 * UserFieldPermission
 */
@Injectable()
export class UserFormPermissionController implements FormPermissionController<UserInfo> {

    // type must be of type string also to handle claim permissions
    private requiredInputFields: (UserInfoKey | string)[] = [];

    constructor(
        @Inject(UserFormPermissionConfig) private config: UserFormPermissionConfig,
        @Inject(Authentication) private auth: Authentication,
        @Inject(Config) private discoverConfig: Config,
        private contextProvider: ContextProvider
    ) {
        if (this.discoverConfig.unifii.tenantSettings?.isUserEmailRequired === true) {
            this.requiredInputFields.push(UserInfoKey.Email);
        }
    }

    canUpdateUser(userInfo: UserInfo): boolean {
        if (this.config.resourceType === UserFormResourceType.Me) {
            return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), PermissionAction.Update).granted;
        }
        return this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+(userInfo.id as string)), PermissionAction.Update, userInfo, this.contextProvider.get()).granted;
    }

    /** In order to verify rights to update user claims, following condition must be matched:
     * - read claims configuration
     * - update the user
     * - no fields limitation
     * - at least one claim as fields limitation entry
     */
    canUpdateClaims(userInfo: UserInfo): boolean {

        if (!this.canListClaims()) {
            return false;
        }

        let updateUserGrantedInfo;
        if (this.config.resourceType === UserFormResourceType.Users) {
            if (userInfo?.id != null) {
                updateUserGrantedInfo = this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+(userInfo?.id as string)), PermissionAction.Update, userInfo, this.contextProvider.get());
            } else {
                updateUserGrantedInfo = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(), PermissionAction.Update);
            }
        } else {
            updateUserGrantedInfo = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), PermissionAction.Update);
        }

        if (!updateUserGrantedInfo.granted) {
            return false;
        }

        if ((updateUserGrantedInfo.fields ?? []).length === 0) {
            return true;
        }
        return (updateUserGrantedInfo.fields ?? []).find(f => f.includes('claims.')) != null;
    }

    // type must be of type string also to handle claim permissions
    inputEnabled(key: UserInfoKey | string, userInfo?: UserInfo): boolean {
        if (key === UserInfoKey.Units && !this.canListHierarchies()) {
            return false;
        }

        if (this.config.resourceType === UserFormResourceType.Me) {
            // User can always update their own password
            if (key === UserInfoKey.Password) {
                return true;
            }
            return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), this.config.action, key).granted;
        }

        if (userInfo?.id == null) {
            console.log('get granted without condition', this.config.action, key);
            // Add | Invite
            return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), this.config.action, key).granted;
        }

        return this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+(userInfo.id as string)), this.config.action, userInfo, this.contextProvider.get(), key).granted;
    }

    canListRoles(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getRolesPath(), PermissionAction.List).granted;
    }

    canListClaims(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getDefaultClaimsPath(), PermissionAction.List).granted;
    }

    canListCompanies(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.List).granted;
    }

    canListHierarchies(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getHierarchiesPath(), PermissionAction.List).granted;
    }

    canInvite(): boolean {
        if (this.config.resourceType === UserFormResourceType.Me) {
            return false;
        }
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted;
    }

    canDelete(userInfo: UserInfo): boolean {
        if (this.config.resourceType === UserFormResourceType.Me || userInfo?.id == null) {
            return false;
        }
        const status = ProvisioningFunctions.getUserStatus(userInfo);
        return status === UserStatus.Pending && this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+userInfo.id), PermissionAction.Delete, userInfo, this.contextProvider.get()).granted;
    }

    requiredInputField(key: UserInfoKey | string): boolean {
        return this.requiredInputFields.includes(key);
    }
}
