import { Component, Inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, DescriptionListItem, SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { Provisioning, TenantSettings } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { DiscoverTranslationKey } from 'discover/discover.tk';

import { Config } from 'config';


@Component({
    selector: 'ud-request-password-change',
    templateUrl: 'request-password-change.html'
})
export class RequestPasswordChangeComponent implements OnDestroy {

    readonly commonTK = CommonTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    control: UfControl;
    successMessage: string;
    successContacts: DescriptionListItem[];

    constructor(
        private provisioning: Provisioning,
        private errorService: ErrorService,
        private translate: TranslateService,
        private accessManager: UserAccessManager,
        @Inject(Config) private config: Config
    ) {
        this.control = new UfControl(ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)));
    }

    ngOnDestroy() {
        // clean errors
        this.accessManager.showError(null);
    }

    async resetPassword(): Promise<void> {

        this.accessManager.showError(null);

        if (this.control.invalid) {
            this.control.setSubmitted();
            return;
        }

        const username = this.control.value.trim();

        try {
            await this.provisioning.resetPassword(username);
            this.updateSuccessMessage(this.config.unifii.tenantSettings);
        } catch (error) {
            this.accessManager.showError(this.errorService.createError(this.discoverTK.RequestPasswordChangeErrorRequestFail, error));
        }
    }

    private updateSuccessMessage(tenantSettings?: TenantSettings) {

        this.successMessage = this.translate.instant(DiscoverTranslationKey.RequestPasswordChangeFeedbackEmailSent);
        this.successContacts = [];

        if (!tenantSettings) {
            return;
        }

        if (tenantSettings.contactName) {
            this.successContacts.push({
                term: this.translate.instant(DiscoverTranslationKey.ContactInfoNameLabel),
                description: {
                    description: tenantSettings.contactName
                },
            });
        }

        if (tenantSettings.contactEmail) {
            this.successContacts.push({
                term: this.translate.instant(DiscoverTranslationKey.ContactInfoEmailLabel),
                description: {
                    description: tenantSettings.contactEmail,
                    href: `mailto:${tenantSettings.contactEmail}`
                }
            });
        }

        if (tenantSettings.contactPhone) {
            this.successContacts.push({
                term: this.translate.instant(DiscoverTranslationKey.ContactInfoPhoneLabel),
                description: {
                    description: tenantSettings.contactPhone,
                    href: `tel:${tenantSettings.contactPhone}`
                }
            });
        }
    }
}
