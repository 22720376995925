import { from, Observable } from 'rxjs';

import { SortStatus, TableDataSource } from '@unifii/library/common';
import { FormData, Query, RequestAnalyticsOrigin } from '@unifii/sdk';

import { BetterFormService } from 'shell/form/better-form.service';


export class BucketTableDataSource extends TableDataSource<FormData> {

    private _query: Query = new Query();

    constructor(
        private betterFormService: BetterFormService,
        query?: Query,
        private search?: string,
        private sort?: SortStatus
    ) {
        super();

        if (query != null) {
            // todo: should copy query rather than reassigning
            this._query = query;
        }

        // Open text search
        if (this.search && this.search.trim().length) {
            this._query = this._query.q(`${this.search}*`);
        }

        // Sort
        if (this.sorted) {
            this._query = this._query.sort((this.sort as SortStatus).name, (this.sort as SortStatus).direction);
        }
    }

    get filtered(): boolean {
        return this.search ? this.search.trim().length > 0 : false;
    }

    get sorted(): boolean {
        return this.sort != null;
    }

    load(offset?: number, limit?: number) {
        // Limit
        if (limit) {
            this._query = this._query.limit(limit, offset);
        }

        /** todo: to be reviewed when services workers are added for full offline support */
        return this.betterFormService.query(this._query, this.loadController?.signal, { origin: RequestAnalyticsOrigin.Table }).then(items => {
            this.stream.next({ data: items });
        }, error => {
            this.stream.next({ error });
        });
    }

    getDownloadUrl(limit = 10000): string | null {
        this._query.limit(limit);
        return this.betterFormService.getDownloadUrl(this._query || undefined);
    }

    download(limit?: number): Observable<any> {
        this._query.limit(limit || 10000);
        return from(this.betterFormService.query(this._query));
    }

}
