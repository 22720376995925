import { Inject, Injectable } from '@angular/core';
import { WindowWrapper } from '@unifii/library/common';


@Injectable()
export class NavigatorService {

    constructor( @Inject(WindowWrapper) private window: Window) { }

    /** Browser in iOS? */
    get isIOS(): boolean {
        return /iPad|iPhone|iPod/.test(this.window.navigator.userAgent);
    }

    /** Browser Safari? */
    get isSafari(): boolean {
        return !!this.window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    }
}
