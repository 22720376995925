import { Component } from '@angular/core';
import { Page } from '@unifii/sdk';

import { PageContent } from './content-types';


@Component({
    templateUrl: './page.html'
})
export class PageComponent implements PageContent {

    page: Page;

}
