import { Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    DownloadConfig, FilterEntries, FilterEntry, FilterQueryBuilder, FilterSerializer, FilterValue, TableContainerManager, TableInputManager,
    TableInputs, TableManagerFunctions
} from '@unifii/components';
import { ContextProvider, DataPropertyDescriptor, SafeUrlFunctions, TableConfig } from '@unifii/library/common';
import { AstNode, ColumnDescriptor, Company, CompanyClient, PermissionAction, Query, Table, TableSourceType } from '@unifii/sdk';

import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ASTExpressionParser } from 'shell/table/ast-expression-parser';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DetailPath } from 'discover/discover-constants';


@Injectable()
export class CompanyTableContainerManager implements TableContainerManager<Company, FilterValue, FilterEntry> {

    tableConfig: TableConfig<Company>;
    showSearch = true;
    addActionConfig = true;
    defaultSort: string | undefined;
    downloadConfig?: DownloadConfig | undefined;
    customColumns: ColumnDescriptor[] = [];

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<Company>();

    inputManager?: TableInputManager<FilterValue, FilterEntry> | undefined;

    private filter?: AstNode;
    private contextFilter?: AstNode; // filter passed via url param $cf
    // dependencies
    private auth: Authentication;
    private companyClient: CompanyClient;
    private columnFactory: TableColumnFactory<Company>;
    private contextProvider: ContextProvider;

    constructor() {
        this.auth = inject(Authentication);
        this.companyClient = inject(CompanyClient);
        this.columnFactory = inject(TableColumnFactory);
        this.contextProvider = inject(ContextProvider);

        const { table, propertyDescriptors } = inject(TablePageConfig);

        this.defaultSort = table.defaultSort;

        this.inputManager = new TableInputManager(this.entries, this.serializer, this.queryBuilder);

        if (table.filter) {
            const astParser = inject(ASTExpressionParser);
            this.filter = astParser.parse(table.filter);
            this.inputManager.staticFilter = new Query().fromAst(this.filter as AstNode);
        }

        // set context filter
        const { $cf } = inject(ActivatedRoute).snapshot.params;
        if ($cf) {
            this.contextFilter = SafeUrlFunctions.decodeObj($cf) as AstNode;
        }

        this.setPermissionControls(table);
        this.setManagerConfig(table, propertyDescriptors);
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        let query: Query = new Query();

        if (inputs?.filters && this.inputManager != null) {
            query = this.inputManager.filterManager.toQuery(inputs.filters);
        }

        if (this.filter != null) {
            query = query.fromAst(this.filter);
        }

        if (this.contextFilter != null) {
            query = query.fromAst(this.contextFilter);
        }

        return new CompanyTableDataSource(this.companyClient, query, inputs?.q, inputs?.sort);
    }

    private setManagerConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>) {
        this.customColumns = (table.columns ?? []).filter(c => (c.heading != null || c.variations != null));

        const id = `table_${table.identifier}`;
        const columns = this.columnFactory.create(table.columns ?? [], propertyDescriptors, TableSourceType.Company);
        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);
        tableConfig.rowLink = (item: Company) => this.getRowLink(item, table.detail != null);

        this.tableConfig = tableConfig;
    }

    private setPermissionControls(config: Table) {
        this.addActionConfig = config.detail == null && this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.Add).granted;
    }

    private getRowLink(company: Company, hasDetailPage = false) {
        const isGranted = this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(company.id), PermissionAction.Read, company, this.contextProvider.get()).granted;
        if (isGranted) {
            if (hasDetailPage) {
                return [DetailPath, { id: company.id }];
            }
            return company.id + '';
        }
        return [];
    }

    private get entries(): FilterEntry[] {
        try {
            return inject(FilterEntries) as FilterEntry[];
        } catch (e) {
            return [];
        }
    }

    private get serializer(): FilterSerializer<FilterValue, FilterEntry> | undefined {
        try {
            return inject(FilterSerializer) as FilterSerializer<FilterValue, FilterEntry>;
        } catch (e) {
            return;
        }
    }

    private get queryBuilder(): FilterQueryBuilder<FilterValue, FilterEntry> | undefined {
        try {
            return inject(FilterQueryBuilder) as FilterQueryBuilder<FilterValue, FilterEntry>;
        } catch (e) {
            return;
        }
    }

}

