import { FilterEntry, FilterSerializer, FilterType, FilterValue, UfFilterSerializer } from '@unifii/components';
import { Dictionary } from '@unifii/sdk';


enum RangeSuffix {
    From = 'Start',
    To = 'End'
}


export class ReportFilterSerialzier implements FilterSerializer<FilterValue, FilterEntry> {

    private default = new UfFilterSerializer();

    serialize(value: FilterValue, entry: FilterEntry): string | null {
        return this.default.serialize(value, entry);
    }

    deserialize(value: string | null, entry: FilterEntry): Promise<FilterValue> {
        return this.default.deserialize(value, entry);
    }

    serializeAll(values: Dictionary<FilterValue>, entries: FilterEntry[]): Dictionary<string> {
        const serialized: Dictionary<string> = {};

        for (const entry of entries) {
            const value = this.default.serialize(values[entry.identifier], entry);
            if (value == null) {
                continue;
            }

            if (this.isRange(entry.type)) {
                const [from, to] = value.trim().split(',').map(p => p.trim());
                serialized[entry.identifier + RangeSuffix.From] = from;
                serialized[entry.identifier + RangeSuffix.To] = to;
            } else {
                serialized[entry.identifier] = value;
            }
        }
        return serialized;
    }

    async deserializeAll(values: Dictionary<string | null>, entries: FilterEntry[]): Promise<Dictionary<FilterValue>> {
        const deserialized: Dictionary<FilterValue> = {};

        for (const entry of entries) {
            if (this.isRange(entry.type)) {
                const from = values[entry.identifier + RangeSuffix.From];
                const to = values[entry.identifier + RangeSuffix.To];
                if (from != null || to != null) {
                    deserialized[entry.identifier] = await this.default.deserialize([from ?? '', to ?? ''].join(','), entry);
                }
            } else {
                const value = await this.default.deserialize(values[entry.identifier], entry);
                if (value != null) {
                    deserialized[entry.identifier] = value;
                }
            }
        }
        return deserialized;
    }

    private isRange(type: FilterType): boolean {
        return [FilterType.DateRange, FilterType.TimeRange, FilterType.NumberRange, FilterType.DatetimeRange, FilterType.ZonedDatetimeRange].includes(type);
    }

}
