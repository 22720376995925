import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { Client } from '@unifii/sdk';

import { TenantSettingsService } from 'shell/services/tenant-settings.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { DeviceService } from 'capacitor/device.service';

import { Config, Environment } from 'config';


@Component({
    selector: 'ud-tenant-selector',
    templateUrl: './tenant-selector.html',
})
export class TenantSelectorComponent {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly commonTK = CommonTranslationKey;

    tenant: string;
    control: UfControl;

    constructor(
        @Inject(Config) private config: Config,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private accessManager: UserAccessManager,
        @Inject(Environment) private env: Config,
        private tenantSettings: TenantSettingsService,
        private deviceService: DeviceService,
        private client: Client,
    ) {

        // it is unlikely but if someone hacks the url to get to this component reroute to main
        if (this.env.unifii.tenant) {
            this.router.navigate(['/login']);
        }

        this.control = this.createControl();

        this.route.params.subscribe(params => {
            if (params.tenant) {
                this.tenant = params.tenant.toLowerCase();
            }
        });
    }

    async setTenant(): Promise<void> {

        this.accessManager.showError(null);

        /**
         * Extra guard added for Mobile Safari UI/WKWebView
         * tenant is evaluated as undefined guessing it's caused timing issue with validators not firing fast enough
         * sentry error: https://sentry.unifii.dev/organizations/unifii-pty-ltd/issues/258/events/?environment=prod&project=7
         */
        if (this.control.invalid || !this.tenant) {
            this.control.setSubmitted();
            return;
        }
        try {
            await this.tenantSettings.setTenant(this.tenant.toLowerCase());
            await this.registerDevice();
            this.router.navigate(['/login']);
        } catch (e) {
            this.accessManager.showError(e);
        }
    }

    private async registerDevice() {

        const device = await this.deviceService.device();

        if (!(device && device.notificationToken)) {
            return;
        }

        try {
            this.client.registerDevice(device);
        } catch (error) {
            // Fail silently
        }
    }

    private createControl(): UfControl {

        return new UfControl([
            ValidatorFunctions.custom(v => this.config.unifii.tenant != null || (v != null && v !== ''), this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)),
            ValidatorFunctions.pattern(/^[A-Za-z0-9.-]+$/, this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidCharacters)),
            ValidatorFunctions.custom(v => !/\s/.test(v), this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidSpaces))
        ]);
    }

}
