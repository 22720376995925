import { Subscription } from 'rxjs';

import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterEntry, FilterValue, TableContainerManager } from '@unifii/components';
import { Breadcrumb, ContextProvider, ModalService, SharedTermsTranslationKey, ToastService, CommonTranslationKey } from '@unifii/library/common';
import { FieldHelperFunctions, FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { PrintConfig, PrintFormModalComponent, SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import {
    Client, Definition, FieldType, FormData, ParentFormData, PermissionAction, PublishedContent, TransitionTrigger, UserContext
} from '@unifii/sdk';

import { FormContent } from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { BetterFormService } from 'shell/form/better-form.service';
import { FormTriggerComponent, FormTriggerData } from 'shell/form/form-trigger.component';
import { NavigationService } from 'shell/nav/navigation.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';
import { TablePageConfig } from 'shell/table/table-page-config';

import { FormPath } from 'discover/discover-constants';
import { DiscoverContext } from 'discover/discover-context';
import { TriggerFormContextProvider } from 'discover/trigger-form-context-provider';

import { Config } from 'config';


interface ParentFormInfo {
    label: string;
    routerLink: any[];
}

/**
 * FormComponent
 * This class is created by the ContentNodeComponent, which is responsible for:
 *  - Catching any load errors
 *  - Catching any basic ACL errors
 *  - Show loading Skeleton
 *  - Resolving Data, FormData and Definition
 *
 * to route to this component using the ContentNode use the following commands
 * [`/${FormPath}`, { bucket, id }]
 * eg: /~form;bucket=abc;id=123
 */
@Component({
    templateUrl: './form.html',
    styleUrls: ['./form.less']
})
export class FormComponent implements OnInit, EditedData, FormContent {

    readonly sharedTK = SharedTermsTranslationKey;
    readonly shellTK = ShellTranslationKey;
    readonly commonTK = CommonTranslationKey;

    // Status
    newSubmission: boolean;
    formLabel: string;
    isDisabled: boolean;
    busy: boolean;
    triggerError: AppError;
    user: UserContext | undefined;
    edited: boolean;
    breadcrumbs: Breadcrumb[] = [];
    prevUrl?: string;

    // Form
    definition: Definition;
    formData: FormData;
    formConfig: FormConfiguration;
    printConfig: PrintConfig | null;
    parentInfo: ParentFormInfo;

    private _formComponent: UfFormComponent;
    private changesSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private context: DiscoverContext,
        private navigation: NavigationService,
        private modalService: ModalService,
        private toastService: ToastService,
        private translate: TranslateService,
        private errorService: ErrorService,
        private router: Router,
        @Inject(Config) private config: Config,
        @Inject(PublishedContent) private content: PublishedContent,
        private formService: BetterFormService,
        @Inject(FormSettings) private settings: FormSettings,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        @Inject(Authentication) private auth: Authentication,
        @Optional() @Inject(TableContainerManager) private tableManager: TableContainerManager<FormData, FilterValue, FilterEntry>,
        @Optional() @Inject(TablePageConfig) private tableConfig: TablePageConfig,
        @Optional() private detailPage: TableDetailComponent,
        private toast: ToastService
    ) { }

    get bucketLabel(): string | undefined {
        return this.navigation.current?.name;
    }

    ngOnInit() {

        this.user = this.contextProvider.get().user;
        this.isDisabled = this.getDisabledStatus(this.config.unifii.projectId, this.definition.bucket as string, this.formData);
        this.formLabel = this.getFormLabel(this.formData);
        this.formService.bucket = this.definition.bucket as string;
        this.newSubmission = this.formData == null;

        this.prevUrl = this.route.snapshot.params.prevUrl;
        if (!this.prevUrl) {
            this.breadcrumbs = this.getBreadcrumbs();
        }

        this.formConfig = {
            optionalCancelButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            optionalSubmitButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionSubmit)
        };

        this.settings.uploader = this.formService.getUploader(this.formData?.id || '');

        if (this.formData == null) {
            this.formData = { id: Client.generateUUID() };
        }

        this.setParentInfo();
    }

    @ViewChild(UfFormComponent, { static: false }) set formComponent(v: UfFormComponent) {

        if (v == null || this.formComponent) {
            return;
        }
        this._formComponent = v;

        if (v.rootControl) {
            this.changesSubscription = v.rootControl.valueChanges.subscribe(() => {
                if (this._formComponent.rootControl.dirty) {
                    this.edited = true;
                }
            });
        }
    }

    get formComponent(): UfFormComponent {
        return this._formComponent;
    }

    private get formBreadcrumb() {
        return this.newSubmission ? this.translate.instant(SharedTermsTranslationKey.NewLabel) :
            this.formData?._seqId || this.definition.label;
    }

    back() {
        if (this.prevUrl) {
            this.router.navigateByUrl(this.prevUrl);
            return;
        }

        const breadcrumb = this.breadcrumbs.slice(-2, -1)[0];
        if (breadcrumb?.urlSegments) {
            this.router.navigate(breadcrumb.urlSegments, { relativeTo: this.route });
            return;
        }

        this.router.navigate(['/']);
    }

    async print() {
        const logo: string | undefined = this.context?.project?.logo?.url;

        const summary = await this.modalService.openFit<null, boolean>(PrintFormModalComponent, null);
        if (summary !== undefined) {
            this.printConfig = {
                definition: JSON.parse(JSON.stringify(this.definition)),
                data: JSON.parse(JSON.stringify(this.formData)),
                logoUrl: logo,
                uploader: this.settings.uploader,
                summary
            };
        }
    }

    async save(args: SubmitArgs) {

        if (this.busy) {
            return;
        }

        const path = this.newSubmission ? PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, this.formService.bucket) : PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, this.formService.bucket, args.data.id as string);
        const action = this.newSubmission ? PermissionAction.Add : PermissionAction.Update;
        if (!this.auth.getGrantedInfo(path, action, args.data, this.contextProvider.get()).granted) {
            this.toast.info(this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized));
            return;
        }

        this.busy = true;
        let savedFormData;

        try {
            this.unsubscribeToFormChanges();

            savedFormData = await this.formService.save(args.data, this.definition);
            args.done(savedFormData);

            this.edited = false;
            this.busy = false;
            this.toastService.success(this.translate.instant(ShellTranslationKey.FormFeedbackSaved));

            if (this.tableManager != null) {
                this.tableManager.updateItem?.next({ item: savedFormData, key: 'id' });
            }

            if (this.detailPage != null) {
                this.detailPage.updateDetails(savedFormData);
            }

            for (const trigger of (args.triggers || [])) {
                await this.openTargetForm(trigger, savedFormData);
            }

            if (this.keepEditingOnNext(savedFormData)) {
                this.subscribeToFormChanges();
                return;
            }
            this.back();

        } catch (e) {

            if (savedFormData == null && args.triggers?.length) {
                this.triggerError = this.errorService.createError('Form trigger failed', e);
            } else {
                const error = this.errorService.createSaveError('form', e);
                this.toast.error(error.message);
            }

        } finally {
            this.busy = false;
        }
    }

    private async setParentInfo() {
        if (this.formData?._parent == null) {
            return;
        }
        this.parentInfo = await this.getParentInfo(this.formData._parent);
    }

    private getDisabledStatus(projectId: string, bucket: string, formData?: FormData): boolean {
        if (!formData) {
            return !this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(projectId, bucket), PermissionAction.Add).granted;
        }
        return !this.auth.getGrantedInfo(PermissionsFunctions.getBucketDocumentPath(projectId, bucket, formData.id as string), PermissionAction.Update, formData, this.contextProvider.get()).granted;
    }

    private subscribeToFormChanges() {

        this.unsubscribeToFormChanges();
        this.edited = false;
    }

    private unsubscribeToFormChanges() {
        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }
    }

    private openTargetForm(trigger: TransitionTrigger, formData: FormData) {
        return this.modalService.openFit<FormTriggerData, boolean>(
            FormTriggerComponent,
            (Object.assign({}, trigger, { parentBucket: this.formService.bucket })) as FormTriggerData,
            undefined,

            [{
                provide: ContextProvider,
                useValue: new TriggerFormContextProvider(this.contextProvider, formData)
            }]
        );
    }

    // Stay on this page to allow the User to fill the form 'Next' Section
    private keepEditingOnNext(formData: FormData) {

        // Triggered transition, to find target State
        const sections = (this.definition.fields || []).filter(f =>
            f.type === FieldType.Section &&
            (f.transitions || []).find(t => t.source === formData._state) != null
        );

        const nextTag = !!sections.find(s => s.transitions?.find(t => t.action === formData._action && t.tags?.some(v => (v === 'Next' || v === 'next'))));

        // Triggered transition, match 'Next' action keyword, or if target state with current action has 'Next' Tag
        if (formData._action !== 'Next' && !nextTag) {
            return false;
        }

        // Check at least a section for target state is accessible by current user
        const match = sections.find(s => FieldHelperFunctions.areRolesMatching(this.user?.roles, s.role));
        return match != null;
    }

    private async getParentInfo(parent: ParentFormData): Promise<{ label: string; routerLink: any[] }> {

        const seqId = parent.seqId || parent.id;
        const routerLink = [`/${FormPath}`, { bucket: parent.bucket, id: parent.id }];

        try {
            if (parent.definitionIdentifier) {
                const definition = await this.content.getForm(parent.definitionIdentifier);

                return {
                    label: `${definition.label} - ${seqId}`,
                    routerLink
                };
            }
        } catch (e) { }

        return {
            label: seqId,
            routerLink
        };
    }

    private getFormLabel(formData: FormData): string {

        if (formData == null) {
            return `${this.definition.label} - ${this.translate.instant(SharedTermsTranslationKey.NewLabel)}`;
        }

        if (formData?._seqId) {
            return `${this.definition.label} - ${formData._seqId}`;
        }

        return `${this.definition.label}`;
    }

    /**
     * TODO: a variation of this duplicated in other components
     * create smart way of delivering breadcrumbs to contentNode
     */
    private getBreadcrumbs(): Breadcrumb[] {
        const breadcrumbs: Breadcrumb[] = [];

        if (this.tableConfig != null) {
            const name = this.tableConfig?.table?.title;
            const urlSegments = ['..'];
            breadcrumbs.push({ name, urlSegments });
        }

        if (this.detailPage != null) {
            const name = this.tableConfig?.table?.title;
            const urlSegments = ['..'];
            if (breadcrumbs.length) {
                breadcrumbs[0].urlSegments = ['../../'];
            }
            breadcrumbs.push({ name, urlSegments });
        }

        if (breadcrumbs.length) {
            breadcrumbs.push({ name: this.formBreadcrumb });
        }
        return breadcrumbs;
    }





}

