// Routes
export const UserAccessRootPath = 'login';
export const UnifiiLoginPath = 'form';
export const UnifiiPasswordChangePath = 'request-password-change';
export const SSOPath = 'sso';
export const TenantSelectionPath = 'tenant';
export const ProjectSelectionPath = 'projects';
export const CompleteRegistrationPath = 'complete-registration';
export const PasswordChangePath = 'password-change';
export const ExternalPath = 'e'; // used when routing from form to form
/**
 * Main routes (or paths the may be used at root level) are
 * prefixed with ~ symbol to prevent clashes with identifiers or id's
 */
export const FormPath = '~form'; // used when routing from form to form
export const SignInPath = '~sign-in'; // sign feature never delete
export const PagePath = '~page'; // used when routing to a page directly
export const DetailPath = '~detail';
export const NewItemPath = '~new'; // form, company, user creation
export const EditPath = `~edit`; // view form, company, user
export const UserInfoPath = `~user-info`;
export const UserProfilePath = `~user-profile`;
export const CreateUserPath = `~create-user`;
export const InviteUserPath = `~invite-user`;
