import {
    addDays, addMonths, addWeeks, differenceInMilliseconds, endOfDay, endOfMonth, endOfWeek, endOfYear, format, parse, startOfDay, startOfMonth,
    startOfWeek, startOfYear
} from 'date-fns';

import { FnsDatetime } from '@unifii/library/common';
import { Dictionary, Field, FormData } from '@unifii/sdk';
import { FieldHelperFunctions } from '@unifii/library/smart-forms';


export const MomentDateFormat = 'YYYY-MM-DD'; // Do not remove, used in forks of discover
export const MomentDatetimeFormat = 'YYYY-MM-DDTHH:mm:ss';

export const getStartOf = (date: Date, unit: string): Date => {
    switch (unit) {
        case 'day':
            return startOfDay(date);
        case 'week':
            return startOfWeek(date);
        case 'month':
            return startOfMonth(date);
        case 'year':
            return startOfYear(date);
        default:
            return date;
    }
};

export const getEndOf = (date: Date, unit: string): Date => {
    switch (unit) {
        case 'day':
            return endOfDay(date);
        case 'week':
            return endOfWeek(date);
        case 'month':
            return endOfMonth(date);
        case 'year':
            return endOfYear(date);
        default:
            return date;
    }
};

export const addOf = (date: Date, unit: string, amount: number): Date => {
    switch (unit) {
        case 'day':
            return addDays(date, amount);
        case 'week':
            return addWeeks(date, amount);
        case 'month':
            return addMonths(date, amount);
        default:
            return date;
    }
};

export const createIntervals = (startDate: string, endDate: string, incrementBy: string, dateLabelFormat: string) => {

    const result: { start: string; end: string; label: string }[] = [];

    let rangeStart = getStartOf(parse(startDate, FnsDatetime, new Date()), incrementBy);
    const rangeEnd = getEndOf(parse(endDate, FnsDatetime, new Date()), incrementBy);

    while (differenceInMilliseconds(rangeEnd, rangeStart) > 0) {
        const intervalStart = rangeStart;
        const intervalEnd = getEndOf(new Date(rangeStart), incrementBy);
        let label = '';

        if (incrementBy === 'day') {
            label = format(intervalEnd, dateLabelFormat);
        } else {
            label = format(intervalStart, dateLabelFormat) + ' to ' + format(intervalEnd, dateLabelFormat);
        }

        result.push({
            start: format(intervalStart, FnsDatetime),
            end: format(intervalEnd, FnsDatetime),
            label
        });

        rangeStart = getStartOf(addOf(intervalStart, incrementBy, 1), incrementBy);
    }

    return result;
};

export const summ = (forms: FormData[], countField: string): number => {
    let result = 0;

    for (const form of forms) {
        const value = form[countField];
        if (value) {
            result = result + value;
        }
    }

    return result;
};

export const average = (forms: FormData[], averageField: string): number =>
    forms
        .map(form => form[averageField])
        .reduce((prev, curr) => curr = Number(curr) + Number(prev)) / forms.length;


export const findField = (fields: Field[], id: string): Field | null => {

    for (const field of FieldHelperFunctions.fieldIterator(fields)) {
        if (field.identifier === id) {
            return field;
        }
    }

    return null;
};

export const cleanObj = (obj: Dictionary<any>): Dictionary<any> => {
    const copy = { ...obj };
    for (const key of Object.keys(copy)) {
        const value = copy[key];
        if (value == null || value === '') {
            delete copy[key];
        }
    }
    return copy;
};