import { Inject, Injectable } from '@angular/core';
import { Repository } from '@unifii/library/common';
import { Company, ProjectInfo } from '@unifii/sdk';

import { LanguageKey } from 'shell/services/translations.service';

import { ProjectPin } from 'discover/discover-model';

import { Config, Environment } from 'config';


const ProjectKey = 'DiscoverProject';
const PreviewKey = 'DiscoverPreview';
const PinKey = 'DiscoverPins';
const CompanyKey = 'DiscoverCompany';

/**
 * Context service holds references to some essential discover values that are mutable.
 */
@Injectable()
export class DiscoverContext {

    constructor(
        private repo: Repository,
        @Inject(Environment) private env: Config,
    ) { }

    get preview(): boolean {
        return this.repo.load(PreviewKey) || false;
    }

    set preview(v: boolean) {
        this.repo.store(PreviewKey, v);
    }

    /** todo: does this belong here or in config */
    get project(): ProjectInfo | null {
        return this.repo.load(ProjectKey);
    }

    set project(v: ProjectInfo | null) {
        this.repo.store(ProjectKey, v);
    }

    get company(): Company | null {
        return this.repo.load(CompanyKey);
    }

    set company(v: Company | null) {
        this.repo.store(CompanyKey, v);
    }

    get language(): string | undefined {
        return this.repo.load(LanguageKey) || undefined;
    }

    set language(v: string | undefined) {
        this.repo.store(LanguageKey, v);
    }

    get pins(): ProjectPin[] {
        return this.repo.load(PinKey) || [];
    }

    set pins(v: ProjectPin[]) {
        this.repo.store(PinKey, v);
    }

    /** pins not reset on clear as are persistent across authentication */
    clear() {
        this.preview = this.env.unifii?.preview === true;
        this.project = null;
        this.company = null;
    }
}
