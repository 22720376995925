import { Component, Input } from '@angular/core';
import { ContentField } from '@unifii/library/smart-forms';
import { Field, FieldTemplate } from '@unifii/sdk';


@Component({
    templateUrl: './file-list.html'
})
export class FileListDisplayComponent implements ContentField {

    @Input() content: any[];
    @Input() field: Field;

    readonly fieldTemplate = FieldTemplate;
}
