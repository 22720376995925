

import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb, CommonTranslationKey, SharedTermsTranslationKey } from '@unifii/library/common';
import { PermissionAction } from '@unifii/sdk';

import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';

import { UserInputType } from './user-types';


@Component({
    selector: 'ud-user-create',
    templateUrl: './user-create.html'
})
export class UserCreateComponent {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly commonTK = CommonTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    error: string;

    inputType: string;
    displayTabs: boolean;

    breadcrumbs: Breadcrumb[] = [];

    get breadCrumbsLabel(): string {
        return this.inputType === UserInputType.Invite ?
            this.translate.instant(SharedTermsTranslationKey.ActionInvite) :
            this.translate.instant(SharedTermsTranslationKey.ActionCreate);
    }

    constructor(
        public context: DiscoverContext,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        @Inject(Authentication) private auth: Authentication,
    ) {
        this.inputType = this.route.snapshot.data.inputType;
        this.breadcrumbs.push({
            name: this.translate.instant(DiscoverTranslationKey.UsersLabel),
            urlSegments: ['../']
        }, {
            name: this.breadCrumbsLabel
        });
        if ((this.inputType === UserInputType.Invite && !this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted) ||
            (this.inputType === UserInputType.Create && !this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Add).granted) ||
            !this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getRolesPath(), PermissionAction.List).granted ||
            !this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getDefaultClaimsPath(), PermissionAction.List).granted) {
            this.error = this.translate.instant(ShellTranslationKey.ErrorAccessDenied);
        }
    }

    back() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
