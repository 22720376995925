import { Component, HostBinding, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DescriptionListItem, Modal, ModalData, ModalRuntime, SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { Client, FieldType, TenantSettings } from '@unifii/sdk';

import { DiscoverTranslationKey } from 'discover/discover.tk';

import { Config } from 'config';


export interface ContactInfoModalData {
    tenantSettings?: TenantSettings;
}

@Component({
    templateUrl: './contact-info-modal.html'
})
export class ContactInfoModalComponent implements Modal<ContactInfoModalData, void> {

    @HostBinding('class.ud-card') class = true;
    @HostBinding('class.grid') class1 = true;

    readonly discoverTK = DiscoverTranslationKey;

    contactDetails: DescriptionListItem[] | null;
    companyControl: UfControl;
    company: string;
    name: string | null;

    constructor(
        private client: Client,
        @Inject(ModalData) public data: ContactInfoModalData,
        @Inject(Config) public config: Config,
        public runtime: ModalRuntime<ContactInfoModalData, void>,
        private translate: TranslateService
    ) {
        this.contactDetails = this.getContactDetails(this.data.tenantSettings);

        this.companyControl = new UfControl([
            ValidatorFunctions.custom(v => this.config.unifii.tenant != null || (v != null && v !== ''), this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)),
            ValidatorFunctions.pattern(/^[A-Za-z0-9.-]+$/, this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidCharacters)),
            ValidatorFunctions.custom(v => !/\s/.test(v), this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidSpaces))
        ]);
    }

    close() {
        this.runtime.close();
    }

    retrieve() {
        this.companyControl.setSubmitted(true);

        if (this.companyControl.invalid) {
            return;
        }

        this.name = this.company;
        this.load();
    }

    async load() {

        this.config.unifii.tenant = this.name || undefined;
        const settings = await this.client.getTenantSettings();
        this.contactDetails = this.getContactDetails(settings);
    }

    private getContactDetails(tenantSettings: TenantSettings | undefined): DescriptionListItem[] | null {

        if (tenantSettings == null) {
            return null;
        }

        const details = [];

        if (tenantSettings.contactName != null) {
            details.push({ term: this.translate.instant(DiscoverTranslationKey.ContactInfoNameLabel), description: tenantSettings.contactName });
        }

        if (tenantSettings.contactEmail != null) {
            details.push({ term: this.translate.instant(DiscoverTranslationKey.ContactInfoEmailLabel), description: tenantSettings.contactEmail });
        }

        if (tenantSettings.contactPhone != null) {
            details.push({ term: this.translate.instant(DiscoverTranslationKey.ContactInfoPhoneLabel), description: tenantSettings.contactPhone });
        }

        return details;
    }
}