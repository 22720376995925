import { Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    AddActionConfig, DownloadConfig, FilterEntries, FilterEntry, FilterQueryBuilder, FilterSerializer, FilterValue, TableContainerManager,
    TableInputManager, TableInputs, TableManagerFunctions
} from '@unifii/components';
import { ContextProvider, DataPropertyDescriptor, SafeUrlFunctions, TableConfig } from '@unifii/library/common';
import { AstNode, Client, ColumnDescriptor, FormData, Option, PermissionAction, Query, Table, TableSourceType } from '@unifii/sdk';

import { BetterFormService } from 'shell/form/better-form.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';
import { ASTExpressionParser } from 'shell/table/ast-expression-parser';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DetailPath, NewItemPath } from 'discover/discover-constants';

import { Config } from 'config';


@Injectable()
export class BucketTableContainerManager implements TableContainerManager<FormData, FilterValue, FilterEntry> {

    tableConfig: TableConfig<FormData>;
    showSearch = true;
    addActionConfig?: AddActionConfig;
    downloadConfig?: DownloadConfig;
    customColumns: ColumnDescriptor[] = [];
    defaultSort: string | undefined;

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<FormData>();

    inputManager?: TableInputManager<FilterValue, FilterEntry> | undefined;
    formDefinitions: Option[] = []; // list of form definitions associated with the table

    private tableInputs?: TableInputs<FilterValue>;
    private filter?: AstNode;
    private contextFilter?: AstNode; // filter passed via url param $cf
    // providers
    private client: Client;
    private auth: Authentication;
    private contextProvider: ContextProvider;
    private formService: BetterFormService;
    private config: Config;
    private columnFactory: TableColumnFactory<FormData>;
    private router: Router;
    private route: ActivatedRoute;
    private translate: TranslateService;

    constructor() {
        this.client = inject(Client);
        this.auth = inject(Authentication);
        this.contextProvider = inject(ContextProvider);
        this.formService = inject(BetterFormService);
        this.config = inject(Config);
        this.columnFactory = inject(TableColumnFactory);
        this.router = inject(Router);
        this.route = inject(ActivatedRoute);
        this.translate = inject(TranslateService);

        const { table, bucket, propertyDescriptors, addOptions } = inject(TablePageConfig);

        this.defaultSort = table.defaultSort;

        this.inputManager = new TableInputManager(this.entries, this.serializer, this.queryBuilder);

        if (table.filter) {
            const astParser = inject(ASTExpressionParser);
            this.filter = astParser.parse(table.filter);
            this.inputManager.staticFilter = new Query().fromAst(this.filter as AstNode);
        }

        // set context filter
        const { $cf } = inject(ActivatedRoute).snapshot.params;
        if ($cf) {
            this.contextFilter = SafeUrlFunctions.decodeObj($cf) as AstNode;
        }

        if (table.hideExport !== true) {
            this.downloadConfig = {
                name: `${bucket}.csv`,
                getUrl: this.getDownloadUrl.bind(this)
            };
        }

        this.formService.bucket = bucket as string;
        this.addActionConfig = this.createAddConfig(addOptions ?? []);
        this.setManagerConfig(table, propertyDescriptors);
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        this.tableInputs = inputs;
        let query: Query = new Query();

        if (inputs?.filters && this.inputManager != null) {
            query = this.inputManager.filterManager.toQuery(inputs.filters);
        }

        if (this.filter != null) {
            query = query.fromAst(this.filter);
        }

        if (this.contextFilter != null) {
            query = query.fromAst(this.contextFilter);
        }

        return new BucketTableDataSource(this.formService, query, inputs?.q, inputs?.sort);
    }

    addActionCallback = (identifier: string) => {
        this.router.navigate([NewItemPath, { definitionIdentifier: identifier }], { relativeTo: this.route });
    };

    private async getDownloadUrl(): Promise<string> {
        const dataSource = this.createDataSource(this.tableInputs);
        const url = dataSource.getDownloadUrl();
        if (!url) {
            throw new Error('Failed to get download url');
        }

        const { token } = await this.client.getDownloadToken(url);
        return `${url}&_dlt=${token}`;
    }

    private setManagerConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>) {
        this.customColumns = (table.columns ?? []).filter(c => (c.heading != null || c.variations != null));

        const id = `table_${table.identifier}`;
        const columns = this.columnFactory.create(table.columns ?? [], propertyDescriptors, TableSourceType.Bucket);
        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);

        tableConfig.rowLink = (item: FormData) => this.getRowLink(item, table.source as string, table.detail != null);

        this.tableConfig = tableConfig;
    }

    private getRowLink(formData: FormData, bucket: string, hasDetailPage = false) {
        const id = '' + formData.id;

        const isGranted = this.auth.getGrantedInfo(
            PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, bucket, id),
            PermissionAction.Read,
            formData,
            this.contextProvider.get()
        ).granted;

        if (isGranted) {
            if (hasDetailPage) {
                return [DetailPath, { id }];
            }
            return id;
        }
        return [];
    }

    private createAddConfig(options: Option[]): AddActionConfig | undefined {
        if (!options.length) {
            return;
        }

        return {
            label: this.translate.instant(ShellTranslationKey.FormBucketDialogAddFormTitle),
            options
        };
    }

    private get entries(): FilterEntry[] {
        try {
            return inject(FilterEntries) as FilterEntry[];
        } catch (e) {
            return [];
        }
    }

    private get serializer(): FilterSerializer<FilterValue, FilterEntry> | undefined {
        try {
            return inject(FilterSerializer) as FilterSerializer<FilterValue, FilterEntry>;
        } catch (e) {
            return;
        }
    }

    private get queryBuilder(): FilterQueryBuilder<FilterValue, FilterEntry> | undefined {
        try {
            return inject(FilterQueryBuilder) as FilterQueryBuilder<FilterValue, FilterEntry>;
        } catch (e) {
            return;
        }
    }

}

