import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterEntry, FilterValue, TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, CommonTranslationKey, DescriptionListItem, ModalService, SharedTermsTranslationKey, ToastService, UfControl, UfControlGroup,
} from '@unifii/library/common';
import {
    Claim, Dictionary, FieldWidth, Manager, Provisioning, Role, TenantSettings, User, UserAuthProvider, UserInfo, UserInvite, UserStatus
} from '@unifii/sdk';
import { FormPermissionController, LockedConfig, UserDescriptionService, UserDescriptionServiceProvider, UserFieldLabelService, UserInfoKey, UserUpdateFormController, UserUpdateMeFormController, UserValidatorService } from '@unifii/user-provisioning';

import { DiscoverContentType, UserContent } from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { PinService } from 'discover/pin/pin-types';
import { UserFormPermissionController } from 'discover/user-management/user-form-permission-controller';

import { Config } from 'config';


enum InactiveReasonOptionIds {
    HasLeft = 'hasLeft',
    NoLongerRequireAccess = 'noLongerRequireAccess',
    TemporarilySuspended = 'temporarlySuspended', // TODO spelling error? Is it like that on Back end??
    Other = 'other'
}
@Component({
    selector: 'ud-user-details',
    templateUrl: './user-details.html'
})
export class UserDetailsComponent implements EditedData, OnDestroy, OnInit, UserContent {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly commonTK = CommonTranslationKey;
    readonly shellTK = ShellTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    readonly userInfoControlKeys = UserInfoKey;
    readonly inactiveReasonOptionIds = InactiveReasonOptionIds;
    readonly userStatus = UserStatus;
    readonly fieldWidth = FieldWidth;

    // UserContent
    userInfo: UserInfo;
    userAuthProviders: UserAuthProvider[];
    status: UserStatus | null;

    // Permissions
    canUpdate: boolean;
    canInvite: boolean;
    canListClaims: boolean;
    canDelete: boolean;

    // Controls
    form: UfControlGroup;
    labelDictionary: Dictionary<string>;

    // Input Options
    roles: Role[];
    claimResults: string[];
    claimValueResults: string[];
    authProviderInfo: Dictionary<{ claims: string[]; roles: string[]; units: string[] }> = {};

    // Page status & info
    isMyProfile: boolean;
    breadcrumbs: Breadcrumb[] = [];
    edited: boolean;
    managerInfo: DescriptionListItem[];
    lockedProperties: LockedConfig = {
        claimTypes: [],
        roles: [],
        units: []
    };
    pinTimeout: number;
    emailRequired: boolean;
    disabledLookup: Dictionary<boolean>;
    descriptionFilter: string[];
    claimDescriptionFilter: (UserInfoKey | string)[];
    loading = true; // this is important for refreshing binding of [formGroup] when we create new instance of form after save

    // Subscriptions
    subscriptions: Subscription[] = [];

    private descriptionOrder = [UserInfoKey.Username, 'status', UserInfoKey.FirstName, UserInfoKey.LastName, UserInfoKey.Email, UserInfoKey.Phone, UserInfoKey.Roles, UserInfoKey.IsExternal, UserInfoKey.Units];
    private formController: UserUpdateMeFormController | UserUpdateFormController;

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private provisioning: Provisioning,
        private translate: TranslateService,
        private modalService: ModalService,
        private toast: ToastService,
        private userClient: User,
        private userFieldLabelService: UserFieldLabelService,
        private updateFormController: UserUpdateFormController,
        private updateMeFormController: UserUpdateMeFormController,
        @Inject(Config) private config: Config,
        @Inject(PinService) public pinService: PinService,
        @Inject(UserDescriptionServiceProvider) private userDescriptionService: UserDescriptionService,
        @Inject(FormPermissionController) private permissionCtrl: UserFormPermissionController,
        @Optional() private detailPage: TableDetailComponent,
        @Optional() @Inject(TablePageConfig) private tableConfig: TablePageConfig,
        @Optional() @Inject(TableContainerManager) private tableManager: TableContainerManager<UserInfo, FilterValue, FilterEntry>
    ) {
        this.isMyProfile = this.route.snapshot.data.contentType === DiscoverContentType.UserProfile;
        this.formController = this.isMyProfile ? this.updateMeFormController : this.updateFormController;

        if (this.config.unifii.tenantSettings?.features?.companies === true) {
            this.descriptionOrder.push(UserInfoKey.Company);
        }

        this.emailRequired = (this.config.unifii.tenantSettings as TenantSettings).isUserEmailRequired;
        this.labelDictionary = this.userFieldLabelService.labelDictionary;
    }

    async ngOnInit() {
        await this.init();
    }

    get user(): UserInfo | undefined {
        if (!this.form) {
            return;
        }

        return this.updateFormController.toDataModel(this.form);
    }

    get fullName() {
        if (!this.user) {
            return '';
        }

        if (this.user.firstName && this.user.lastName) {
            return `${this.user.firstName} ${this.user.lastName}`;
        }

        return this.user.username;
    }

    get usernameControl(): UfControl {
        return this.form.get(UserInfoKey.Username) as UfControl;
    }

    get unitsControl(): UfControl {
        return this.form.get(UserInfoKey.Units) as UfControl;
    }

    get unitPathsControl(): UfControl {
        return this.form.get(UserInfoKey.UnitPaths) as UfControl;
    }

    get rolesControl(): UfControl {
        return this.form.get(UserInfoKey.Roles) as UfControl;
    }

    get claimsControl(): UfControlGroup {
        return this.form.get(UserInfoKey.Claims) as UfControlGroup;
    }

    get emailControl(): UfControl {
        return this.form.get(UserInfoKey.Email) as UfControl;
    }

    get companyControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Company) as UfControl | undefined;
    }

    get oldPasswordControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.OldPassword) as UfControl | undefined;
    }

    get passwordControl(): UfControl {
        return this.form.get(UserInfoKey.Password) as UfControl;
    }

    get changePasswordOnNextLoginControl(): UfControl {
        return this.form.get(UserInfoKey.ChangePasswordOnNextLogin) as UfControl;
    }

    get lastActivationReasonControl(): UfControl {
        return this.form.get(UserInfoKey.LastActivationReason) as UfControl;
    }

    get isActiveControl(): UfControl {
        return this.form.get(UserInfoKey.IsActive) as UfControl;
    }

    async reInvite() {
        if (!this.user) {
            return;
        }

        const consent = await this.modalService.openConfirm({
            title: this.translate.instant(DiscoverTranslationKey.UserModalResendInviteTitle),
            message: this.translate.instant(DiscoverTranslationKey.UserModalResendInviteMessage, { email: this.user.email })
        });
        if (!consent) {
            return;
        }

        try {
            await this.provisioning.bulkInviteUsers([{ email: this.user.email as string, username: this.user.username as string, company: this.user.company } as UserInvite]);
            this.toast.success(this.translate.instant(DiscoverTranslationKey.UserFeedbackResendInviteSuccess));
        } catch (_e) {
            this.toast.error(this.translate.instant(DiscoverTranslationKey.UserFeedbackResendInviteFail));
        }
    }

    async delete() {
        if (!this.user) {
            return;
        }

        const consent = await this.modalService.openConfirm({
            title: this.translate.instant(ShellTranslationKey.DeleteModalTitle),
            message: this.translate.instant(ShellTranslationKey.DeleteModalMessage, { argument: this.fullName })
        });
        if (!consent) {
            return;
        }

        try {
            await this.provisioning.deleteUser(this.user.id as string);
            this.toast.success(this.translate.instant(ShellTranslationKey.DeleteModalSuccess));
            if (this.tableManager != null) {
                this.tableManager.reload?.next();
            }
            this.back();
        } catch (_e) {
            this.toast.error(this.translate.instant(ShellTranslationKey.DeleteModalFail));
        }
    }

    async save() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        if (this.status === UserStatus.Pending && this.passwordControl?.enabled && this.usernameControl.value.indexOf('invitation_') === 0) {
            // Ask confirmation to activate the user with the generated username
            const confirmed = await this.modalService.openConfirm({
                title: this.translate.instant(DiscoverTranslationKey.UserDetailsModalCompleteRegistrationTitle),
                message: this.translate.instant(DiscoverTranslationKey.UserDetailsModalCompleteRegistrationMessage, { username: this.userInfo.username })
            });

            if (!confirmed) {
                return;
            }
        }

        const user = this.formController.toDataModel(this.form);

        try {
            if (this.pinTimeout != null) {
                this.pinService.changeTimeout(this.pinTimeout);
            }

            const updatedUser = await this.updateUser(user, this.isMyProfile);

            if (!updatedUser) {
                throw new Error();
            }

            this.userInfo = updatedUser;
            this.edited = false;
            this.toast.success(this.translate.instant(SharedTermsTranslationKey.ActionSaveFeedbackSuccess));

            if (this.tableManager != null) {
                this.tableManager.updateItem?.next(updatedUser);
            }

            if (this.detailPage != null) {
                this.detailPage.updateDetails(updatedUser);
            }

            if (this.breadcrumbs.length) {
                this.back();
            } else {
                for (const subscription of this.subscriptions) {
                    subscription.unsubscribe();
                }
                await this.init();
            }
        } catch (e) {
            const saveError = this.errorService.createSaveError(user.username, e);
            const mergedError = this.errorService.mergeError(e, saveError.message);
            this.toast.error(mergedError.message);
        }
    }

    back() {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    private initPermissions() {
        this.canUpdate = this.permissionCtrl.canUpdateUser(this.userInfo);
        this.canInvite = this.permissionCtrl.canInvite();
        this.canListClaims = this.permissionCtrl.canListClaims();
        this.canDelete = this.permissionCtrl.canDelete(this.userInfo);
    }

    private initProviderAndManagerInfo() {
        if (this.userInfo.manager != null) {
            this.managerInfo = this.getManagerInfo(this.userInfo.manager);
        }

        if (this.userInfo.isExternal === true) {
            for (const provider of this.userAuthProviders) {
                this.lockedProperties.roles = this.lockedProperties.roles.concat(provider.lockedRoles);
                this.lockedProperties.claimTypes = this.lockedProperties.claimTypes.concat(provider.lockedClaims);
                this.lockedProperties.units = this.lockedProperties.units.concat(provider.lockedUnits);
            }
            this.setAuthProviderInfo(this.userInfo);
        }
    }

    private async initForm() {
        this.form = await this.formController.buildRoot(this.lockedProperties, this.userInfo);

        if (this.status === UserStatus.Pending) {
            this.form.removeControl(UserInfoKey.LastActivationReason);
        }

        this.subscriptions.push(this.form.statusChanges.pipe(filter(() => !this.form.pristine)).subscribe(() => {
            this.edited = this.form.dirty;
            this.breadcrumbs = this.getBreadcrumbs();
        }));
    }

    private initPageInfo() {
        this.breadcrumbs = this.getBreadcrumbs();

        this.disabledLookup = Object.keys(this.form.controls).reduce((dictionary, key) => {
            dictionary[key] = this.form.get(key)?.disabled === true;
            return dictionary;
        }, {} as Dictionary<boolean>);

        this.descriptionFilter = this.userDescriptionService.getDescriptionFilter(this.form);
        this.claimDescriptionFilter = this.userDescriptionService.getClaimDescriptionFilter(this.claimsControl, this.lockedProperties.claimTypes);
    }

    private async updateUser(userInfo: UserInfo, isMyProfile: boolean): Promise<UserInfo> {
        /**
         * A password can never be updated using PUT on /me therefore updating
         * properties via me and updating password details need to updated separately
         */
        const passwordDetails = { password: userInfo.password, oldPassword: userInfo.oldPassword };
        if (isMyProfile) {
            if (this.canUpdate) {
                const updatedUser = await this.userClient.update(userInfo);
                if (passwordDetails == null) {
                    return updatedUser;
                }
            }
            if (passwordDetails != null) {
                return await this.userClient.updatePassword(passwordDetails);
            }
        }

        return await this.provisioning.updateUser(userInfo);
    }

    private setAuthProviderInfo(user: UserInfo) {
        for (const provider of this.userAuthProviders) {
            this.authProviderInfo[provider.tenant] = {
                claims: (provider.lockedClaims || []).map(claim => this.claimMapper(claim, user)) as string[],
                roles: (provider.lockedRoles || []).filter(role => (user.roles || []).includes(role)),
                units: (user.unitPaths || []).filter(paths => paths.find(p => provider.lockedUnits.includes(p.id))).map(paths => paths.map(p => p.label).join(' / '))
            };
        }
    }

    private claimMapper(source: string, user: UserInfo): string | undefined {
        const claim = (user.claims || []).find(c => c.type === source);
        return `${source}: ${claim?.value || ''}`;
    }

    private getManagerInfo(manager: Manager): DescriptionListItem[] {
        return [
            { term: this.translate.instant(CommonTranslationKey.UsernameLabel), description: { description: manager.username, routerLink: ['../', `${manager.id}`] } },
            { term: this.translate.instant(CommonTranslationKey.FirstNameLabel), description: { description: manager.firstName } },
            { term: this.translate.instant(CommonTranslationKey.LastNameLabel), description: { description: manager.lastName } },
            { term: this.translate.instant(CommonTranslationKey.EmailLabel), description: { description: manager.email, href: `mailto:${manager.email}` } },
            { term: this.translate.instant(CommonTranslationKey.PhoneLabel), description: { description: manager.phone, href: `tel:${manager.phone}` } },
        ].filter(d => !!d.description && !!d.description.description);
    }

    /**
     * TODO: a variation of this duplicated in other components
     * create smart way of delivering breadcrumbs to contentNode
     */
    private getBreadcrumbs(): Breadcrumb[] {
        const breadcrumbs: Breadcrumb[] = [];

        if (this.tableConfig != null) {
            const name = this.tableConfig?.table?.title;
            const urlSegments = ['..'];
            breadcrumbs.push({ name, urlSegments });
        }

        if (this.detailPage != null) {
            const name = this.detailPage.title;
            const urlSegments = ['..'];
            if (breadcrumbs.length) {
                breadcrumbs[0].urlSegments = ['../../'];
            }
            breadcrumbs.push({ name, urlSegments });
        }

        if (breadcrumbs.length) {
            breadcrumbs.push({ name: this.fullName + (this.edited ? ' *' : '') });
        }
        return breadcrumbs;
    }

    private async init() {
        this.loading = true;
        this.cd.detectChanges();

        this.initPermissions();
        this.initProviderAndManagerInfo();
        await this.initForm();
        this.initPageInfo();

        this.loading = false;
    }
}
