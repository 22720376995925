import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WindowWrapper } from '@unifii/library/common';

import { UserAccessManager } from 'shell/services/user-access-manager';

import { DeviceService } from 'capacitor/device.service';

import { Authentication } from '../shell/services/authentication';

import { DiscoverTranslationKey } from './discover.tk';
import { ExternalService } from './external.service';


/**
 * Responsible for decoding route from route params
 * and redirecting to intended route or redirecting to
 * user access route if needed
 */
@Injectable()
export class ExternalRouteGuard implements CanActivate {

    constructor(
        private router: Router,
        private accessManager: UserAccessManager,
        private externalService: ExternalService,
        private translate: TranslateService,
        private device: DeviceService,
        @Inject(Authentication) private auth: Authentication,
        @Inject(WindowWrapper) private window: Window,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const { tenant, projectId } = route.params;

        try {

            if (!this.auth.isAuthenticated) {

                this.accessManager.deny({
                    grantedRedirectURL: state.url
                });

                return false;
            }

            if (!(await this.externalService.validateTenant(tenant))) {
                this.accessManager.deny({
                    message: this.translate.instant(DiscoverTranslationKey.LoginUseCompanyMessage, { company: tenant }),
                    grantedRedirectURL: state.url,
                    deniedRedirectURL: ['/login', { tenant, projectId }]
                });

                return false;
            }

            if (!(await this.externalService.validateProject(projectId))) {
                this.externalService.selectProject(projectId);

                if (this.device.isIOS || this.device.isAndroid) {
                    this.window.location.hash = state.url;
                    location.reload();
                    // stops the guard continuing the route while reloading the page.
                    throw new Error('Project Changed');
                }
            }

            this.router.navigateByUrl(this.mapUrl(state.url));

        } catch (err) {
            this.router.navigate(['/']);
            return false;
        }

        return false;
    }

    mapUrl(url: string) {
        let redirectPath = url.split('/').filter(Boolean).splice(3).join('/');

        const parts = redirectPath.split('?');

        if (parts.length === 2) {
            const path = parts.shift() as string;
            const params = parts.shift() as string;
            redirectPath = `${path};${params.split('&').join(';')}`;
        }

        return redirectPath ?? url;
    }
}
