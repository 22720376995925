import { Inject, Injectable } from '@angular/core';

import { InitStep } from 'shell/init-step';
import { Authentication } from 'shell/services/authentication';

import { DiscoverContext } from 'discover/discover-context';


/**
 * Centralised place for running the logout process
 */
@Injectable()
export class LogoutInitStep implements InitStep {

    constructor(
        @Inject(Authentication) private auth: Authentication,
        private context: DiscoverContext
    ) { }

    run() {
        this.auth.logouts.subscribe(async () => {
            this.context.clear();
            this.auth.clear();
        });
    }
}
