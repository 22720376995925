import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { SavedUsersService } from 'shell/services/saved-users.service';

import { Config } from 'config';


/**
 * Ensure tenant enables saved User (or in flags)
 */

@Injectable()
export class SavedUsersGuard implements CanActivate {

    constructor(
        private savedUsersService: SavedUsersService,
        private router: Router,
        private config: Config
    ) { }

    canActivate(): boolean {

        if ((this.savedUsersService.enabled && this.savedUsersService.users.length) || this.config.unifii.tenantSettings?.authProviders?.length) {
            return true;
        }

        this.router.navigate(['/login/form']);
        return false;

    }

}