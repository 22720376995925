import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CommonTranslationKey, ContextProvider, FeatureFlagService, ModalService, SharedTermsTranslationKey, ToastService, UfControl, UfControlGroup
} from '@unifii/library/common';
import {
    Claim, Dictionary, FieldWidth, PermissionAction, Provisioning, UserCreateResult
} from '@unifii/sdk';
import { FormPermissionController, UserFieldLabelService, UserInfoKey, UserInputControlKey, UsersInviteUploadFormController, UsersCreateUploadFormController, UserProvisioningTranslationKey } from '@unifii/user-provisioning';

import { Authentication } from 'shell/services/authentication';
import { EditedData } from 'shell/services/unsaved-data-guard';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { UserFormPermissionConfig, UserFormPermissionController, UserFormResourceType } from 'discover/user-management/user-form-permission-controller';
import { UserInputType } from 'discover/user-management/user-types';
import { UserUploadModalComponent } from 'discover/user-management/user-upload-modal.component';

import { Config } from 'config';
import { ShellFeatureFlagService } from 'shell/services/shell-feature-flag.service';


export enum UserCsvUploadType {
    BASIC = 'basic',
    ADVANCED = 'advanced'
}

@Component({
    templateUrl: './user-upload-csv.html',
    providers: [
        { provide: FeatureFlagService, useClass: ShellFeatureFlagService }
    ]
})
export class UserUploadCsvComponent implements EditedData, OnInit, OnDestroy {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly commonTK = CommonTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;
    readonly userProvisioningTK = UserProvisioningTranslationKey;

    readonly userInfoControlKeys = UserInfoKey;
    readonly userInputControlKey = UserInputControlKey;
    readonly userCsvUploadType = UserCsvUploadType;
    readonly fieldWidth = FieldWidth;

    readonly formController: UsersInviteUploadFormController | UsersCreateUploadFormController;

    error: any;
    edited = false;
    uploading = false;
    uploadResult: any;

    inputType: UserInputType;
    uploadType: UserCsvUploadType = UserCsvUploadType.ADVANCED;

    abortController: AbortController = new AbortController();

    form: UfControlGroup;
    labelDictionary: Dictionary<string>;

    get saveButtonLabel(): string {
        return this.inputType === UserInputType.Invite ? this.translate.instant(SharedTermsTranslationKey.ActionInvite) : this.translate.instant(SharedTermsTranslationKey.ActionCreate);
    }

    get csvTemplatePath(): string {
        if (this.inputType === UserInputType.Invite) {
            return this.uploadType === UserCsvUploadType.BASIC ? 'assets/csv/invite basic.csv' : 'assets/csv/invite advanced.csv';
        } else {
            return this.uploadType === UserCsvUploadType.BASIC ? 'assets/csv/create basic.csv' : 'assets/csv/create advanced.csv';
        }
    }

    constructor(
        public context: DiscoverContext,
        private router: Router,
        private route: ActivatedRoute,
        private provisioning: Provisioning,
        private translate: TranslateService,
        private toastService: ToastService,
        private modalService: ModalService,
        private userFieldLabelService: UserFieldLabelService,
        private inviteFormController: UsersInviteUploadFormController,
        private createFormController: UsersCreateUploadFormController,
    ) {
        this.inputType = this.route.snapshot.data.inputType;
        this.uploadType = this.route.snapshot.data.uploadType;
        this.labelDictionary = this.userFieldLabelService.labelDictionary;

        const userFormPermissionConfig = inject(UserFormPermissionConfig);
        userFormPermissionConfig.action = this.inputType === UserInputType.Invite ? PermissionAction.Invite : PermissionAction.Add;
        userFormPermissionConfig.resourceType = UserFormResourceType.Users;

        if (this.inputType === UserInputType.Invite) {
            this.formController = this.inviteFormController;
        } else {
            this.formController = this.createFormController;
        }
    }

    get fileControl(): UfControl {
        return this.form.get(UserInputControlKey.File) as UfControl;
    }

    get claimsControl(): UfControlGroup | undefined {
        return this.form.get(UserInfoKey.Claims) as UfControlGroup | undefined;
    }

    get unitsControl(): UfControl {
        return this.form.get(UserInfoKey.Units) as UfControl;
    }

    get unitPathsControl(): UfControl {
        return this.form.get(UserInfoKey.UnitPaths) as UfControl;
    }
    get rolesControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Roles) as UfControl | undefined;
    }

    get companyControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Company) as UfControl | undefined;
    }

    get changePasswordOnNextLoginControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.ChangePasswordOnNextLogin) as UfControl | undefined;
    }

    get canChangeUsernameControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.CanChangeUsername) as UfControl | undefined;
    }

    async ngOnInit() {
        this.form = await this.formController.buildRoot(this.uploadType);

        this.form.valueChanges.subscribe(() => {
            this.edited = true;
        });

        this.fileControl.valueChanges.subscribe((v) => {
            if (v.length > 0) {
                this.fileControl.disable({ emitEvent: false });
            } else {
                this.fileControl.enable({ emitEvent: false });
            }
        });
    }

    async upload() {
        if (this.uploading) {
            return;
        }

        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        // Save
        let responseData: UserCreateResult = {
            successCount: 0,
            errors: []
        };

        const data = await this.formController.toDataModel(this.form);
        let uploadModalTimeout;

        try {
            uploadModalTimeout = setTimeout(() => this.modalService.openFit(UserUploadModalComponent, undefined, { guard: true }).then(result => {
                if (result === false) {
                    this.abortController.abort();
                }
            }), 1000); // Only show if it's taking longer than 1 second

            this.uploading = true;

            if (this.inputType === UserInputType.Create) {
                responseData = await this.provisioning.bulkAddUsersCsv(data.file, data.additionalData, this.abortController.signal);
            } else if (this.inputType === UserInputType.Invite) {
                responseData = await this.provisioning.bulkInviteUsersCsv(data.file, data.additionalData, this.abortController.signal);
            }
            this.uploading = false;

            clearTimeout(uploadModalTimeout);
            this.modalService.closeLatest();

            if (!responseData.errors || !responseData.errors.length) {
                this.edited = false;
                if (this.inputType === UserInputType.Create) {
                    this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersCreateSuccessToast, { count: responseData.successCount }));
                } else {
                    this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersInviteSuccessToast, { count: responseData.successCount }));
                }
                this.back();
            } else {
                this.uploadResult = responseData;
                if (this.inputType === UserInputType.Create) {
                    this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersCreateFailedToast, { count: responseData.successCount, total: responseData.errors.length + responseData.successCount }));
                } else {
                    this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersInviteFailedToast, { count: responseData.successCount, total: responseData.errors.length + responseData.successCount }));
                }
            }
        } catch (e) {
            if (this.abortController.signal.aborted) {
                this.abortController = new AbortController();
                this.toastService.error(this.translate.instant(CommonTranslationKey.UploadCancelledMessage));
            } else {
                this.toastService.error(this.translate.instant(SharedTermsTranslationKey.ErrorGenericMessage));
            }

            this.error = e;

            if (uploadModalTimeout) {
                clearTimeout(uploadModalTimeout);
            }
            this.modalService.closeLatest();
            this.uploading = false;
        }
    }

    back(canLeave?: boolean) {
        this.edited = canLeave ? false : this.edited;
        this.router.navigate(['../../'], { relativeTo: this.route });
    }

    claimsChange(claims: Claim[]) {
        if (this.claimsControl) {
            this.claimsControl.setValue(claims);
            this.edited = true;
        }
    }

    removeFile() {
        this.fileControl.setValue([]);
    }

    ngOnDestroy() {
        this.abortController.abort();
    }
}
