import { DataSourceLoader } from '@unifii/library/smart-forms';
import { Compound, DataSeed, DataSource, Provisioning, Query } from '@unifii/sdk';


export class UserOptionsLoader implements DataSourceLoader {

    dataSource: DataSource;

    private seeds: DataSeed[];

    constructor(
        private identifier: string,
        private name: string,
        private provisioning: Provisioning,
    ) { }

    async getOptions(): Promise<DataSeed[]> {
        return [...this.seeds];
    }


    async search(query?: string): Promise<DataSeed[]> {
        await this.load(query);
        return this.seeds;
    }


    async get(id: string): Promise<DataSeed | null> {
        const collectionItem = await this.provisioning.getUser(id);
        return this.mapToSeed(collectionItem);
    }

    mapToSeed(result?: Compound): DataSeed | null {

        if (!result) {
            return null;
        }

        return {
            _id: result[this.identifier],
            _display: result[this.name]
        };
    }

    private async load(q?: string) {

        this.seeds = [];

        const query = new Query();
        if (q && q.length) {
            query.q(q);
        }

        const results = await this.provisioning.queryUsers(query);

        this.seeds = results.map(result => this.mapToSeed(result)).filter(seed => seed != null) as DataSeed[];
    }
}
