
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Component, ElementRef, HostListener, Inject, Input, ViewChild } from '@angular/core';
import { ChartOptions, ChartData, ScaleOptionsByType, ChartConfiguration, LegendOptions, ChartTypeRegistry } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils'; // using this private util helps avoid casting to any and retains typechecking

import { WindowWrapper } from '@unifii/library/common';

import { ChartComponent } from 'shell/common/chart/chart.component';
import { ShellTranslationKey } from 'shell/shell.tk';

import { ReportAxisConfig, ReportConfig, ReportData, ReportLegendConfig, ReportService } from 'discover/reports/report-service';
import { ReportColour, ReportColourCommon } from 'discover/reports/report-constants';

@Component({
    selector: 'us-report',
    templateUrl: './report.html',
    styleUrls: ['./report.less']
})
export class ReportComponent {

    readonly shellTK = ShellTranslationKey;

    loading = true;
    error: any;

    chartConfig: ChartConfiguration;
    reportData?: ReportData;

    onWindowResize = new Subject<void>();

    private _chart: ChartComponent;
    private _reportConfig: ReportConfig;

    constructor(
        private service: ReportService,
        private element: ElementRef,
        @Inject(WindowWrapper) private window: Window
    ) {
        this.onWindowResize.pipe(debounceTime(300)).subscribe(() => {
            this.changeChartRatio();
        });
    }

    @HostListener('window:resize')
    onResize() {
        this.onWindowResize.next();
    }

    @ViewChild(ChartComponent) set chart(chart: ChartComponent) {
        const init = !this._chart;
        this._chart = chart;
        if (init && this._chart) {
            this.updateReportData();
        }
        this.changeChartRatio();
    }

    get chart(): ChartComponent {
        return this._chart;
    }

    @Input() set reportConfig(v: ReportConfig) {
        this._reportConfig = v;
        this.updateReportConfig();
    }

    get reportConfig(): ReportConfig {
        return this._reportConfig;
    }

    async loadData(filters: any) {
        try {
            this.reportData = await this.service.getData(this.reportConfig.identifier, filters);
            this.updateReportData();
        } catch (e) {
            console.error(e);
        }
    }

    downloadChartAsImage() {
        this.chart.downloadChartAsImage(this.reportConfig.title + '.png');
    }

    private updateReportConfig() {
        if (this.reportConfig.chartType !== 'table') {
            const chartOptions: ChartOptions = {
                scales: {
                    x: this.getScaleOptions(this.reportConfig.xAxis),
                    y: this.getScaleOptions(this.reportConfig.yAxis)
                },
                plugins: {
                    legend: this.getLegend(this.reportConfig.legend)
                }
            };

            this.chartConfig = {
                type: this.reportConfig.chartType,
                options: chartOptions,
                data: {
                    labels: [],
                    datasets: [],
                }
            };
        }
    }

    private getScaleOptions(axisConfig?: ReportAxisConfig): _DeepPartialObject<ScaleOptionsByType> | undefined {
        if (axisConfig == null) {
            return;
        }

        const axisOptions: _DeepPartialObject<ScaleOptionsByType> = {
            title: {
                display: !!axisConfig.label,
                text: axisConfig.label ?? ''
            }
        };

        if (axisConfig.stacked != null) {
            axisOptions.stacked = axisConfig.stacked;
        }

        if (axisConfig.ticks != null) {
            axisOptions.ticks = axisConfig.ticks;
            axisOptions.min = axisConfig.ticks.min;
            axisOptions.max = axisConfig.ticks.max;
        }

        return axisOptions;
    }

    private getLegend<Type extends keyof ChartTypeRegistry>(legend?: ReportLegendConfig): Partial<LegendOptions<Type>> {
        if (legend == null) {
            return { position: 'center', align: 'start' };
        }

        return Object.assign(legend, { align: 'start' });
    }

    private updateReportData() {
        if (!this.reportData) {
            return;
        }

        if (this.chart) {
            const chartData: ChartData = {
                labels: this.reportData?.labels as string[],
                datasets: this.reportData.datasets.map((dataset, index) => ({
                    label: dataset.label,
                    data: dataset.data,
                    backgroundColor: this.getBackgroundColour(index, dataset.color),
                    borderColor: this.getBorderColour(index, dataset.color),
                    borderWidth: this.getBorderWidth(),
                    hoverBorderColor: this.getBorderColour(index, dataset.color),
                    hoverBorderWidth: this.getHoverBorderWidth(),
                    tension: dataset.tension || 0,
                }))
            };

            this.chart.clearData();
            this.chart.addData(chartData);
        }
    }

    private getBackgroundColour(index: number, colour?: string | string[]): string | string[] {
        return this.getColour(index, colour);
    }

    private getBorderColour(index: number, colour?: string | string[]): string | string[] | undefined {
        switch (this.reportConfig.chartType) {
            case 'bar':
            case 'pie':
            case 'doughnut':
                return '#ffffff';
            case 'polarArea':
            case 'scatter':
            case 'bubble':
                return undefined;
            default:
                return this.getColour(index, colour);
        }
    }

    private getBorderWidth(): number | { top: number; bottom: number; left: number; right: number } | undefined {
        switch (this.reportConfig.chartType) {
            case 'bar':
                return {
                    top: 1,
                    bottom: 0,
                    left: 0,
                    right: 0
                };
            case 'polarArea':
            case 'scatter':
            case 'bubble':
                return undefined;
            default:
                return 1;
        }
    }

    private getHoverBorderWidth(): number {
        return !!this.getBorderWidth() ? 1 : 0;
    }

    // look up in 2 dictionaries or return itself, if no colour supplied return random
    private getColour(index: number, colour?: string | string[]): string | any[] {
        if (!colour) {
            return ReportColourCommon[index % Object.keys(ReportColourCommon).length];
        }

        if (Array.isArray(colour)) {
            return colour.map((c, i) => this.getColour(i, c));
        }

        return ReportColour[colour] || ReportColourCommon[colour] || colour;
    }

    private changeChartRatio() {
        const windowHeight = this.window.innerHeight;
        const componentWidth = this.element.nativeElement.clientWidth;
        let ratio = 1;
        if (componentWidth * 1.5 > windowHeight) {
            ratio = 2;
        }
        if (componentWidth / 1.5 > windowHeight) {
            ratio = 3;
        }
        if (this.chart) {
            this.chart.changeRatio(ratio);
        }
    }
}