import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Client, ErrorType, ProjectInfo } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { Features } from 'shell/features';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';


/**
 * This class is responible for:
 * - Adding allowed projects to localstorage
 * - Redirecting if only one available project
 * - Redirecting back to login if errors
 * Guard is useful for:
 * - Preventing any unwanted flashes in UI when retriving data or redirecting
 */
@Injectable()
export class ProjectSelectorGuard implements CanActivate {

    constructor(
        private client: Client,
        private context: DiscoverContext,
        private errorService: ErrorService,
        @Inject(Authentication) private auth: Authentication,
        private translate: TranslateService,
        private features: Features,
        private offlineManager: OfflineManager,
        private accessManager: UserAccessManager
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        try {
            // A computed set/get
            this.auth.allowedProjects = await this.client.getProjects();

            if (!this.auth.allowedProjects.length || this.auth.allowedProjects.length > 1) {
                return true;
            }

            await this.updateProjectAndRedirect();

        } catch (error) {

            // Don't show message for expired token
            if (error.type !== ErrorType.Unauthorized) {
                error = this.errorService.mergeError(error, this.translate.instant(DiscoverTranslationKey.SelectProjectErrorLoadingProjects));
                this.accessManager.deny({ error: error.message });
            }
        }
        return false;
    }

    private async updateProjectAndRedirect(): Promise<void> {

        try {

            this.context.project = await this.getProject(this.auth.allowedProjects[0].id);
            this.accessManager.grant();

        } catch (error) {

            // Don't show message for expired token
            if (error.type !== ErrorType.Unauthorized) {
                error = this.errorService.mergeError(error, this.translate.instant(DiscoverTranslationKey.SelectProjectErrorLoadingProject));
                this.accessManager.deny({ error: error.message });
            }
        }
    }

    private async getProject(id: string): Promise<ProjectInfo> {

        try {
            const project = await this.client.getProject(id);

            if (this.features.offline) {
                // Close previous project DB reference
                this.offlineManager.projectChanged();
            }
            return project;
        } catch (error) {

            throw this.errorService.createLoadError(this.translate.instant(DiscoverTranslationKey.SelectProjectErrorLoadingProject), error);
        }
    }
}