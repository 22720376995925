import { Component, inject, Inject, Input, OnInit } from '@angular/core';
import { ContextProvider, SortStatus, TableConfig, TableDataSource } from '@unifii/library/common';
import { ColumnDescriptor, Definition, FormData, Query, PermissionAction, TableSourceType, Provisioning, CompanyClient, Client } from '@unifii/sdk';

import { AppError } from 'shell/errors/errors';
import { TableNodeInfo } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';

import { Config } from 'config';
import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { BetterFormService } from 'shell/form/better-form.service';
import { ASTExpressionParser } from 'shell/table/ast-expression-parser';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { TableData } from 'shell/table/models';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { ErrorService } from 'shell/errors/error.service';


@Component({
    selector: 'us-dashboard-table',
    templateUrl: './dashboard-table.html',
    styleUrls: ['./dashboard-table.less'],
    providers: [BetterFormService]
})
export class DashboardTableComponent implements OnInit {

    @Input() tableInfo: TableNodeInfo;

    readonly shellTK = ShellTranslationKey;

    error: AppError | undefined;
    formsDefinition: Map<string, Definition> = new Map<string, Definition>();
    tableConfig: TableConfig<FormData> | null = null;
    datasource:  TableDataSource<TableData> | null = null;
    customColumns: ColumnDescriptor[] = [];

    private query?: Query;

    constructor(
        private provisioning: Provisioning,
        private astExpressionParser: ASTExpressionParser,
        @Inject(ContentDataResolver) private dataResolver: ContentDataResolver,
        private tableColumnFactory: TableColumnFactory<FormData>,
        @Inject(Authentication) private auth: Authentication,
        private formService: BetterFormService,
        @Inject(Config) private config: Config,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        private errorService: ErrorService
    ) { }

    async ngOnInit() {

        try {

            this.formService.bucket = this.tableInfo.table.source as string;
            const { tablePageConfig } = await this.dataResolver.getTableData(this.tableInfo.table.identifier);
            const { propertyDescriptors } = tablePageConfig;
            const parsedFilter = this.astExpressionParser.parse(this.tableInfo.table.filter);
            this.query = parsedFilter != null ? new Query().fromAst(parsedFilter) : undefined;

            this.tableConfig = {
                columns: this.tableColumnFactory.create(this.tableInfo.table.columns ?? [], propertyDescriptors, this.tableInfo.table.sourceType),
                pageSize: this.tableInfo.pageSize ?? 5,
                rowLink: (item: FormData) => {
                    if (this.canRouteToItem(item)) {
                        return ['/', this.tableInfo.table.identifier, item.id];
                    }
                    return [];
                }
            };

            // Load data
            this.reload();

        } catch (e) {
            this.error = this.errorService.mergeError(e, this.errorService.unknownErrorMessage);
        }
    }

    reload() {
        this.datasource = this.getDataSource();
    }

    private getDataSource(): TableDataSource<TableData> {

        const sort = SortStatus.fromString(this.tableInfo.table?.defaultSort) || undefined;

        switch (this.tableInfo.table.sourceType) {
            case TableSourceType.Users:
                return new UsersTableDataSource(this.provisioning, this.query, undefined, sort);
            case TableSourceType.Company:
                const companyClient = new CompanyClient(inject(Client));
                return new CompanyTableDataSource(companyClient, this.query, undefined, sort);
            case TableSourceType.Bucket:
                this.formService.bucket = this.tableInfo.table.source as string;
                return new BucketTableDataSource(this.formService, this.query, undefined, sort);
        }
    }

    private canRouteToItem(item: FormData): boolean {

        switch (this.tableInfo.table.sourceType) {
            case TableSourceType.Bucket:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, this.tableInfo.table.source as string, item.id as string),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get()
                ).granted;
            case TableSourceType.Company:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getCompanyPath(item.id),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get()
                ).granted;
            case TableSourceType.Users:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getUserPath(item.id ? +item.id : undefined),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get()
                ).granted;
        }
    }
}
