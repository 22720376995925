<div *ngIf="!uploadResult && form" class="uf-container-lg">
    <div class="uf-grid pad-top row-gap-lg" [formGroup]="form">
        <div class="uf-box col-12">
            <ng-container *ngIf="uploadType === userCsvUploadType.BASIC">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ discoverTK.UsersBasicCSVTemplateTitle | translate }}</div>
                </div>
                <div class="pad-sides">
                    <p class="small">{{ discoverTK.UsersBasicCSVTemplateMessage | translate }}</p>
                    <p class="small">{{ discoverTK.UsersBasicCSVTemplateUsageMessage | translate }}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="uploadType === userCsvUploadType.ADVANCED">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ discoverTK.UsersAdvancedCSVTemplateTitle | translate }}</div>
                </div>
                <div class="pad-sides">
                    <p class="small">{{ discoverTK.UsersAdvancedCSVTemplateMessage | translate }}</p>
                    <p class="small">{{ discoverTK.UsersAddMultipleAdvancedCSVMessage | translate }} <span
                            *ngIf="companyControl?.enabled">{{
                            discoverTK.UsersAddMultipleAdvancedCSVMessageCompany | translate }}</span></p>
                </div>
            </ng-container>
            <div class="pad-right pad-bottom">
                <a class="uf-button right small" [href]="csvTemplatePath" download>
                    {{ discoverTK.UsersDownloadCSVTemplate | translate }}
                </a>
            </div>
        </div>
        <div class="uf-box col-12">
            <div class="uf-app-bar accent medium">
                <div class="title" *ngIf="uploadType === userCsvUploadType.BASIC">
                    {{ discoverTK.UsersAddMultipleBasicCSVTitle | translate }}</div>
                <div class="title" *ngIf="uploadType === userCsvUploadType.ADVANCED">
                    {{ discoverTK.UsersAddMultipleAdvancedCSVTitle | translate }}</div>
            </div>
            <div class="uf-grid condensed">
                <div class="col-12 pad-sides" *ngIf="!fileControl.value.length">
                    <p class="small">{{ discoverTK.UsersAddMultipleBasicCSVMessage | translate }}</p>
                </div>
                <div class="col-12 pad-bottom" *ngIf="fileControl.value.length">
                    <ul class="uf-list">
                        <li *ngFor="let file of fileControl.value; let i = index" class="uf-list-item">
                            <span class="grow">{{ file.name }}</span>
                            <button type="button" class="uf-action tertiary small" (click)="removeFile()"
                                [title]="sharedTermsTK.ActionRemove | translate">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="col-12 pad-sides pad-bottom">
                    <uf-file type=".csv" [formControlName]="userInputControlKey.File"></uf-file>
                    <uf-error [control]="fileControl"></uf-error>
                </div>
            </div>
        </div>

        <div class="col-12 uf-box" *ngIf="canChangeUsernameControl">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ commonTK.UsernameLabel | translate }}</div>
            </div>
            <div class="uf-grid pad">
                <uf-checkbox class="col-12" [formControlName]="userInfoControlKeys.CanChangeUsername"
                    [label]="labelDictionary[userInfoControlKeys.CanChangeUsername]"></uf-checkbox>
            </div>
        </div>

        <div class="col-12 uf-box" *ngIf="changePasswordOnNextLoginControl">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ commonTK.PasswordLabel | translate }}</div>
            </div>
            <div class="uf-grid pad">
                <uf-checkbox class="col-12" [label]="userProvisioningTK.UserCreateNewPasswordLabel | translate"
                    [formControlName]="userInfoControlKeys.ChangePasswordOnNextLogin">
                </uf-checkbox>
            </div>
        </div>

        <div class="col-12 uf-box" *ngIf="companyControl?.enabled">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ commonTK.CompanyLabel | translate }}</div>
            </div>
            <div class="pad">
                <uf-company-input class="col-12 col-md-6" [formControlName]="userInfoControlKeys.Company">
                </uf-company-input>
            </div>
        </div>
        <div class="uf-box col-12 gap-top-sm" *ngIf="unitsControl">
            <div class="uf-app-bar flat medium accent">
                <div class="title">{{ commonTK.HierarchyLabel | translate }}</div>
            </div>
            <div class="uf-grid pad-bottom">
                <uf-hierarchy-input class="col-12" [label]="labelDictionary[userInfoControlKeys.Units]"
                    [control]="unitsControl">
                </uf-hierarchy-input>
            </div>
        </div>
        <div class=" col-12 uf-box" *ngIf="rolesControl && rolesControl.enabled">
            <div class="uf-app-bar medium accent">
                <div class="title">{{ commonTK.RolesLabel | translate }}</div>
            </div>
            <div class="pad">
                <uf-role-input [formControlName]="userInfoControlKeys.Roles">
                </uf-role-input>
            </div>
        </div>
        <div class="col-12 uf-box" *ngIf="claimsControl && claimsControl.enabled">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
            </div>
            <uf-claim-editor class="col-12 pad-sm-bottom" [control]="claimsControl" [width]="fieldWidth.Half"
                (claimsChange)="claimsChange($event)">
            </uf-claim-editor>
        </div>
        <uf-blockquote *ngIf="error && error.message" class="col-12 error" icon="errorSolid" [content]="error.message">
        </uf-blockquote>
        <div class="col-12 row">
            <button type="button" class="uf-button tertiary left" (click)="back()">
                {{sharedTermsTK.ActionCancel | translate}}
            </button>
            <button type="button" class="uf-button primary" (click)="upload()" [disabled]="uploading">
                {{ saveButtonLabel }}
            </button>
        </div>
    </div>
</div>
<ud-user-upload *ngIf="uploadResult" [uploadResult]="uploadResult" [inputType]="inputType" [csvUpload]="true"
    (resolved)="back(true)">
</ud-user-upload>