import { filter } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalConfirmData, ModalService, Repository, SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';

import { TenantSettingsService } from 'shell/services/tenant-settings.service';

import { DiscoverTranslationKey } from 'discover/discover.tk';

import { DeviceService } from 'capacitor/device.service';

import { Config } from 'config';

import { RequiredUpdateModalComponent } from './required-update-modal.component';


const LastUpdateRequestKey = 'UfLastUpdateRequest';
const ForcedUpdateRequestKey = 'UfForcedUpdateRequest';

@Injectable()
export class AppUpdateService {

    constructor(
        @Inject(Config) private config: Config,
        @Inject(WindowWrapper) private window: Window,
        private repo: Repository,
        private modalService: ModalService,
        private translate: TranslateService,
        private router: Router,
        private tenantSettings: TenantSettingsService,
        private device: DeviceService
    ) { }

    init() {

        this.checkAppInfo().catch(() => { });

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.checkAppInfo().catch(() => { });
            });
    }

    private async checkAppInfo() {

        await this.tenantSettings.sync();

        const appInfo = this.config.unifii.tenantSettings?.appInfo;

        // guard no appInfo
        if (!appInfo) {
            return;
        }

        // current version is below minVersion
        if (!this.compareVersion(this.config.version, appInfo.minVersion)) {

            // if desktop, try reload page once
            if (this.device.device == null) {

                const previousForcedUpdateVersion = this.repo.loadString(ForcedUpdateRequestKey);

                if (!previousForcedUpdateVersion || !this.compareVersion(ForcedUpdateRequestKey, appInfo.minVersion)) {
                    this.repo.storeString(ForcedUpdateRequestKey, appInfo.minVersion);
                    this.window.location.reload();
                    return;
                }

            }

            // after reload, block with modal
            this.modalService.openFullScreen(
                RequiredUpdateModalComponent, appInfo, { guard: true }
            );

            return;
        }

        // current version is below latestVersion
        if (!this.compareVersion(this.config.version, appInfo.latestVersion)) {

            // if previous version is up to date with latest version dont update
            const previousPopupVersion = this.repo.loadString(LastUpdateRequestKey);

            if (previousPopupVersion && this.compareVersion(previousPopupVersion, appInfo.latestVersion)) {
                return;
            }

            this.repo.storeString(LastUpdateRequestKey, appInfo.latestVersion);

            // if desktop, just reload page
            if (this.device.device == null) {
                this.window.location.reload();
                return;
            }

            const message: ModalConfirmData = {
                title: this.translate.instant(DiscoverTranslationKey.UpdateAvailableTitle),
                message: this.translate.instant(DiscoverTranslationKey.UpdateAvailableMessage, { version: appInfo.latestVersion }),
                confirmLabel: this.translate.instant(SharedTermsTranslationKey.UpdateLabel),
                cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            };

            const update = await this.modalService.openConfirm(message);

            // route to link
            if (update) {
                window.open(appInfo.appStoreLink, '_system ');
            }
        }
    }

    // returns true is current version is same or greater than minimum, or if not able to compare.
    private compareVersion(currentVersion: string, minimumVersion: string): boolean {
        const current = this.splitVersion(currentVersion);
        const minimum = this.splitVersion(minimumVersion);

        // no version
        if (!current || !minimum) {
            return true;
        }

        if (current.major > minimum.major) {
            return true;
        }

        if (current.major < minimum.major) {
            return false;
        }

        if (current.minor > minimum.minor) {
            return true;
        }

        if (current.minor < minimum.minor) {
            return false;
        }

        if (current.build > minimum.build) {
            return true;
        }

        if (current.build < minimum.build) {
            return false;
        }

        if (!isNaN(current.revision) && !isNaN(minimum.revision)) {

            if (current.revision > minimum.revision) {
                return true;
            }

            if (current.revision < minimum.revision) {
                return false;
            }
        }

        // same number
        return true;
    }

    private splitVersion(version: string): { major: number; minor: number; build: number; revision: number } | null {

        const array = version.split('.');

        const major = parseInt(array[0]);
        const minor = parseInt(array[1]);
        const build = parseInt(array[2]);
        const revision = parseInt(array[3]);

        if (isNaN(major) || isNaN(minor) || isNaN(build)) {
            return null;
        }

        return { major, minor, build, revision };

    }

}