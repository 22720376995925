<div class="uf-container-lg" *ngIf="!uploadResult && form" [formGroup]="form">
    <div class="uf-grid pad-top row-gap-lg">
        <div class="uf-box col-12">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ discoverTK.UsersLabel | translate }}</div>
            </div>
            <div class="uf-grid pad">

                <!-- Mobile View -->
                <div class="col-12 col space-children margin--top user-card-display" *ngIf="usersControl.value.length;">
                    <div class="uf-card" *ngFor="let user of usersControl.value; let i = index">
                        <div class="pad">
                            <button type="button" class="uf-action tertiary small float-right" (click)="removeUser(i)"
                                [title]="sharedTermsTK.ActionRemove | translate">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                            <button type="button" class="uf-action tertiary small float-right" (click)="editUser(i)"
                                [title]="sharedTermsTK.ActionEdit | translate">
                                <uf-icon name="edit"></uf-icon>
                            </button>
                            <uf-description-list class="small-label">
                                <dt>{{ commonTK.UsernameLabel | translate }}</dt>
                                <dd>{{ user.username }}</dd>
                                <dt>{{ commonTK.FirstNameLabel | translate }}</dt>
                                <dd>{{ user.firstName }}</dd>
                                <dt>{{ commonTK.LastNameLabel | translate }}</dt>
                                <dd>{{ user.lastName }}</dd>
                                <dt>{{ commonTK.EmailLabel | translate }}</dt>
                                <dd>{{ user.email }}</dd>
                                <dt>{{ commonTK.PhoneLabel | translate }}</dt>
                                <dd>{{ user.phone }}</dd>
                            </uf-description-list>
                        </div>
                    </div>
                </div>
                <!-- Desktop view -->
                <div class="col-12 user-table-display" *ngIf="usersControl.value.length;">
                    <table class="uf-table break-word">
                        <thead>
                            <tr>
                                <th>{{ commonTK.UsernameLabel | translate }}</th>
                                <th>{{ commonTK.FirstNameLabel | translate }}</th>
                                <th>{{ commonTK.LastNameLabel | translate }}</th>
                                <th>{{ commonTK.EmailLabel | translate }}</th>
                                <th>{{ commonTK.PhoneLabel | translate }}</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let user of usersControl.value; let i = index">
                                <td>{{ user.username }}</td>
                                <td>{{ user.firstName }}</td>
                                <td>{{ user.lastName }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.phone }}</td>
                                <td class="pad-right">
                                    <div class="row space-children">
                                        <button type="button" class="uf-action tertiary small right"
                                            (click)="editUser(i)" [title]="sharedTermsTK.ActionEdit | translate">
                                            <uf-icon name="edit"></uf-icon>
                                        </button>
                                        <button type="button" class="uf-action tertiary small" (click)="removeUser(i)"
                                            [title]="sharedTermsTK.ActionRemove | translate">
                                            <uf-icon name="delete"></uf-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 row center-all">
                    <uf-error [control]="form.controls.users | asUfControl"></uf-error>
                    <button type="button" class="uf-button right small" (click)="addUser()">
                        {{ discoverTK.UserAddAction | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12 uf-box" *ngIf="changePasswordOnNextLoginControl">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ commonTK.PasswordLabel | translate }}</div>
            </div>
            <div class="uf-grid pad">
                <uf-checkbox class="col-12" [label]="userProvisioningTK.UserCreateNewPasswordLabel | translate"
                    [formControlName]="userInfoControlKeys.ChangePasswordOnNextLogin">
                </uf-checkbox>
            </div>
        </div>

        <div class="col-12 uf-box" *ngIf="companyControl?.enabled">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ commonTK.CompanyLabel | translate }}</div>
            </div>
            <div class="pad">
                <uf-company-input class="col-12 col-md-6" [formControlName]="userInfoControlKeys.Company">
                </uf-company-input>
            </div>
        </div>

        <div class="uf-box col-12 gap-top-sm" *ngIf="unitsControl">
            <div class="uf-app-bar flat medium accent">
                <div class="title">{{ commonTK.HierarchyLabel | translate }}</div>
            </div>
            <div class="uf-grid pad-bottom">
                <uf-hierarchy-input class=" col-12" [label]="labelDictionary[userInfoControlKeys.Units]"
                    [control]="unitsControl">
                </uf-hierarchy-input>
            </div>
        </div>

        <div class="uf-box col-12" *ngIf="rolesControl.enabled || rolesControl.value.length">
            <div class="uf-app-bar medium accent">
                <div class="title">{{ commonTK.RolesLabel | translate }}</div>
            </div>
            <div class="pad">
                <uf-role-input [formControlName]="userInfoControlKeys.Roles">
                </uf-role-input>
            </div>
        </div>

        <div class="col-12 uf-box" *ngIf="claimsControl && claimsControl.enabled">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
            </div>
            <uf-claim-editor class="col-12 pad-sm-bottom" [control]="claimsControl" [width]="fieldWidth.Half"
                (claimsChange)="claimsChange($event)">
            </uf-claim-editor>
        </div>
        <div class="col-12 row">
            <button type="button" class="uf-button tertiary left" (click)="back()">
                {{sharedTermsTK.ActionCancel | translate}}
            </button>
            <button type="button" class="uf-button primary" (click)=" save()">
                {{ saveButtonLabel }}
            </button>
        </div>
    </div>
</div>
<ud-user-upload *ngIf="uploadResult" [uploadResult]="uploadResult" [inputType]="inputType" [users]="usersControl.value"
    (resolved)="back(true)">
</ud-user-upload>