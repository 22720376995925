<uf-panel class="container">

    <!-- Emty pane required for table tool bar -->
    <div class="header-pane"></div>

    <uf-table class="content-pane accent" [config]="tableConfig" [datasource]="datasource">

        <!-- Special cell for uploading state-->
        <div *cellFor="'syc'; let info">
            <!-- Ready to Sync -->
            <div *ngIf="!isFailed(info) && !isCompleted(info) && !isUploading(info)">
                <div>{{discoverTK.OfflineFormsWaitingSyncLabel | translate}}</div>
            </div>
            <!-- Upload completed -->
            <div class="success" *ngIf="isCompleted(info)">
                <uf-icon name="tick--solid" class="small"
                    [title]="discoverTK.OfflineFormsActionUploadSuccess | translate"></uf-icon>
            </div>
            <!-- Upload failed -->
            <div class="error-icon" *ngIf="isFailed(info)">
                <uf-icon name="warning--solid" class="small"
                    [title]="discoverTK.OfflineFormsActionUploadFail | translate"></uf-icon>
            </div>
            <!-- Uploading -->
            <div class="uploading" *ngIf="isUploading(info)">
                <div>{{progress(info) | percent: '.0-0'}}</div>
                <uf-icon name="close" class="small" (click)="cancel(info)"></uf-icon>
            </div>
        </div>

    </uf-table>

</uf-panel>