import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, ToastService } from '@unifii/library/common';
import { FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import { Client, Definition, Dictionary, Error, Field, FormData, PublishedContent } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { BetterFormService } from 'shell/form/better-form.service';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { ShellTranslationKey } from 'shell/shell.tk';

import { BucketIdentifier } from 'discover/components/sign-in/sign-in-constants';
import { SignInHistoryComponent } from 'discover/components/sign-in/sign-in-history.component';


const FormIdentifier = 'sign-in';
const LocationFieldIdentifier = 'location';

@Component({
    templateUrl: './sign-in.html',
    styleUrls: ['./sign-in.less']
})
export class SignInComponent implements OnInit, EditedData {

    readonly sharedTK = SharedTermsTranslationKey;
    readonly shellTK = ShellTranslationKey;
    readonly commonTK = CommonTranslationKey;

    busy: boolean;
    error: Error;
    edited: boolean;

    definition: Definition;
    formData: FormData;
    formConfig: FormConfiguration;

    private _formComponent: UfFormComponent;

    constructor(
        private route: ActivatedRoute,
        private toastService: ToastService,
        private translate: TranslateService,
        private errorService: ErrorService,
        private router: Router,
        private formService: BetterFormService,
        private content: PublishedContent,
        @Inject(FormSettings) private settings: FormSettings,
        @Optional() private parent: SignInHistoryComponent
    ) {
        this.formService.bucket = BucketIdentifier;
        this.formConfig = {
            optionalCancelButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel)
        };
    }

    async ngOnInit() {
        const { id, location } = this.route.snapshot.params;

        try {
            this.formData = await this.getFormData(id);
            this.definition = await this.content.getForm(FormIdentifier, this.formData?._definitionVersion);

            if (location) {
                const locationData = await this.getLocation(location, this.definition);
                this.formData.location = locationData;
            }

            this.settings.uploader = this.formService.getUploader(this.formData.id as string);
        } catch (e) {
            const loadError = this.errorService.createLoadError(FormIdentifier, e);
            this.error = this.errorService.mergeError(e, loadError.message);
        }
    }

    @ViewChild(UfFormComponent, { static: false }) set formComponent(v: UfFormComponent) {

        if (v == null || this.formComponent) {
            return;
        }
        this._formComponent = v;

        v.rootControl.valueChanges.subscribe(() => {
            if (this._formComponent.rootControl.dirty) {
                this.edited = true;
            }
        });
    }

    get formComponent(): UfFormComponent {
        return this._formComponent;
    }

    back() {
        if (this.parent != null) {
            this.router.navigate(['..'], { relativeTo: this.route });
        } else {
            this.router.navigate(['/']);
        }
    }

    async save(args: SubmitArgs) {

        if (this.busy) {
            return;
        }

        this.busy = true;
        let savedFormData;

        try {
            savedFormData = await this.formService.save(args.data, this.definition);
            args.done(savedFormData);

            this.edited = false;
            this.busy = false;
            this.toastService.success(this.translate.instant(ShellTranslationKey.FormFeedbackSaved));

            if (this.parent != null) {
                await this.parent.update();
            }
            this.back();

        } catch (error) {
            this.error = this.errorService.createSaveError('form', error);
        } finally {
            this.busy = false;
        }
    }

    private async getFormData(id: string): Promise<FormData> {

        if (id === 'new') {
            return { id: Client.generateUUID() };
        }
        return await this.formService.get(id);
    }

    private async getLocation(id: string, definition: Definition): Promise<Dictionary<any>> {

        const locationField = this.getLocationField(definition);
        const outputs: Dictionary<string> = locationField?.dataSourceConfig?.outputs ?? {};
        const collectionIdentifier = locationField?.dataSourceConfig?.id;

        if (!collectionIdentifier) {
            throw this.errorService.createLoadError(FormIdentifier);
        }

        const locationItem = await this.content.getCollectionItem(collectionIdentifier, id);
        const result: Dictionary<any> = {};

        Object.keys(outputs).forEach(key => {
            result[key] = locationItem[outputs[key]];
        });
        return result;
    }

    private getLocationField(definition: Definition): Field | undefined {
        for (const field of this.fieldIterator(definition.fields)) {
            if (field.identifier === LocationFieldIdentifier) {
                return field;
            }
        }
        return;
    }

    private *fieldIterator(fields: Field[]): Iterable<Field> {

        for (const field of fields) {
            yield field;

            if (field.fields) {
                yield* this.fieldIterator(field.fields);
            }
        }
    }

}

