import { Compound, ContentType, Definition, Dictionary, ImageProfile, Page, PublishedContent, Query, RequestAnalytics, Schema, Structure, Table } from '@unifii/sdk';

import { Features } from 'shell/features';

import { DiscoverContext } from './discover-context';


export class DiscoverContent implements PublishedContent {

    constructor(
        private onlineContent: PublishedContent,
        private offlineContent: PublishedContent,
        private context: DiscoverContext,
        private features: Features
    ) { }

    getView(identifier: string): Promise<Compound> {
        return this.service.getView(identifier);
    }

    getViewDefinition(identifier: string): Promise<Definition> {
        return this.service.getViewDefinition(identifier);
    }

    getPage(id: string): Promise<Page> {
        return this.service.getPage(id);
    }

    getCollectionDefinition(identifier: string): Promise<Definition> {
        return this.service.getCollectionDefinition(identifier);
    }

    getCollections(signal?: AbortSignal, analytics?: RequestAnalytics): Promise<Definition[]> {
        return this.service.getCollections(signal, analytics);
    }

    queryCollection(identifier: string, query?: Query, signal?: AbortSignal, analytics?: RequestAnalytics): Promise<Compound[]> {
        return this.service.queryCollection(identifier, query, signal, analytics);
    }

    getCollectionItem(identifier: string, id: string): Promise<Compound> {
        return this.service.getCollectionItem(identifier, id);
    }

    getBucket(identifier: string): Promise<Schema> {
        return this.service.getBucket(identifier);
    }

    queryForms(query?: Query, signal?: AbortSignal, analytics?: RequestAnalytics): Promise<Definition[]> {
        return this.service.queryForms(query, signal, analytics);
    }

    getForm(identifier: string, version?: any): Promise<Definition> {
        return this.service.getForm(identifier, version);
    }

    getStructure(): Promise<Structure> {
        return this.service.getStructure();
    }

    queryTables(query?: Query, signal?: AbortSignal, analytics?: RequestAnalytics): Promise<Table[]> {
        return this.service.queryTables(query, signal, analytics);
    }

    /** TODO Remove hack once the API correctly return a null detail instead of the mocked one */
    async getTable(id: string): Promise<Table> {
        const table = await this.service.getTable(id);
        if (table.detail && !table.detail.title) {
            delete table.detail;
        }
        return table;
    }

    async getIdentifiers(): Promise<Dictionary<{ type: ContentType }>> {
        return this.service.getIdentifiers();
    }

    buildImageUrl(imageProfile: ImageProfile, options?: any): string | null {
        return this.service.buildImageUrl(imageProfile, options);
    }

    private get service(): PublishedContent {
        if (this.useOfflineService) {
            return this.offlineContent;
        }
        return this.onlineContent;
    }

    private get useOfflineService(): boolean {
        return this.features.offline && this.context.project?.offline === true;
    }
}
