import { Definition, FormDataOptions } from '@unifii/sdk';


export enum FileState {
    Pending = 'Pending',
    Uploaded = 'Uploaded',
}

export enum FormDataState {
    InProgres = 'InProgress',
    Pending = 'Pending',
    Uploaded = 'Uploaded',
}

export interface FileInfo {
    id: string;
    name: string;
    properties: FilePropertyBag;
    size: number;
    storedAt: Date;
    state: FileState;
}

export interface FormInfo extends FormDataOptions {
    id: string;
    form: Definition;
    result: string;
    state: string;
    storedAt: Date;
    size: number;
    status: FormDataState;
}
