import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'shell/app.module';

import { Config, Environment } from 'config';


(async () => {
    let config: Config;
    const configElement = window.document.getElementById('env') as HTMLElement;

    try {
        config = JSON.parse(configElement.innerHTML);
    } catch (e) {
        const response = await fetch('environment.json');
        config = await response.json();
        // fix goLang variable for localhost
        window.document.title = config.env;
    }

    if (config.env !== 'localhost' && config.env !== 'dev') {
        enableProdMode();
    }

    const environment = JSON.parse(JSON.stringify(config));

    platformBrowserDynamic([{ provide: Environment, useValue: environment }, { provide: Config, useValue: config }])
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));

})();
