import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CommonTranslationKey, CreatePasswordConfig, SharedTermsTranslationKey, ToastService, UfControl, UfFormBuilder
} from '@unifii/library/common';
import { Client, ErrorType, Interceptor, Provisioning, User, UserInfo } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { SdkInterceptor } from 'shell/sdk-interceptor';
import { Authentication } from 'shell/services/authentication';

import { DiscoverTranslationKey } from 'discover/discover.tk';

import { Config } from 'config';


/**
 * Via changePasswordOnNextLogin after successful login
 *  Update via Provisioning.updatePassword as authenticated user
 *
 * Via email link
 *  Update via Provisioning.updatePassword as anonymous user with token
 */
@Component({
    selector: 'ud-password-change',
    templateUrl: 'password-change.html'
})
export class PasswordChangeComponent {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    passwordConfig: CreatePasswordConfig;
    control: UfControl;
    loadError: AppError;
    error: AppError | null;
    user: UserInfo;
    busy: boolean;
    tokenProvisioning: Provisioning;

    private id: string;
    private token: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(Authentication) private authentication: Authentication,
        private errorService: ErrorService,
        private translate: TranslateService,
        private userService: User,
        private toastService: ToastService,
        private ufb: UfFormBuilder,
        @Inject(Interceptor) private interceptor: SdkInterceptor,
        @Inject(Config) private config: Config
    ) {
        this.init();
    }

    get isViaLink() {
        return this.route.snapshot.queryParams.tenant != null ||
            this.route.snapshot.queryParams.id != null ||
            this.route.snapshot.queryParams.token != null;
    }

    async submit() {
        this.control.setSubmitted();

        if (this.control.invalid || !this.control.value?.password) {
            return;
        }

        try {
            this.error = null;
            this.busy = true;

            const commands: any[] = ['/login'];

            if (this.isViaLink) {
                await this.tokenProvisioning.updatePassword(this.id, this.control.value.password as string, this.token ? this.token : undefined);
            } else {
                // Already logged in, update password
                await this.userService.updatePassword({ oldPassword: this.oldPassword, password: this.control.value.password });
                // Update my changePasswordOnNextLogin status
                this.authentication.userInfo = await this.userService.getMe();

                this.toastService.success(this.translate.instant(CommonTranslationKey.FeedbackSuccess));
                commands.push('projects');
                // old logic breaks in workers R&S contractors guard
            }

            this.authentication.oldPassword = undefined; // remove old password

            this.router.navigate(commands);

        } catch (error) {

            switch ((error as AppError).type) {
                case ErrorType.Unauthorized:
                    this.error = this.errorService.createError(this.errorService.passwordChangeInvalidLinkErrorMessage);
                    return;
                case ErrorType.Validation:
                    if (error.data && error.data.code === 'InvalidPassword') {
                        this.error = this.errorService.createError(this.errorService.passwordChangeInvalidPasswordErrorMessage);
                    } else if (error.data && error.data.code === 'PasswordNotChanged') {
                        this.error = this.errorService.createError(this.errorService.passwordChangeCannotBeSameErrorMessage);
                    } else {
                        this.error = this.errorService.createError(this.errorService.passwordChangeGenericErrorMessage);
                    }
                    break;
                default:
                    this.error = this.errorService.createError(this.errorService.passwordChangeGenericErrorMessage);
            }

        } finally {
            this.busy = false;
        }
    }

    logout() {
        this.authentication.logout();
    }

    private async init() {

        this.control = this.ufb.control({ value: {} });

        this.passwordConfig = {
            showOldPassword: !this.isViaLink && !this.authentication.oldPassword,
            isRequired: true,
            showStrengthIndicator: true,
            canCopy: true,
            canGenerate: true
        };

        if (this.isViaLink) {

            const tenant = this.route.snapshot.queryParams.tenant;
            this.id = this.route.snapshot.queryParams.id;
            this.token = this.route.snapshot.queryParams.token;

            const tokenClient = new Client(this.config.unifii, undefined, this.interceptor);
            this.tokenProvisioning = new Provisioning(tokenClient);

            try {

                if (!tenant || !this.id || !this.token) {
                    throw new AppError('');
                }

                // apply tenant from link param
                this.config.unifii.tenant = tenant;
                this.config.unifii.tenantSettings = await tokenClient.getTenantSettings();

                this.busy = true;
                this.user = await this.tokenProvisioning.getUser(this.id, this.token);

            } catch (error) {

                this.loadError = this.errorService.createError(
                    this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorInvalidLink)
                );

            } finally {

                this.busy = false;
            }
        }
    }

    private get oldPassword(): string {

        if (this.authentication.oldPassword) {
            return this.authentication.oldPassword;
        }
        return this.control.value?.oldPassword || '';
    }

}
