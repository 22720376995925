import { Component, Input } from '@angular/core';
import { Compound, Field } from '@unifii/sdk';


@Component({
    selector: 'us-link-list-display',
    templateUrl: './link-list.html',
    styleUrls: ['link-list.less']
})
export class LinkListComponent {

    linkList: Compound[] = [];
    tableColumns: any[] = [];
    tableRows: any[] = [];

    private _content: Compound[];
    private _field: Field;

    get field(): Field {
        return this._field;
    }

    @Input() set field(v: Field) {
        this._field = v;
        this.setup();
    }

    get content() {
        return this._content;
    }

    @Input() set content(v: Compound[]) {
        this._content = v;
        this.setup();
    }

    private setup() {
        if (!this.content || !this.field || !this.field.fields || !this.field.fields.length || !this.content.length) {
            return;
        }

        const fields: Field[] = this.field.fields;

        if (!this.field.template || this.field.template !== 'table') {
            /** Show list  */
            this.linkList = this.content;
            return;
        }

        this.setupTable(fields, this.content);

    }

    private setupTable(fields: Field[], values: Compound[]) {

        const configuredColumns = this.field.columns && this.field.columns.length ? this.field.columns : undefined;
        this.tableColumns = this.getColumns(fields, configuredColumns);
        this.tableRows = this.getRows(this.tableColumns, values, this.content.map(item => item.id) as string[]);
    }

    private getColumns(items: Field[], columns?: { identifier: string; label: string }[]): any[] {
        /** Columns are predefined columns */

        /** todo: add filter unsupported columns here */
        const parsedItems = items.map(item => ({
            label: item.label as string,
            identifier: item.identifier as string,
            type: item.type
        }));

        /** if no predefined columns show all */
        if (columns) {

            /** Return new filted array in correct order */
            return columns.reduce((result, column) => {

                /** find entry and add */
                const parsedItem = parsedItems.find(item => item.identifier === column.identifier);

                /** Guard needed incase column no longer exists */
                if (parsedItem) {
                    result.push(parsedItem);
                }

                return result;

            }, [] as any[]);

        }

        return parsedItems;
    }

    private getRows(columns: any[], values: Compound[], order: string[]): any[] {
        return order.reduce((result, id) => {
            const value = values.find(item => item.id === id) as Compound;
            const row = this.addCellsToRow(columns, value);

            if (Object.keys(row).length) {
                result.push(row);
            }

            return result;

        }, [] as any[]);
    }

    private addCellsToRow(columns: any[], value: Compound) {
        const row = {} as any;

        /** Iterate through each column and find a match in values */
        for (const column of columns) {
            const columnIdentifier = column.identifier;

            row[columnIdentifier] = {
                type: column.type,
                value: value[columnIdentifier] || ''
            };
        }

        return row;

    }
}
