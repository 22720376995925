import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ModalService } from '@unifii/library/common';

import { Features } from 'shell/features';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';

import { UpdateProgressComponent, UpdateProgressData } from 'discover/offline/update-progress.component';

import { Config } from 'config';


@Injectable()
export class OfflineContentGuard implements CanActivate {

    constructor(
        @Inject(Config) private config: Config,
        private modalService: ModalService,
        private offlineManager: OfflineManager,
        private features: Features,
        @Inject(Authentication) private authentication: Authentication,
    ) { }

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {

        if (!this.features.offline || !this.config.unifii.offline) {
            return of(true);
        }

        return of(null).pipe(
            flatMap(() => this.offlineManager.openContentDB()),
            map(() => true),
            catchError(error => {
                console.info('OfflineGuard: no content available, update needed', error);
                return this.updateContent();
            })
        );
    }

    private updateContent(): Observable<boolean> {

        return from(this.modalService.openFit<UpdateProgressData, boolean>(
            UpdateProgressComponent,
            { progress: this.offlineManager.updateContent() } as UpdateProgressData,
            { guard: true }
        )).pipe(flatMap(updated => {

            if (!updated) {
                // If no existing database we have no choice but to logout
                return from(this.authentication.logout());
            }

            return of(updated);
        }));
    }

}
