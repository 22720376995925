import { from, Observable } from 'rxjs';

import { SortStatus, TableDataSource } from '@unifii/library/common';
import { Provisioning, Query, RequestAnalyticsOrigin, UserInfo } from '@unifii/sdk';


export class UsersTableDataSource extends TableDataSource<UserInfo> {

    private _query: Query = new Query();

    constructor(
        private provisioning: Provisioning,
        query?: Query,
        private search?: string,
        private sort?: SortStatus
    ) {
        super();

        if (query != null) {
            // todo: should copy query rather than reassigning
            this._query = query;
        }

        // Open text search
        if (this.search && this.search.trim().length) {
            this._query = this._query.q(`${this.search}*`);
        }

        // Sort
        if (this.sorted) {
            this._query = this._query.sort((this.sort as SortStatus).name, (this.sort as SortStatus).direction);
        }
    }

    get filtered(): boolean {
        return this.search ? this.search.trim().length > 0 : false;
    }

    get sorted(): boolean {
        return this.sort != null;
    }

    load(offset?: number, limit?: number) {
        // Limit
        if (limit) {
            this._query = this._query.limit(limit, offset);
        }

        return this.provisioning.queryUsers(this._query, this.loadController?.signal, { origin: RequestAnalyticsOrigin.Table }).then(items => {
            this.stream.next({ data: items });
        }, error => {
            this.stream.next({ error });
        });
    }

    getDownloadUrl(limit = 10000): string | null {
        this._query.limit(limit);
        return this.provisioning.getUsersDownloadUrl(this._query);
    }

    download(limit?: number): Observable<any> {
        this._query.limit(limit || 10000);
        return from(this.provisioning.queryUsers(this._query));
    }

}
