import { Inject, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { Authentication } from 'shell/services/authentication';
@Injectable()
export class LoggedInGuard implements CanDeactivate<any> {

    constructor(
        @Inject(Authentication) private auth: Authentication,
    ) { }

    async canDeactivate(component: any): Promise<boolean> {
        if (component.navigationTrigger === 'popstate' && this.auth.isAuthenticated) {
            return false;
        }
        return true;
    }
}