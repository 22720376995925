import { TableDataSource, SortStatus } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { RiskMatrixEntry, RiskMatrixService } from './risk-matrix-service';


export class RiskMatrixDatasource extends TableDataSource<RiskMatrixEntry> {

    constructor(
        private riskMatrixService: RiskMatrixService,
        private filters?: any,
        private sort?: SortStatus
    ) {
        super();
    }

    get filtered(): boolean {
        return (this.filters);
    }

    get sorted(): boolean {
        return this.sort != null;
    }

    load(offset: number, limit?: number) {

        const params = Object.assign(this.params, { limit, offset });

        // Load and emit data
        this.riskMatrixService.getEntries(params, { origin: RequestAnalyticsOrigin.Table }).then(entries => {
            this.stream.next({ data: entries });
        }).catch(error => {
            this.stream.next({ error });
        });
    }

    get params(): any {

        const params: any = this.filtered ? this.filters : {};

        // Sort
        if (this.sorted) {
            params.sort = (this.sort as SortStatus).toString();
        }

        return params;
    }
}
