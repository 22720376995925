import { AppContext, ContextProvider } from '@unifii/library/common';
import { Company, FormData, UserInfo } from '@unifii/sdk';


export interface TableDetailContext extends AppContext {
    detail?: FormData | UserInfo;
}

export class TableDetailContextProvider implements ContextProvider {

    constructor(
        private contextProvider: ContextProvider,
        private detail: FormData | UserInfo | Company
    ) { }

    get(): TableDetailContext {

        const appContext: AppContext = this.contextProvider.get();

        return Object.assign(appContext, {
            detail: this.detail
        });
    }
}