import { ClaimConfig, Company as CompanyInfo, Compound, Definition, FormData, Page, UserAuthProvider, UserInfo, UserStatus } from '@unifii/sdk';


export enum DiscoverContentType {
    Company = 'company',
    User = 'user',
    UserProfile = 'userProfile'
}
export interface PageContent {
    page: Page;
}

export interface ViewContent {
    definition: Definition;
    compound: Compound;
}

export interface CollectionContent {
    definition: Definition;
    compounds: Compound[];
}

export interface CollectionItemContent {
    definition: Definition;
    compound: Compound;
}

export interface FormContent {
    definition: Definition;
    formData: FormData;
}

export interface CompanyContent {
    company?: CompanyInfo;
    claimConfig: ClaimConfig[];
}

export interface UserContent {
    userInfo: UserInfo;
    userAuthProviders: UserAuthProvider[];
    status: UserStatus | null;
}
