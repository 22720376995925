import { FileUploader } from '@unifii/library/common';
import { Bucket, Client, Error, ErrorType, Progress } from '@unifii/sdk';

import { AppError } from 'shell/errors/errors';
import { FileState } from 'shell/offline/forms/interfaces';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';


export class BetterUploader implements FileUploader {
    constructor(
        private bucket: Bucket,
        private offlineQ: OfflineQueue,
        private dataId: string,
    ) { }

    upload(file: File, progressCallback: (progress: Progress) => void = () => { }, signal?: AbortSignal): Promise<Progress> {

        if (this.bucket == null) {
            throw new AppError('Set bucket first');
        }

        const abortedError: Error = { type: ErrorType.AbortError, message: 'AbortError' };

        if (signal && signal.aborted) {
            return Promise.reject(abortedError);
        }

        const id = Client.generateUUID();
        const size = file.size;

        return new Promise<Progress>(async (resolve, reject) => {

            if (signal) {
                signal.addEventListener('abort', () => reject(abortedError));
            }

            const start = performance.now();

            try {
                // Save to local cache first
                await this.offlineQ.saveAttachment(this.dataId, id, file);
                progressCallback({ done: Math.trunc(file.size * 0.2), total: size });
                console.log(`Attachment saved in ${performance.now() - start}ms`);

                // Check if online before procceed with upload
                if (!navigator.onLine) {
                    resolve({ total: size, done: size, id, });
                    return;
                }

                // Save online
                const uploadProgress = await this.bucket.uploadAttachment(file, id, progress => {
                    progressCallback({ total: file.size, done: Math.min(Math.trunc(size * 0.2 + progress.done * 0.8), size) });
                }, signal);

                console.log(`Attachment uploaded in ${performance.now() - start}ms`);

                // update state to uploaded before reporting success
                const lockInfo = await this.offlineQ.getFileInfo(this.dataId, id);
                lockInfo.state = FileState.Uploaded;

                await this.offlineQ.updateFileInfo(this.dataId, lockInfo);

                resolve({
                    id: uploadProgress.id,
                    done: size,
                    total: size
                });

            } catch (e) {
                console.warn('Error uploading attachment', e);
                reject(e);
            } finally {
                console.log(`Upload finished in ${performance.now() - start}ms`);
            }
        });
    }

    getUrl(id: string): Promise<string> {
        if (this.bucket == null) {
            throw new AppError('Set bucket first');
        }

        return this.bucket.getAttachmentUrl(id);
    }
}