import { inject, Injectable, Injector, StaticProvider, Type, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterEntries, FilterEntry, FilterQueryBuilder, TableContainerManager } from '@unifii/components';
import { ContentType, ErrorType, PermissionAction, StructureNodeType, TableSourceType } from '@unifii/sdk';

import { ContentComponentFactory, ContentFactoryArgs } from 'shell/content/content-component-factory';
import { ContentComponentSelector } from 'shell/content/content-component-selector';
import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { IFrameComponent } from 'shell/content/iframe.component';
import { DashboardComponent } from 'shell/dashboard/dashboard.component';
import { AppError } from 'shell/errors/errors';
import { ShellTranslationKey } from 'shell/shell.tk';
import { CompanyTableContainerManager } from 'shell/table/companies/company-table-container-manager';
import { BucketFilterQueryBuilder } from 'shell/table/form-data/bucket-filter-query-builder';
import { BucketTableContainerManager } from 'shell/table/form-data/bucket-table-container-manager';
import { TablePageConfig } from 'shell/table/table-page-config';
import { UsersTableContainerManager } from 'shell/table/users/user-table-container-manager';
import { UsersFilterQueryBuilder } from 'shell/table/users/users-filter-query-builder';

import { UserFormPermissionConfig, UserFormResourceType } from 'discover/user-management/user-form-permission-controller';

import { DiscoverContentType } from './content-types';


@Injectable()
export class ShellContentComponentFactory implements ContentComponentFactory {

    dataResolver: ContentDataResolver;
    contentComponentSelector: ContentComponentSelector;

    private translate: TranslateService;

    constructor() {
        this.dataResolver = inject(ContentDataResolver);
        this.contentComponentSelector = inject(ContentComponentSelector);
        this.translate = inject(TranslateService);
    }

    async create(
        container: ViewContainerRef,
        type: ContentType | StructureNodeType | DiscoverContentType,
        { identifier, id, bucket, hasRollingVersion }: ContentFactoryArgs,
    ): Promise<any> { // todo: add explict types ComponentRef<T>.instance
        if (type === StructureNodeType.Dashboard) {
            const instance = this.createComponent(DashboardComponent, container);
            return instance;
        }

        if (type === StructureNodeType.IFrame) {
            this.createComponent(IFrameComponent, container);
            return;
        }

        if (type === ContentType.View && identifier) {
            const { definition, compound } = await this.dataResolver.getView(identifier);
            const instance = this.createComponent(this.contentComponentSelector.getViewComponent(), container);
            instance.definition = definition;
            instance.compound = compound;
            return instance;
        }

        if (type === ContentType.Page) {
            const page = await this.dataResolver.getPage(id as string);
            const instance = this.createComponent(this.contentComponentSelector.getPageComponent(), container);
            instance.page = page;
            return instance;
        }

        if (type === ContentType.Collection && identifier) {
            const { definition, compounds } = await this.dataResolver.getCollection(identifier);
            const instance = this.createComponent(this.contentComponentSelector.getCollectionComponent(), container);
            instance.definition = definition;
            instance.compounds = compounds;
            return instance;
        }

        if (type === ContentType.CollectionItem && identifier) {
            const { definition, compound } = await this.dataResolver.getCollectionItem(identifier, id as number);
            const instance = this.createComponent(this.contentComponentSelector.getCollectionItemComponent(), container);
            instance.definition = definition;
            instance.compound = compound;
            return instance;
        }

        if (type === ContentType.Table && identifier) {
            const { tablePageConfig, filterEntries } = await this.dataResolver.getTableData(identifier);
            // FormMetadataField '_parent' was an alias for '_parent.seqId' and since 1.32.0 '_parent.seqId' is fully supported in Discover
            // This re-map legacy configurations using the '_parent' alias
            tablePageConfig.table.columns?.filter(c => c.identifier === '_parent').forEach(c => c.identifier = '_parent.seqId');

            const injector = this.createTableInjector(tablePageConfig, filterEntries, container);
            const instance = this.createComponent(this.contentComponentSelector.getTableComponent(tablePageConfig.sourceType), container, injector);
            return instance;
        }

        if (type === ContentType.Form && bucket && id) {
            const { definition, formData } = await this.dataResolver.getFormData(bucket, id as string, hasRollingVersion);
            const instance = this.createComponent(this.contentComponentSelector.getFormComponent(), container);
            instance.definition = definition;
            instance.formData = formData;
            return instance;
        }

        if (type === ContentType.Form && identifier) {
            const definition = await this.dataResolver.getForm(identifier);
            const instance = this.createComponent(this.contentComponentSelector.getFormComponent(), container);
            instance.definition = definition;
            return instance;
        }

        if (type === DiscoverContentType.Company) {
            const { company, claimConfig } = await this.dataResolver.getCompanyContent(id as string);
            const instance = this.createComponent(this.contentComponentSelector.getCompanyComponent(), container);
            instance.company = company;
            instance.claimConfig = claimConfig;
            return instance;
        }

        if (type === DiscoverContentType.User && id) {
            const { userInfo, status, userAuthProviders } = await this.dataResolver.getUserContent(id as string);

            const userFormPermissionConfig = container.injector.get(UserFormPermissionConfig);
            userFormPermissionConfig.action = PermissionAction.Update;
            userFormPermissionConfig.resourceType = UserFormResourceType.Users;

            const instance = this.createComponent(this.contentComponentSelector.getUserComponent(), container);

            instance.userInfo = userInfo;
            instance.status = status;
            instance.userAuthProviders = userAuthProviders;
            return instance;
        }

        if (type === DiscoverContentType.UserProfile) {
            const { userInfo, status, userAuthProviders } = await this.dataResolver.getProfileContent();

            const userFormPermissionConfig = container.injector.get(UserFormPermissionConfig);
            userFormPermissionConfig.action = PermissionAction.Update;
            userFormPermissionConfig.resourceType = UserFormResourceType.Me;

            const instance = this.createComponent(this.contentComponentSelector.getUserComponent(), container);

            instance.userInfo = userInfo;
            instance.status = status;
            instance.userAuthProviders = userAuthProviders;
            return instance;
        }

        throw this.notFoundError;
    }

    private get notFoundError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorContentNotFound), ErrorType.NotFound);
    }

    private createComponent<T>(component: Type<T>, container: ViewContainerRef, injector?: Injector): T {
        const ref = container.createComponent(component, { index: 0, injector: injector ?? container.injector });
        return ref.instance;
    }

    private createTableInjector(tablePageConfig: TablePageConfig, filterEntries: FilterEntry[], container: ViewContainerRef): Injector {
        const tableManager = this.getTableManagerClass(tablePageConfig.sourceType);
        const providers: StaticProvider[] = [
            { provide: FilterEntries, useValue: filterEntries },
            { provide: TablePageConfig, useValue: tablePageConfig },
            { provide: TableContainerManager, useClass: tableManager, deps: [] }
        ];

        if (tablePageConfig.sourceType === TableSourceType.Users) {
            providers.push({ provide: FilterQueryBuilder, useClass: UsersFilterQueryBuilder, deps: [] });
        }

        if (tablePageConfig.sourceType === TableSourceType.Bucket) {
            providers.push({ provide: FilterQueryBuilder, useClass: BucketFilterQueryBuilder, deps: [TablePageConfig] });
        }

        return Injector.create({
            providers,
            parent: container.injector
        });
    }

    private getTableManagerClass(type: TableSourceType): Type<TableContainerManager<any, any, any>> {
        switch (type) {
            case (TableSourceType.Users): return UsersTableContainerManager;
            case (TableSourceType.Company): return CompanyTableContainerManager;
            case (TableSourceType.Bucket): return BucketTableContainerManager;
            default: throw new Error('Could not result DataDescriptor type');
        }
    }


}

