import { Inject, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, ModalService } from '@unifii/library/common';

import { ShellTranslationKey } from 'shell/shell.tk';

import { Authentication } from './authentication';


export interface EditedData {
    edited: boolean;
}

@Injectable()
export class UnsavedDataGuard implements CanDeactivate<EditedData> {

    constructor(
        private modal: ModalService,
        private translate: TranslateService,
        @Inject(Authentication) private auth: Authentication
    ) { }

    canDeactivate(component: EditedData): Promise<boolean> | boolean {

        if (component?.edited && this.auth.isAuthenticated) {
            return this.modal.openConfirm({
                title: this.translate.instant(ShellTranslationKey.UnsavedChangesModalTitle),
                message: this.translate.instant(ShellTranslationKey.UnsavedChangesModalMessage),
                confirmLabel: this.translate.instant(CommonTranslationKey.YesLabel),
                cancelLabel: this.translate.instant(CommonTranslationKey.NoLabel)
            });
        }

        return true;
    }

}
