import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ShellFeatureFlagService } from 'shell/services/shell-feature-flag.service';

import { ShellFeatureFlagKey } from 'shell/shell-model';


@Injectable()
export class FeatureFlagGuard implements CanActivate {

    constructor(
        private router: Router,
        private service: ShellFeatureFlagService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

        const flag = route.data.flag as ShellFeatureFlagKey;
        if (!await this.service.isEnabled(flag)) {
            console.info(`FeatureFlagGuard: flag ${flag} is not enabled for this application`);
            this.router.navigate([route.data.redirect || '/']);
            return false;
        }

        return true;
    }
}