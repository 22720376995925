import { Component, Inject, OnInit } from '@angular/core';
import { Compound, Definition, Field, FieldType, ImageProfile, PublishedContent, PermissionAction } from '@unifii/sdk';

import { CollectionContent } from 'shell/content/content-types';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';

import { Config } from 'config';
import { ContextProvider } from '@unifii/library/common';


interface Card {
    id: string;
    label: string;
    accessible: boolean;
    url?: string;
}

@Component({
    selector: 'us-collection',
    templateUrl: './collection.html'
})
export class CollectionComponent implements OnInit, CollectionContent {

    definition: Definition;
    compounds: Compound[];
    label: string;
    cards: Card[] = [];

    constructor(
        @Inject(PublishedContent) private content: PublishedContent,
        @Inject(Authentication) private auth: Authentication,
        @Inject(Config) private config: Config,
        @Inject(ContextProvider) private contextProvider: ContextProvider
    ) { }

    ngOnInit() {
        this.label = this.definition.label;
        this.cards = this.createCardList(this.definition, this.compounds);
    }

    getImageUrl(imageProfiles: ImageProfile[]): string | undefined {

        if (imageProfiles == null || !imageProfiles.length) {
            return;
        }

        const firstImage = imageProfiles[0] as ImageProfile;
        const width = '336'; // Size base off the the largest col1of3 found in a fixed grid
        const height = '212';

        // TODO @Adam - How to apply the imgHeight? // '&h=' + this.imgHeight + crop
        return this.content.buildImageUrl(firstImage, { width, height }) || undefined;
    }

    private createCardList(definition: Definition, compounds: Compound[] = []): Card[] {

        const imageIdentifier = this.findIdentifier(definition.fields, FieldType.ImageList) || '';
        const textIdentifier = this.findIdentifier(definition.fields, FieldType.Text) || '';

        return compounds.map(compound => {

            const id = compound.id || '';
            const url = this.getImageUrl(compound[imageIdentifier]);
            const label = compound[textIdentifier] || id;
            const accessible = this.auth.getGrantedInfo(
                PermissionsFunctions.getCollectionItemPath(this.config.unifii.projectId, definition.identifier, compound.id as string),
                PermissionAction.Read,
                compound,
                this.contextProvider.get()
            ).granted;

            return { id, url, label, accessible };
        });
    }

    private findIdentifier(fields: Field[] = [], type: FieldType): string | undefined {

        const field = fields.find(f => f.type === type);

        if (field != null) {
            return field.identifier;
        }
        return;
    }
}
