<div #pdfContainer class="container">
    <div></div>
    <us-loading-spinner *ngIf="loading"></us-loading-spinner>
    <uf-icon *ngIf="error" class="pdf-error large" name="document-error"></uf-icon>
</div>
<div class="uf-app-bar flat accent medium">
    <button class="uf-action" (click)="previousPage()" [title]="discoverTK.PdfPreviousPage | translate">
        <uf-icon name="arrowUp"></uf-icon>
    </button>
    <button class="uf-action" (click)="nextPage()" [title]="discoverTK.PdfNextPage | translate">
        <uf-icon name="arrowDown"></uf-icon>
    </button>
    <div class="title"><span *ngIf="!error">{{page}}/{{pagesCount}}</span></div>
    <button class="uf-action" (click)="zoomIn()" [title]="discoverTK.PdfZoomIn | translate">
        <uf-icon name="add"></uf-icon>
    </button>
    <button class="uf-action" (click)="zoomOut()" [title]="discoverTK.PdfZoomOut | translate">
        <uf-icon name="minus"></uf-icon>
    </button>
</div>