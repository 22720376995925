import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PanelComponent } from '@unifii/library/common';
import { Structure } from '@unifii/sdk';

import { ShellService } from 'shell/core/shell.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { NavigatorService } from 'shell/services/navigator.service';
import { ShellTranslationKey } from 'shell/shell.tk';

import { DeviceService } from 'capacitor/device.service';


@Component({
    selector: 'us-nav',
    templateUrl: 'nav.html',
    styleUrls: ['nav.less'],
})
export class NavComponent implements OnDestroy {

    @ViewChild(PanelComponent, { static: true }) panel: PanelComponent;

    @Input() img: string;

    readonly shellTK = ShellTranslationKey;

    structure: Structure | null;
    isHomeActive: boolean;

    private routerSub: Subscription;

    constructor(
        router: Router,
        nav: NavigationService,
        public device: DeviceService,
        public navigator: NavigatorService,
        private shell: ShellService,
    ) {

        this.structure = nav.structure;

        this.routerSub = router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
            this.isHomeActive = nav.isRoot(router.routerState.snapshot);
        });

        this.shell.menuOpened.subscribe(() => {

            if (this.panel && (device.isIOS || navigator.isIOS)) {
                /**
                 * force reflow on panel for
                 * mobile iOS 12.2 scroll bug where scroll position freezes
                 */
                this.panel.reflow();
            }
        });
    }

    close() {
        this.shell.closeMenuDrawer();
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
    }
}