import { Inject, Injectable, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterEntry } from '@unifii/components';
import {
    BucketDataDescriptorAdapter, CompanyDataDescriptorAdapter, ContextProvider, DataDescriptor, DataPropertyDescriptor,
    FormDefinitionMetadataIdentifiers, SharedTermsTranslationKey, UserDataDescriptorAdapter
} from '@unifii/library/common';
import { DisplayService } from '@unifii/library/smart-forms/display';
import {
    ClaimConfig, Client, Company, CompanyClient, Compound, Definition, ErrorType, Option, Page, PermissionAction, Provisioning, ProvisioningFunctions,
    PublishedContent, Table, TableSourceType, User, UserAuthProvider, UserFilterOptions
} from '@unifii/sdk';

import { CollectionContent, CollectionItemContent, CompanyContent, FormContent, UserContent, ViewContent } from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { BetterFormService } from 'shell/form/better-form.service';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableFilterEntryFactory } from 'shell/table/table-filter-entry-factory';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { UserInputType } from 'discover/user-management/user-types';

import { Config } from 'config';


export interface ContentDataResolver {
    getView(identifier: string): Promise<ViewContent>;
    getPage(id: string): Promise<Page>;
    getCollection(identifier: string): Promise<CollectionContent>;
    getCollectionItem(identifier: string, id: number): Promise<CollectionItemContent>;
    getTableData(identifier: string): Promise<{ tablePageConfig: TablePageConfig; filterEntries: FilterEntry[] }>;
    getForm(identifier: string, version?: number): Promise<Definition>;
    getFormData(bucket: string, id?: string, hasRollingVersion?: boolean): Promise<FormContent>;
    getCompanyContent(id?: string): Promise<CompanyContent>;
    getUserContent(id: string): Promise<UserContent>;
    getProfileContent(): Promise<UserContent>;
}

export const ContentDataResolver = new InjectionToken<ContentDataResolver>('UfContentDataResolver');


/**
 * Resolves Data for content components
 *  - Checks permissions
 *  - Catches errors
 */
@Injectable()
export class ShellContentDataResolver implements ContentDataResolver {
    /**
     * Create new instance of formService multiple instances can cause timing issues when
     * bucket is set by mutliple active components
     */
    private formService: BetterFormService;

    constructor(
        private display: DisplayService,
        @Inject(PublishedContent) private content: PublishedContent,
        private errorService: ErrorService,
        @Inject(Config) private config: Config,
        @Inject(Authentication) private auth: Authentication,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        private translate: TranslateService,
        private offlineQueue: OfflineQueue,
        private provisioning: Provisioning,
        private client: Client,
        private companyClient: CompanyClient,
        private user: User,
        private userDescriptorAdapter: UserDataDescriptorAdapter,
        private companyDescriptorAdapter: CompanyDataDescriptorAdapter,
        private bucketDescriptorAdapter: BucketDataDescriptorAdapter,
        private tableFilterEntryFactory: TableFilterEntryFactory
    ) {
        this.formService = new BetterFormService(this.config, this.client, this.offlineQueue);
    }

    async getView(identifier: string): Promise<ViewContent> {
        try {
            return await this.display.getView(identifier) as { definition: Definition; compound: Compound };
        } catch (e) {
            throw this.errorService.createLoadError(identifier, e);
        }
    }

    async getPage(id: string): Promise<Page> {
        try {
            const response = await this.display.getPage(id);
            return response.page as Page;
        } catch (e) {
            throw this.errorService.createLoadError(id, e);
        }
    }

    async getCollection(identifier: string): Promise<CollectionContent> {
        try {
            const definition = await this.content.getCollectionDefinition(identifier);
            const compounds = await this.content.queryCollection(identifier);
            return { definition, compounds };
        } catch (e) {
            throw this.errorService.createLoadError(identifier, e);
        }
    }

    async getCollectionItem(identifier: string, id: number): Promise<CollectionItemContent> {
        try {
            const data = await this.display.getCollectionItem(identifier, id as any as string);
            return data as { definition: Definition; compound: Compound };
        } catch (e) {
            throw this.errorService.createLoadError(identifier, e);
        }
    }

    async getForm(identifier: string, version?: number): Promise<Definition> {
        try {
            if (!this.canReadFormPath(identifier)) {
                throw this.forbiddenError;
            }

            const definition = await this.content.getForm(identifier, version);
            if (!this.canReadFormPath(identifier, definition)) {
                throw this.forbiddenError;
            }

            if (version && definition.hasRollingVersion) {
                return this.content.getForm(identifier);
            }

            return definition;
        } catch (e) {
            const loadError = this.errorService.createLoadError(identifier, e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getFormData(bucket: string, id: string, hasRollingVersion = false): Promise<FormContent> {
        try {
            if (!this.canReadFormDocumentPath(bucket, id)) {
                throw this.forbiddenError;
            }

            this.formService.bucket = bucket;
            const formData = await this.formService.get(id);
            const identifier = formData._definitionIdentifier as string;

            if (!this.canReadFormPath(identifier)) {
                throw this.forbiddenError;
            }
            const definition = await this.getForm(identifier, !hasRollingVersion ? formData._definitionVersion : undefined);
            return { definition, formData };

        } catch (e) {
            const loadError = this.errorService.createLoadError(bucket, e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getCompanyContent(id?: string): Promise<CompanyContent> {
        try {
            let company: Company | undefined;
            if (id) {
                company = await this.companyClient.getCompany(id);
            }

            let claimConfig: ClaimConfig[] = [];
            if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyClaimsPath(), PermissionAction.List).granted) {
                claimConfig = await this.client.getCompanyClaims();
            }

            return { company, claimConfig };
        } catch (e) {
            const loadError = this.errorService.createLoadError('company info', e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getUserContent(id: string): Promise<UserContent> {
        try {
            if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(+id), PermissionAction.Read).granted) {
                throw this.userDetailsForbiddenError;
            }

            const userInfo = await this.provisioning.getUser(id);
            const status = ProvisioningFunctions.getUserStatus(userInfo);

            let userAuthProviders: UserAuthProvider[] = [];
            if (userInfo.isExternal) {
                userAuthProviders = await this.provisioning.getUsersAuthProviders(id);
            }

            return { userInfo, status, userAuthProviders };
        } catch (e) {
            const loadError = this.errorService.createLoadError(id, e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getProfileContent(): Promise<UserContent> {
        try {
            const userInfo = await this.user.getMe();
            const status = ProvisioningFunctions.getUserStatus(userInfo);

            let userAuthProviders: UserAuthProvider[] = [];
            if (userInfo.isExternal) {
                userAuthProviders = await this.provisioning.getUsersAuthProviders(userInfo?.id as string);
            }

            return { userInfo, status, userAuthProviders };
        } catch (e) {
            const loadError = this.errorService.createLoadError('"me"', e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getTableData(identifier: string): Promise<{ tablePageConfig: TablePageConfig; filterEntries: FilterEntry[] }> {
        try {
            const table = await this.content.getTable(identifier);
            // Check ACL for Bucket and BucketDocuments
            if (!this.canAccessTable(table)) {
                throw this.forbiddenError;
            }

            const tablePageConfig = await this.getTablePageConfig(table);
            const filterEntries = this.createFilterEntries(tablePageConfig.propertyDescriptors, table.visibleFilters, table.sourceType);

            return { tablePageConfig, filterEntries };
        } catch (e) {
            const loadError = this.errorService.createLoadError(identifier, e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    private async getTablePageConfig(table: Table): Promise<TablePageConfig> {

        const properties = [
            ...(table.columns ?? []).map(cd => cd.identifier),
            ...(table.visibleFilters ?? []).map(vfo => vfo.identifier),
            ...(table.detail?.fields ?? []).map(fd => fd.identifier).filter(v => v != null) as string[]
        ];

        const dataDescriptor = await this.getDataDescriptor(table.sourceType, table.source, properties);
        if (dataDescriptor == null) {
            throw new Error(`Failed create dependencies for table: ${table.identifier}`);
        }

        if (dataDescriptor.skippedProperties && dataDescriptor.skippedProperties.length > 0) {
            console.warn(`DataDescriptor for ${table.sourceType}${table.source ? '[' + table.source + ']' : ''} skipped ${dataDescriptor.skippedProperties.length} properties`);
            for (const sp of dataDescriptor.skippedProperties) {
                console.warn(`${sp.identifier}: ${sp.name}`);
            }
        }

        const propertyDescriptorMap = new Map<string, DataPropertyDescriptor>();
        for (const descriptor of dataDescriptor.propertyDescriptors) {
            propertyDescriptorMap.set(descriptor.identifier, descriptor);
        }

        const config: TablePageConfig = {
            table,
            propertyDescriptors: propertyDescriptorMap,
            sourceType: table.sourceType,
            isSearchable: dataDescriptor.isSearchable === true,
        };

        if (table.sourceType === TableSourceType.Users) {
            config.addOptions = this.getUserTableAddOptions();
        }

        if (table.sourceType === TableSourceType.Bucket) {
            config.bucket = table.source as string;
            config.addOptions = await this.getBucketTableAddOptions(config.bucket, propertyDescriptorMap.get(FormDefinitionMetadataIdentifiers.DefinitionIdentifier));

            const schema = await this.content.getBucket(table.source as string);
            config.hasRollingVersion = schema.hasRollingVersion;
        }

        return config;
    }

    private async getBucketTableAddOptions(bucketId: string, definitionDescriptor?: DataPropertyDescriptor): Promise<Option[] | undefined> {
        if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, bucketId), PermissionAction.Add).granted) {
            return;
        }

        const options: Option[] = [];
        for (const option of (definitionDescriptor?.options ?? [])) {
            try {
                // Get form definition to run full permission check
                await this.getForm(option.identifier);
                options.push(option);
            } catch (e) { }
        }

        return options.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    private getUserTableAddOptions(): Option[] {
        const options: Option[] = [];
        if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted) {
            options.push({ identifier: UserInputType.Create, name: this.translate.instant(SharedTermsTranslationKey.ActionCreate) });
        }
        if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Add).granted) {
            options.push({ identifier: UserInputType.Invite, name: this.translate.instant(SharedTermsTranslationKey.ActionInvite) });
        }
        return options;
    }

    private createFilterEntries(
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        filters: UserFilterOptions[] = [],
        source: TableSourceType
    ): FilterEntry[] {
        return filters.map(f => this.tableFilterEntryFactory.create(f, propertyDescriptors, source))
            .filter(f => f != null) as FilterEntry[];
    }

    private async getDataDescriptor(type: TableSourceType, bucket?: string, properties?: string[]): Promise<DataDescriptor | undefined> {
        switch (type) {
            case TableSourceType.Users: return await this.userDescriptorAdapter.getDataDescriptor(properties);
            case TableSourceType.Company: return await this.companyDescriptorAdapter.getDataDescriptor(properties);
            case TableSourceType.Bucket: return await this.bucketDescriptorAdapter.getDataDescriptor(bucket as string, [FormDefinitionMetadataIdentifiers.DefinitionIdentifier, ...(properties ?? [])]);
            default: throw new Error('Could not result DataDescriptor type');
        }
    }

    private canReadFormPath(identifier: string, definition?: Definition): boolean {
        if (definition != null) {
            return this.auth.getGrantedInfo(PermissionsFunctions.getFormPath(this.config.unifii.projectId, definition.identifier), PermissionAction.Read, definition, this.contextProvider.get()).granted;
        }
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getFormPath(this.config.unifii.projectId, identifier), PermissionAction.Read).granted;
    }

    private canReadFormDocumentPath(bucketId: string, documentId: string): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, bucketId, documentId), PermissionAction.Read).granted;
    }

    private canAccessTable(table: Table): boolean {
        switch (table.sourceType) {
            case TableSourceType.Users:
                return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).granted;
            case TableSourceType.Company:
                return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.List).granted;
            case TableSourceType.Bucket: {
                return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketPath(this.config.unifii.projectId, table.source as string), PermissionAction.Read).granted &&
                    this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, table.source as string), PermissionAction.List).granted;
            };
            default: return true;
        }
    }

    private get forbiddenError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorRequestForbidden), ErrorType.Forbidden);
    }

    private get userDetailsForbiddenError(): AppError {
        return new AppError(this.translate.instant(this.translate.instant(DiscoverTranslationKey.UserDetailsErrorUnauthorized)), ErrorType.Forbidden);
    }


}
