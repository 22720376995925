<uf-panel class="container bg-gray" ufMasterDetail>
    <div class="uf-container bg-solid">
        <div class="uf-app-bar transparent pad-sm-left">
            <a *ngIf="prevUrl" class="uf-button tertiary left align-left" (click)="back(prevUrl)">
                <uf-icon name="arrowBack"></uf-icon>
                {{sharedTK.ActionBack | translate}}
            </a>
            <uf-breadcrumbs class="small left" [breadcrumbs]="breadcrumbs" [minimize]="true"></uf-breadcrumbs>
            <a *ngIf="itemLink" [routerLink]="itemLink.urlSegments" class="uf-button small primary right">
                {{ itemLink.name }}
            </a>
        </div>

        <div class="gap-top pad-sides">
            <uf-description-list class="responsive row-gap-sm pad-sm-bottom">
                <ng-container *ngFor="let item of fieldDescriptorItems">
                    <ng-container *ngIf="item.term; else header">
                        <dt>{{item.term}}</dt>
                        <uf-dd [description]=item.description></uf-dd>
                    </ng-container>
                    <ng-template #header>
                        <h5>{{item.description}}</h5>
                    </ng-template>
                </ng-container>
            </uf-description-list>
        </div>

        <div class="uf-grid pad-sides">
            <div *ngFor="let module of modules" class="col-12 gap-sm-top">
                <!-- todo: add switch statement as we get more types -->
                <ng-container *ngIf="module.type === 'Table'">
                    <us-table-module [module]="module" [item]="item" [detailContextProvider]="detailContextProvider">
                    </us-table-module>
                </ng-container>
            </div>
        </div>

    </div>
</uf-panel>

<router-outlet></router-outlet>