import { Component, HostBinding, inject, Inject, OnInit } from '@angular/core';
import {
    CreatePasswordValue, Modal, ModalData,
    ModalRuntime, SharedTermsTranslationKey, UfControl, UfControlArray, UfControlGroup
} from '@unifii/library/common';
import { Dictionary, FieldWidth, UserCreate, UserInvite } from '@unifii/sdk';
import { UserControlService, UserFieldLabelService, UserInfoKey } from '@unifii/user-provisioning';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { AddUserModalInfo, UserInputType } from 'discover/user-management/user-types';

@Component({
    templateUrl: './user-add.html',
    styleUrls: ['user-add.less']
})
export class UserAddComponent implements Modal<AddUserModalInfo, UfControlGroup>, OnInit {

    @HostBinding('class.uf-form-card') class = true;

    readonly userInfoControlKeys = UserInfoKey;
    readonly fieldWidth = FieldWidth;
    readonly userInputType = UserInputType;

    readonly discoverTK = DiscoverTranslationKey;
    readonly sharedTermsTK = SharedTermsTranslationKey;

    form: UfControlGroup;
    labelDictionary: Dictionary<string>;

    emailRequired = false;

    createPasswordValue: CreatePasswordValue = {};

    constructor(
        public runtime: ModalRuntime<AddUserModalInfo, UfControlGroup>,
        private userFieldLabelService: UserFieldLabelService,
        private userControlService: UserControlService,
        @Inject(ModalData) public data: AddUserModalInfo
    ) {
        this.labelDictionary = this.userFieldLabelService.labelDictionary;
    }

    async ngOnInit() {
        this.form = this.data.control;
        this.emailRequired = this.userControlService.isRequired(UserInfoKey.Email) || this.data.inputType === UserInputType.Invite;

        if (this.data.hasError) {
            this.form.setSubmitted();
        }
    }

    get user(): UserCreate | UserInvite {
        return this.form.getRawValue();
    }

    get usernameControl(): UfControl {
        return this.form.get(UserInfoKey.Username) as UfControl;
    }

    get canChangeUsernameControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.CanChangeUsername) as UfControl | undefined;
    }

    get emailControl(): UfControl {
        return this.form.get(UserInfoKey.Email) as UfControl;
    }

    get passwordControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Password) as UfControl | undefined;
    }

    get rolesControl(): UfControlArray | undefined {
        return this.form.get(UserInfoKey.Roles) as UfControlArray | undefined;
    }

    get unitsControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Units) as UfControl | undefined;
    }

    get unitPathsControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.UnitPaths) as UfControl | undefined;
    }

    get companyControl(): UfControlArray | undefined {
        return this.form.get(UserInfoKey.Company) as UfControlArray | undefined;
    }

    get claimsControl(): UfControlGroup | undefined {
        return this.form.get(UserInfoKey.Claims) as UfControlGroup | undefined;
    }

    async addUser() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.runtime.close(this.form);
    }

    close() {
        this.runtime.close();
    }
}
