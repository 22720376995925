import { Inject } from '@angular/core';
import { FilterEntry, FilterValue, UfFilterQueryBuilder } from '@unifii/components';
import { DataPropertyDescriptor, ValidatorFunctions } from '@unifii/library/common';
import { FieldType, Query } from '@unifii/sdk';

import { TablePageConfig } from '../table-page-config';


export class BucketFilterQueryBuilder extends UfFilterQueryBuilder {

    private propertyDescriptors: Map<string, DataPropertyDescriptor>;

    constructor(
        @Inject(TablePageConfig) config: TablePageConfig
    ) {
        super();
        this.propertyDescriptors = config.propertyDescriptors;
    }

    createQuery(value: FilterValue, entry: FilterEntry): Query | null {
        if (value == null || ValidatorFunctions.isEmpty(value)) {
            return null;
        }

        const { identifier } = entry;
        const descriptor = this.propertyDescriptors.get(identifier);
        if (descriptor == null) {
            return null;
        }

        const { type, dataSource } = descriptor;
        // Choice fields without data sources require in operator
        if (type === FieldType.Choice && dataSource == null) {
            const optionValue = this.parseOptionValue(value);
            return new Query().in(identifier, optionValue);
        }

        if (
            type === FieldType.Lookup &&
            dataSource != null &&
            !identifier.endsWith('._id')
        ) {
            // Add ._id to identifiers so the correct query is created
            return super.createQuery(value, {
                identifier: `${identifier}._id`,
                loader: entry.loader,
                type: entry.type
            });
        }

        return super.createQuery(value, entry);
    }

    private parseOptionValue(value: any): string[] {
        if (Array.isArray(value)) {
            return (value ?? []).map(v => v.identifier);
        }
        return [];
    }

}
