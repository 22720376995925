import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb, ModalService, SharedTermsTranslationKey, ToastService } from '@unifii/library/common';
import { FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { PrintConfig, PrintFormModalComponent, SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import { Definition, Error, FormData } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { OfflineFileUploader } from 'shell/offline/forms/offline-file-uploader';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';


@Component({
    selector: 'ud-offline-form',
    templateUrl: './offline-form.html',
    styleUrls: ['./offline-form.less']
})
export class OfflineFormComponent {

    @ViewChild(UfFormComponent, { static: true }) empForm: UfFormComponent;

    readonly discoverTK = DiscoverTranslationKey;

    error: Error | undefined;
    definition: Definition;
    formData: FormData;
    config: FormConfiguration;
    printConfig: PrintConfig | null;
    breadcrumbs: Breadcrumb[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private context: DiscoverContext,
        @Inject(FormSettings) private settings: FormSettings,
        private offlineQ: OfflineQueue,
        private toastService: ToastService,
        private errorService: ErrorService,
        private translate: TranslateService,
        private modalService: ModalService,
    ) {
        this.config = {
            optionalCancelButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            optionalSubmitButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionSubmit)
        };

        this.route.params.subscribe(p => {
            this.loadData(p.key);
        });
    }

    get formLabel(): string | null {
        return this.definition ? `${this.definition.label}` : null;
    }

    back() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    async submit(submitArgs: SubmitArgs) {
        try {
            this.error = undefined;
            const savedData = await this.offlineQ.save(submitArgs.data, this.definition, false);
            submitArgs.done(savedData);
            this.toastService.success(this.translate.instant(DiscoverTranslationKey.OfflineFormFeedbackStoreSuccess));
            this.back();
        } catch (error) {
            this.error = this.errorService.createError(this.errorService.offlineFormSaveErrorMessage, error);
        }
    }

    async print() {

        let logo: string | undefined;
        // Set project logo
        if (this.context.project && this.context.project.logo && this.context.project.logo.url) {
            logo = this.context.project.logo.url;
        }

        const summary = await this.modalService.openFit<null, boolean>(PrintFormModalComponent, null);
        if (summary !== undefined) {
            this.printConfig = {
                definition: JSON.parse(JSON.stringify(this.definition)),
                data: JSON.parse(JSON.stringify(this.formData)),
                logoUrl: logo,
                uploader: this.settings.uploader,
                summary
            };
        }
    }

    private async loadData(dataId: string) {

        this.error = undefined;

        try {
            const info = await this.offlineQ.getFormInfo(dataId);
            const data = await this.offlineQ.getData(dataId);

            if (info == null || data == null) {
                throw new AppError(this.translate.instant(DiscoverTranslationKey.OfflineFormErrorSave));
            }

            this.settings.uploader = new OfflineFileUploader(this.offlineQ, dataId);
            this.definition = info.form;
            this.formData = data;

            this.breadcrumbs = [
                { name: this.translate.instant(this.discoverTK.OfflineFormsTitle), urlSegments: ['../'] },
                { name: this.formLabel || '' },
            ];

        } catch (error) {
            this.error = this.errorService.createError(this.errorService.offlineFormLoadErrorMessage, error);
        }
    }

}
