import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    getGMaps, getSessionStorage, getStorage, getWindow, GoogleMaps, HierarchyUnitProvider, OfflineStorageService, Repository, SessionStorageWrapper,
    StorageWrapper, TablePreferencesProvider, UfHierarchyUnitProvider, WindowResizeController, WindowWrapper
} from '@unifii/library/common';
import { DataLoaderFactory } from '@unifii/library/smart-forms';
import {
    Client, CompanyClient, Content, ExternalDataSource, HierarchyClient, Interceptor, Provisioning, PublishedContent, TokenStorage, User
} from '@unifii/sdk';
import { UserProvisioningProvider } from '@unifii/user-provisioning';

import { AppComponent } from 'shell/app.component';
import { AppRoutes } from 'shell/nav/routes';
import { StructureGuard } from 'shell/nav/structure-guard';
import { SdkInterceptor } from 'shell/sdk-interceptor';
import { AuthService } from 'shell/services/auth.service';
import { Authentication } from 'shell/services/authentication';
import { BrowserGuard } from 'shell/services/browser-guard';
import { NavigatorService } from 'shell/services/navigator.service';
import { SSOService } from 'shell/services/sso.service';
import { TokenService } from 'shell/services/token.service';
import { TranslationsService } from 'shell/services/translations.service';
import { UserPreferencesService } from 'shell/services/user-preferences.service';
import { ShellModule } from 'shell/shell.module';
import { ShellMissingTranslationHandler } from 'shell/translations/shell-missing-translation-handler';

import { DeviceService } from 'capacitor/device.service';
import { InAppBrowserService } from 'capacitor/in-app-browser.service';
import { PushNotificationService } from 'capacitor/push-notification.service';
import { VibrateService } from 'capacitor/vibrate.service';

import { Config, Environment } from 'config';

import { languages, modules, providers, routeReuseStrategy, translationEntries } from 'settings';

import { ShellFeatureFlagService } from './services/shell-feature-flag.service';
import { UserProvisioning } from './services/user-provisioning';
import { ShellTranslateLoader } from './translations/shell-translate-loader';


export const browserAllowed = (browserGuard: BrowserGuard) =>
    () => browserGuard.allowed; // APP_INITIALIZER factory must return an executable function

export const initIndexDB = () =>
    () => {
        if (window.indexedDB) {
            const idb = window.indexedDB;
        }
    };   // temporary fix for https://bugs.webkit.org/show_bug.cgi?id=226547

export const initTranslations = (translationsService: TranslationsService) =>
    () => translationsService.register(languages); // APP_INITIALIZER factory must return an executable function

export const createTranslationService = (window: Window, repo: Repository, translateService: TranslateService, featureFlagService: ShellFeatureFlagService): TranslationsService =>
    new TranslationsService(window, repo, translateService, featureFlagService);

export const createClient = (config: Config, tokenStorage: TokenStorage, interceptor: Interceptor): Client =>
    new Client(config.unifii, tokenStorage, interceptor);


export const createContent = (config: Config, client: Client): Content =>
    new Content(client, config.unifii);

export const createProvisioning = (client: Client): Provisioning =>
    new Provisioning(client);

export const createUser = (client: Client): User =>
    new User(client);

export const createCompanyClient = (client: Client): CompanyClient =>
    new CompanyClient(client);

export const createExternalDataSource = (client: Client, config: Config): ExternalDataSource =>
    new ExternalDataSource(client, config.unifii);

export const createOfflineStorage = (config: Config): OfflineStorageService =>
    // TODO: review this -Oliver
    new OfflineStorageService(config.unifii.projectId, config.unifii.preview);

export const createShellTranslateLoader = (window: Window, config: Config) =>
    new ShellTranslateLoader(window, `${config.unifii.translationsUrl}/${config.unifii.appId}`, translationEntries);

export const getLocationStrategy = (platform: PlatformLocation, config: Config, baseRef: string) => {

    if (config.useHashUrls === true) {
        return new HashLocationStrategy(platform, baseRef);
    }
    return new PathLocationStrategy(platform, baseRef);
};

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createShellTranslateLoader,
                deps: [WindowWrapper, Config]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: ShellMissingTranslationHandler,
                deps: [ErrorHandler]
            },
        }),
        ShellModule,
        ...modules,
    ],

    // TODO: cleanup, move most things to ShellModule
    providers: [
        // config
        { provide: APP_BASE_HREF, useValue: '/' },

        { provide: LocationStrategy, useFactory: getLocationStrategy, deps: [PlatformLocation, Environment, APP_BASE_HREF] },

        { provide: APP_INITIALIZER, useFactory: initIndexDB, multi: true, deps: [] },
        { provide: APP_INITIALIZER, useFactory: browserAllowed, multi: true, deps: [BrowserGuard] },
        { provide: APP_INITIALIZER, useFactory: initTranslations, multi: true, deps: [TranslationsService] },

        { provide: RouteReuseStrategy, useClass: routeReuseStrategy },

        // native
        { provide: WindowWrapper, useFactory: getWindow, deps: [PLATFORM_ID] },
        { provide: StorageWrapper, useFactory: getStorage, deps: [PLATFORM_ID] },
        { provide: SessionStorageWrapper, useFactory: getSessionStorage, deps: [PLATFORM_ID] },

        // browser
        BrowserGuard,

        // capacitor
        DeviceService,
        PushNotificationService,
        VibrateService,
        InAppBrowserService,

        // 3rd party
        { provide: GoogleMaps, useFactory: getGMaps, deps: [PLATFORM_ID] },

        // unifii
        ShellFeatureFlagService,
        { provide: TranslationsService, useFactory: createTranslationService, deps: [WindowWrapper, Repository, TranslateService, ShellFeatureFlagService] },
        { provide: TokenStorage, useClass: TokenService },
        { provide: Interceptor, useClass: SdkInterceptor },
        { provide: Client, useFactory: createClient, deps: [Config, TokenStorage, Interceptor] },
        { provide: HierarchyClient, useFactory: (client: Client) => new HierarchyClient(client), deps: [Client] },
        { provide: HierarchyUnitProvider, useClass: UfHierarchyUnitProvider },
        { provide: Provisioning, useFactory: createProvisioning, deps: [Client] },
        { provide: UserProvisioningProvider, useClass: UserProvisioning },
        { provide: User, useFactory: createUser, deps: [Client] },
        { provide: CompanyClient, useFactory: createCompanyClient, deps: [Client]},
        { provide: Content, useFactory: createContent, deps: [Config, Client] },
        { provide: PublishedContent, useExisting: Content },
        { provide: ExternalDataSource, useFactory: createExternalDataSource, deps: [Client, Config] },
        DataLoaderFactory,
        { provide: Authentication, useClass: AuthService },
        { provide: OfflineStorageService, useFactory: createOfflineStorage, deps: [Config] },
        StructureGuard,
        SSOService,
        PushNotificationService,
        WindowResizeController,
        NavigatorService,
        { provide: TablePreferencesProvider, useClass: UserPreferencesService },
        ...providers,
    ]
})
export class AppModule { }
