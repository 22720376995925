

import { getDocument, PDFDocumentProxy } from 'pdfjs-dist';
import { EventBus, NullL10n, PDFLinkService, PDFViewer } from 'pdfjs-dist/web/pdf_viewer';

import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ContentField } from '@unifii/library/smart-forms';
import { Field } from '@unifii/sdk';

import { DiscoverTranslationKey } from 'discover/discover.tk';


@Component({
    selector: 'ud-pdf-viewer',
    templateUrl: './pdf-viewer.html',
    styleUrls: ['./pdf-viewer.less']
})
export class PDFViewerComponent implements ContentField, OnInit {

    @Input() content: any[];
    @Input() field: Field;

    @HostBinding('class.uf-box') boxClass = true;
    @HostBinding('class.inset') flatClass = true;
    @ViewChild('pdfContainer', { static: true }) private pdfContainer: ElementRef<HTMLDivElement>;

    readonly discoverTK = DiscoverTranslationKey;
    error: boolean;
    loading: boolean;

    private pdfDocument: PDFDocumentProxy;
    private pdfViewer: PDFViewer;
    private defaultScaleDelta = 1.1;
    private minScale = 0.25;
    private maxScale = 10.0;
    private defaultScaleValue = 'auto';

    // prevent right-click
    @HostListener('contextmenu', ['$event'])
    onRightClick(event: Event) {
        event.preventDefault();
    }

    async ngOnInit() {

        if(!this.content[0]?.url){
            this.error = true;
        }

        const pdfUrl = this.content[0].url;
        const container = this.pdfContainer.nativeElement;
        const pdfEvents = new EventBus();
        const linkService = new PDFLinkService({ eventBus: pdfEvents });

        try {
            this.loading = true;
            this.pdfDocument = await getDocument(pdfUrl).promise;
        } catch (error) {
            this.error = true;
            this.loading = false;
        }

        this.pdfViewer = new PDFViewer({
            container,
            eventBus: pdfEvents,
            linkService,
            l10n: NullL10n,
            useOnlyCssZoom: true,
            textLayerMode: 0,
        });

        linkService.setViewer(this.pdfViewer);
        this.pdfViewer.setDocument(this.pdfDocument);

        pdfEvents.on('pagesinit', () => {
            this.pdfViewer.currentScaleValue = this.defaultScaleValue.toString();
            this.pdfViewer.container.scrollTop = 0;
            this.loading = false;
        });
    }

    protected get pagesCount() {
        return this.pdfDocument?.numPages;
    };

    protected get page() {
        return this.pdfViewer?.currentPageNumber;
    };

    nextPage() {
        this.pdfViewer.nextPage();
    }

    previousPage() {
        this.pdfViewer.previousPage();

        if(this.page === 1){
            this.pdfViewer.container.scrollTop = 0;
        }
    }

    zoomIn() {
        let newScale = this.pdfViewer.currentScale;
        newScale = Number((newScale * this.defaultScaleDelta).toFixed(2));
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(this.maxScale, newScale);
        this.pdfViewer.currentScaleValue = newScale.toString();
    }

    zoomOut() {
        let newScale = this.pdfViewer.currentScale;
        newScale = Number((newScale / this.defaultScaleDelta).toFixed(2));
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(this.minScale, newScale);
        this.pdfViewer.currentScaleValue = newScale.toString();
    }
}
