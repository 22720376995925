import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { DataDescriptorAdapterCache } from '@unifii/library/common';
import { Client, User } from '@unifii/sdk';

import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { DiscoverContext } from 'discover/discover-context';

import { Config } from 'config';


@Injectable()
export class UserAndProjectGuard implements CanActivate {

    constructor(
        @Inject(Config) private config: Config,
        private context: DiscoverContext,
        @Inject(Authentication) private auth: Authentication,
        private client: Client,
        private user: User,
        private accessManager: UserAccessManager,
        private dataDescriptorAdapterCache: DataDescriptorAdapterCache
    ) { }

    async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        // Guard access token
        if (!this.auth.isAuthenticated || (this.auth.userInfo && this.auth.userInfo.changePasswordOnNextLogin)) {
            this.accessManager.deny({ grantedRedirectURL: state.url });
            return false;
        }

        // Guard selected project
        if (!this.context.project) {
            this.accessManager.deny({ grantedRedirectURL: state.url, deniedRedirectURL: ['/login', 'projects'] });
            return false;
        }

        // Reset data descriptor cache
        this.dataDescriptorAdapterCache.reset();

        // todo: deal with offline
        const timeout = new Promise<void>(resolve => setTimeout(() => resolve(), 500));
        await Promise.race([this.updateUserAndProject(), timeout]);
        return true;
    }

    private async updateUserAndProject(): Promise<void> {

        try {
            this.auth.userInfo = await this.user.getMe();
            const permissions = await this.user.getPermissions(false);
            this.auth.userPermissions = PermissionsFunctions.normalizePermissions(permissions);
            this.context.project = await this.client.getProject(this.config.unifii.projectId);
        } catch (e) {
            console.warn('update user and project failed');
        }
    }


}
