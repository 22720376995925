import { Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    AddActionConfig, DownloadConfig, FilterEntries, FilterEntry, FilterQueryBuilder, FilterSerializer, FilterValue, TableContainerManager,
    TableInputManager, TableInputs, TableManagerFunctions
} from '@unifii/components';
import {
    CommonTranslationKey, ContextProvider, DataPropertyDescriptor, ModalService, SafeUrlFunctions, SharedTermsTranslationKey, TableAction,
    TableConfig, TableRowContext, ToastService
} from '@unifii/library/common';
import {
    AstNode, Client, ColumnDescriptor, Option, PermissionAction, Provisioning, ProvisioningFunctions, Query, Table, TableSourceType, UserInfo,
    UserInvite, UserStatus
} from '@unifii/sdk';

import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';

import { CreateUserPath, DetailPath, InviteUserPath } from 'discover/discover-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { UserInputType } from 'discover/user-management/user-types';

import { ASTExpressionParser } from '../ast-expression-parser';
import { TableColumnFactory } from '../table-column-factory';
import { TablePageConfig } from '../table-page-config';

import { UsersTableDataSource } from './users-table-datasource';


@Injectable()
export class UsersTableContainerManager implements TableContainerManager<UserInfo, FilterValue, FilterEntry> {

    readonly discoverTK = DiscoverTranslationKey;

    tableConfig: TableConfig<UserInfo>;
    showSearch = true;
    addActionConfig?: AddActionConfig;
    downloadConfig?: DownloadConfig;
    customColumns: ColumnDescriptor[] = [];
    defaultSort: string | undefined;

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<UserInfo>();

    inputManager: TableInputManager<FilterValue, FilterEntry>;

    private tableInputs?: TableInputs<FilterValue>;
    private filter?: AstNode;
    private contextFilter?: AstNode; // filter passed via url param $cf
    // dependencies
    private client: Client;
    private auth: Authentication;
    private contextProvider: ContextProvider;
    private translate: TranslateService;
    private provisioning: Provisioning;
    private modalService: ModalService;
    private toastService: ToastService;
    private router: Router;
    private route: ActivatedRoute;
    private columnFactory: TableColumnFactory<UserInfo>;

    constructor() {
        this.client = inject(Client);
        this.auth = inject(Authentication);
        this.contextProvider = inject(ContextProvider);
        this.provisioning = inject(Provisioning);
        this.translate = inject(TranslateService);
        this.modalService = inject(ModalService);
        this.toastService = inject(ToastService);
        this.router = inject(Router);
        this.route = inject(ActivatedRoute);
        this.columnFactory = inject(TableColumnFactory);

        this.inputManager = new TableInputManager(this.entries, this.serializer, this.queryBuilder);

        const { table, propertyDescriptors, addOptions } = inject(TablePageConfig);

        this.defaultSort = table.defaultSort;

        if (table.filter) {
            const astParser = inject(ASTExpressionParser);
            this.filter = astParser.parse(table.filter);
            this.inputManager.staticFilter = new Query().fromAst(this.filter as AstNode);
        }

        // set context filter
        const { $cf } = inject(ActivatedRoute).snapshot.params;
        if ($cf) {
            this.contextFilter = SafeUrlFunctions.decodeObj($cf) as AstNode;
        }

        if (table.hideExport !== true) {
            this.downloadConfig = {
                name: `${table.title}.csv`,
                getUrl: this.getDownloadUrl.bind(this)
            };
        }

        this.addActionConfig = this.createAddConfig(table, addOptions ?? []);
        this.setManagerConfig(table, propertyDescriptors);
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        this.tableInputs = inputs;
        let query: Query = new Query();

        if (inputs?.filters && this.inputManager != null) {
            query = this.inputManager.filterManager.toQuery(inputs.filters);
        }

        if (this.filter != null) {
            query = query.fromAst(this.filter);
        }

        if (this.contextFilter != null) {
            query = query.fromAst(this.contextFilter);
        }

        return new UsersTableDataSource(this.provisioning, query, inputs?.q, inputs?.sort);
    }

    addActionCallback = (identifier: string) => {
        const path = identifier === UserInputType.Create ? CreateUserPath : InviteUserPath;
        this.router.navigate([path], { relativeTo: this.route });
    };

    private async getDownloadUrl(): Promise<string> {
        const dataSource = this.createDataSource(this.tableInputs);
        const url = dataSource.getDownloadUrl();
        if (!url) {
            throw new Error('Failed to get download url');
        }

        const { token } = await this.client.getDownloadToken(url);
        return `${url}&_dlt=${token}`;
    }

    private setManagerConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>) {
        const canInvite = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted;
        const canDelete = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(), PermissionAction.Delete).granted;

        this.customColumns = (table.columns ?? []).filter(c => (c.heading != null || c.variations != null));

        const id = `table_${table.identifier}`;
        const columns = this.columnFactory.create(table.columns ?? [], propertyDescriptors, TableSourceType.Users);
        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);
        tableConfig.rowLink = (item: UserInfo) => this.getRowLink(item, table.detail != null);
        tableConfig.actions = this.getActions(canInvite);

        if (table.detail == null && (canInvite || canDelete)) {
            tableConfig.selectable = 100;
        }
        this.tableConfig = tableConfig;
    }

    private getRowLink(userInfo: UserInfo, hasDetailPage = false) {
        const isGranted = this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+(userInfo.id as string)), PermissionAction.Read, userInfo, this.contextProvider.get()).granted;
        if (isGranted) {
            if (hasDetailPage) {
                return [DetailPath, { id: userInfo.id }];
            }
            return userInfo.id + '';
        }
        return [];
    }

    private getActions(canInvite = false): TableAction<UserInfo>[] | undefined {
        return [{
            label: this.translate.instant(SharedTermsTranslationKey.ActionDelete),
            predicate: row => this.canDeleteUser(row.$implicit),
            action: rows => {
                const users = (rows as TableRowContext<UserInfo>[]).map(r => r.$implicit);
                this.delete(users);
            }
        }, {
            label: this.translate.instant(DiscoverTranslationKey.UserActionResendInvite),
            predicate: row => this.canInviteUser(row.$implicit, canInvite),
            action: rows => {
                const users = (rows as TableRowContext<UserInfo>[]).map(row => row.$implicit);
                this.inviteUsers(users);
            }
        }];
    }

    private canDeleteUser = (userInfo: UserInfo) => {
        const id = +(userInfo.id as string);
        const path = PermissionsFunctions.getUserPath(id);

        return ProvisioningFunctions.getUserStatus(userInfo) === UserStatus.Pending &&
            this.auth.getGrantedInfo(path, PermissionAction.Delete, userInfo, this.contextProvider.get()).granted;
    };

    private canInviteUser = (userInfo: UserInfo, canInvite: boolean) => canInvite && ProvisioningFunctions.getUserStatus(userInfo) === UserStatus.Pending;

    private async delete(users: UserInfo[]): Promise<void> {
        const confirmed = await this.modalService.openConfirm({ message: this.translate.instant(DiscoverTranslationKey.UserInviteDeleteModalMessage) });
        if (!confirmed) {
            return;
        }

        try {
            for (const { id } of users) {
                await this.provisioning.deleteUser(id + '');
            }
            this.toastService.success(this.translate.instant(ShellTranslationKey.DeleteModalSuccess));
            this.reload.next();
        } catch (e) {
            this.toastService.error(this.translate.instant(ShellTranslationKey.DeleteModalFail));
        }
    };

    private async inviteUsers(users: UserInfo[]): Promise<void> {
        const confirmed = await this.modalService.openConfirm({ message: this.translate.instant(DiscoverTranslationKey.UserInviteModalMessage) });
        if (!confirmed) {
            return;
        }

        try {
            const invites = users.map(user => ({ email: user.email as string, username: user.username, company: user.company } as UserInvite));
            await this.provisioning.bulkInviteUsers(invites);
            this.toastService.success(this.translate.instant(CommonTranslationKey.FeedbackSuccess));
            this.reload.next();
        } catch (e) {
            this.toastService.error(this.translate.instant(CommonTranslationKey.FeedbackFail));
        }
    }

    private createAddConfig(table: Table, options: Option[]): AddActionConfig | undefined {
        /**
         * Hide actions if table has a detail, actions are only used when a table is configured with a direct user form
         * as it's assumed that table is created with the goal of editing users. In the future we may add configuration around allowing table actions in the configuration
         */
        if (table.detail != null || !options.length) {
            return;
        }
        return {
            label: this.translate.instant(this.discoverTK.UsersAddTitle),
            options
        };
    }

    private get entries(): FilterEntry[] {
        try {
            return inject(FilterEntries) as FilterEntry[];
        } catch (e) {
            return [];
        }
    }

    private get serializer(): FilterSerializer<FilterValue, FilterEntry> | undefined {
        try {
            return inject(FilterSerializer) as FilterSerializer<FilterValue, FilterEntry>;
        } catch (e) {
            return;
        }
    }

    private get queryBuilder(): FilterQueryBuilder<FilterValue, FilterEntry> | undefined {
        try {
            return inject(FilterQueryBuilder) as FilterQueryBuilder<FilterValue, FilterEntry>;
        } catch (e) {
            return;
        }
    }

}


