import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { FileUploader } from '@unifii/library/common';
import { Bucket, Client, Definition, FormData, Query, RequestAnalytics } from '@unifii/sdk';

import { AppError } from 'shell/errors/errors';
import { BetterUploader } from 'shell/form/better-uploader';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';

import { Config } from 'config';


/**
 * This class tries to inteligently deal with with saving forms.
 * If the servers can't be reached it falls back to offline storage
 */
@Injectable()
export class BetterFormService {

    formSaved: Subject<FormData> = new Subject();

    private formBucket: Bucket | null = null;
    private _bucket: string | null = null;

    constructor(
        @Inject(Config) private config: Config,
        private client: Client,
        private offlineQ: OfflineQueue,
    ) { }

    set bucket(b: string) {
        this._bucket = b;
        this.formBucket = new Bucket(this.client, {
            bucket: this._bucket,
            preview: this.config.unifii.preview,
            projectId: this.config.unifii.projectId,
        });
    }

    get bucket(): string {
        return this._bucket as string;
    }

    getUploader(dataId: string): FileUploader {
        if (this.formBucket == null) {
            throw new AppError('Set bucket first');
        }

        return new BetterUploader(this.formBucket, this.offlineQ, dataId);
    }

    get(id: string): Promise<FormData> {
        if (this.formBucket == null) {
            throw new AppError('Set bucket first');
        }

        return this.formBucket.get(id);
    }

    query(query: Query, signal?: AbortSignal, analytics?: RequestAnalytics): Promise<FormData[]> {
        if (this.formBucket == null) {
            throw new AppError('Set bucket first');
        }

        return this.formBucket.query(query, signal, analytics);
    }

    getDownloadUrl(query: Query): string {
        if (this.formBucket == null) {
            throw new AppError('Set bucket first');
        }

        return this.formBucket.getDownloadUrl(query);
    }

    async save(data: FormData, definition: Definition): Promise<FormData> {

        if (this.formBucket == null) {
            throw new AppError('Set bucket first');
        }

        try {
            await this.offlineQ.save(data, definition, false);
            const result = await this.offlineQ.upload(data.id as string);

            if (!result.formData) {
                throw new Error('Save failed');
            }

            this.formSaved.next(result.formData);
            return result.formData;

        } catch (e) {
            console.warn('Error uploading form:', e);
            this.offlineQ.emitAddition();
            return data;
        }
    }
}


