import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Breadcrumb, CommonTranslationKey, ContextProvider, DataPropertyDescriptor, DescriptionListItem, FieldDescriptionServiceProvider, FieldDisplayPipe,
    SharedTermsTranslationKey, TemplateStringParser
} from '@unifii/library/common';
import {
    ClaimConfig, Client, Company, FieldDescriptor, FieldType, FormData, PermissionAction, Table, TableDetail, TableDetailModule, TableSourceType,
    UserInfo
} from '@unifii/sdk';
import { UserDescriptionService, UserDescriptionServiceProvider } from '@unifii/user-provisioning';

import { ShellService } from 'shell/core/shell.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { getColumnRenderableValue } from 'shell/services/schema-field-functions';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableData } from 'shell/table/models';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DiscoverFieldDescriptionService } from 'discover/services/discover-field-description.service';

import { TableDetailContextProvider } from './table-detail-context-provider';
import { TableDetailData } from './table-detail-resolver';


@Component({
    templateUrl: './table-detail.html'
})
export class TableDetailComponent implements OnInit {

    readonly sharedTK = SharedTermsTranslationKey;
    readonly shellTk = ShellTranslationKey;
    readonly commonTK = CommonTranslationKey;

    detailContextProvider: TableDetailContextProvider;
    prevUrl?: string;

    // Set by route resolver
    table: Table;
    detail: TableDetail;
    item: TableData;
    propertyDescriptors: Map<string, DataPropertyDescriptor>;
    fieldDescriptorItems: DescriptionListItem[];
    modules: TableDetailModule[] = [];
    itemLink: { name: string; urlSegments: any[] } | undefined;
    breadcrumbs: Breadcrumb[] = [];
    title: string; // make available for children to use a breadcrumb

    private userClaims: ClaimConfig[];
    private companyClaims: ClaimConfig[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fieldDisplayPipe: FieldDisplayPipe,
        @Inject(TablePageConfig) private tablePageConfig: TablePageConfig,
        private shell: ShellService,
        private stringParser: TemplateStringParser,
        @Inject(Authentication) private auth: Authentication,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        private client: Client,
        @Inject(FieldDescriptionServiceProvider) private descriptionService: DiscoverFieldDescriptionService,
        @Inject(UserDescriptionServiceProvider) private userDescriptionService: UserDescriptionService
    ) {
        this.prevUrl = this.route.snapshot.params.prevUrl;
    }

    async ngOnInit() {
        const { table, detail, propertyDescriptors, item, itemLink } = this.route.snapshot.data.input as TableDetailData;

        this.detailContextProvider = new TableDetailContextProvider(this.contextProvider, item);  // Monkey-patch ContextProvider
        this.table = table;
        this.detail = detail;
        this.propertyDescriptors = propertyDescriptors;
        this.item = item;
        this.itemLink = itemLink;

        if (table.sourceType === TableSourceType.Company) {
            if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyClaimsPath(), PermissionAction.List).granted) {
                this.companyClaims = await this.client.getCompanyClaims();
            }
        } else if (table.sourceType === TableSourceType.Users) {
            if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getDefaultClaimsPath(), PermissionAction.List).granted) {
                this.userClaims = await this.client.getUserClaims();
            }
        }

        this.refreshDetails();

        // Set title
        const context = Object.assign({ self: null, root: {} }, this.detailContextProvider.get());
        this.title = this.stringParser.parse(this.detail.title, context, this.item);
        this.shell.setTitle(this.title);

        this.modules = this.detail.modules || [];

        if (!this.prevUrl) {
            this.breadcrumbs = this.getBreadcrumbs();
        }
    }

    back(url: string) {
        this.router.navigateByUrl(url);
    }

    updateDetails(formData: FormData) {
        this.item = formData;
        this.refreshDetails();
    }

    private refreshDetails() {
        this.fieldDescriptorItems = (this.detail.fields || [])
            .map(descriptor => this.toDescription(descriptor))
            .filter(d => d != null) as DescriptionListItem[];
    }

    private getBreadcrumbs(): Breadcrumb[] {
        const name = this.tablePageConfig.table.title;
        return [{ name, urlSegments: ['..'] }, { name: this.title }];
    }

    private toDescription({ type, identifier, value }: FieldDescriptor): DescriptionListItem | undefined {

        if (type === 'Heading') {
            return { term: undefined as any as string, description: value as string };
        }

        if (!identifier) {
            // Should not reach here as matched the type === 'Heading' case
            return undefined;
        }

        // Source type - Users
        if (this.table.sourceType === TableSourceType.Users) {
            const itemValue = (this.item as UserInfo)[identifier as keyof UserInfo];

            // TODO available this.propertyDescriptors to use instead
            return this.userDescriptionService.createUserDescription(itemValue, identifier, this.userClaims, this.item as UserInfo);
        }

        // Source type - Companies
        if (this.table.sourceType === TableSourceType.Company) {
            const itemValue = (this.item as Company)[identifier as keyof Company];

            // TODO available this.propertyDescriptors to use instead
            return this.descriptionService.createCompanyDescription(itemValue, identifier, this.companyClaims, this.item as Company);
        }

        // Source type - Form Data
        const dataPropertyDescriptor = this.propertyDescriptors.get(identifier);
        if (dataPropertyDescriptor == null || !dataPropertyDescriptor.asDisplay) {
            return;
        }

        const term = dataPropertyDescriptor.label;
        let description: any = (this.item as FormData)[dataPropertyDescriptor.identifier as keyof FormData];

        if ([FieldType.MultiChoice, FieldType.TextArray].includes(dataPropertyDescriptor.type)) {
            description = this.fieldDisplayPipe.transform(description, dataPropertyDescriptor.type, dataPropertyDescriptor.options);
        }

        description = getColumnRenderableValue(this.item as FormData, dataPropertyDescriptor, this.fieldDisplayPipe);
        return { term, description };
    }


}
