<uf-panel class="container">
    <div class="uf-container-md">
        <div class="uf-grid condensed">

            <br>

            <div class="col-12 gap-lg-top gap-sides" *ngIf="logo">
                <img class="logo" [src]="logo" alt="{{logo}}" />
            </div>

            <div class="col-12 gap-sides body-copy" *ngIf="description" [markdownRenderer]="description">
            </div>
        </div>

        <div class="uf-grid directory-template row-gap-lg">
            <ng-container *ngFor="let link of links">
                <ng-container *ngIf="canAccess(link)">
                    <a *ngIf="link.href" [attr.href]="link.href" ufTarget="_blank" class="uf-card centered">
                        <div *ngIf="link.imgUrl" class="uf-img-16x9">
                            <img [src]="link.imgUrl">
                        </div>
                        <div class="uf-card-title">
                            <h4 *ngIf="link.label">{{link.label}}</h4>
                        </div>
                    </a>

                    <a *ngIf="link.link" [routerLink]="link.link" class="uf-card centered">
                        <div *ngIf="link.imgUrl" class="uf-img-16x9">
                            <img [src]="link.imgUrl">
                        </div>
                        <div class="uf-card-title">
                            <h4 *ngIf="link.label">{{link.label}}</h4>
                        </div>
                    </a>
                </ng-container>
            </ng-container>
        </div>

    </div>

</uf-panel>