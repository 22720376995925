<div *ngIf="error">
    <uf-message class="col-1of1 error padded" icon="errorSolid" [heading]="error.code" [content]="error.message">
    </uf-message>
</div>

<ng-template [ngIf]="tableConfig">
    <div class="header">
        <a *ngIf="tableLink" [routerLink]="tableLink">
            {{title}}
            <ng-template [ngIf]="pageTable && pageTable.status.exhausted === false">
                <uf-icon class="small" name="moreInfo"></uf-icon>
                <span>
                    {{shellTK.DashboardBucketActionSeeMore | translate}}
                </span>
            </ng-template>
        </a>
    </div>
    <uf-table #pageTable [config]="tableConfig" [datasource]="dataSource" class="small-message accent">
        <ng-container *ngFor="let descriptor of customColumns">
            <ng-container *cellFor="descriptor.identifier; let item; let index = index; let column = column;">
                <uf-custom-cell [index]="index" [item]="item" [column]="column" [descriptor]="descriptor">
                </uf-custom-cell>
            </ng-container>
        </ng-container>
    </uf-table>
</ng-template>