import { Compound, ContentType, Definition, Dictionary, ImageProfile, Page, Progress, Schema, Structure, Table } from '@unifii/sdk';


export interface ContentPackage {
    info: ContentInfo;
    structure?: Structure;
    pages: Page[];
    views: { definition: Definition; compound: Compound }[];
    collections: { definition: Definition; compounds: Compound[] }[];
    forms: { definition: Definition; versions: Definition[] }[];
    buckets: Schema[];
    assets: ImageProfile[];
    tables: Table[];
    identifiers: Dictionary<{ type: ContentType }>;
}

export interface ContentInfo {
    tenant: string;
    projectId: string;
    name: string;
    version: number;
    preview?: number;
    state: ContentState;
}

export enum ContentState {
    Previous = 'Previous',
    Active = 'Active',
    Next = 'Next',
    Delete = 'Delete'
}

export enum TenantStores {
    Assets = 'assets',
    Projects = 'projects',
    Versions = 'versions'
}

export enum ContentStores {
    Indexes = 'indexes',
    Assets = 'assets',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Structure = 'structure',
    Views = 'views',
    ViewDefinitions = 'view-definitions',
    Pages = 'pages',
    Collections = 'collections',
    Forms = 'forms',
    FormVersions = 'form-versions',
    Buckets = 'buckets',
    Tables = 'tables',
    Identifiers = 'identifiers'
}

export const parseVersionName = (versionName: string): { version: number; preview?: number } => {

    const [version, preview] = versionName.split('preview');

    return {
        version: +version,
        preview: preview ? +preview : undefined
    };
};

export const buildOfflineImageUrl = (imageProfile: ImageProfile): string => {

    const name = imageProfile.id;

    if (!imageProfile.filename) {
        return `${name}`;
    }

    const extension = imageProfile.filename.substr(imageProfile.filename.lastIndexOf('.'));

    if (!imageProfile.crop) {
        return `${name}${extension}`;
    }

    const cropSuffix = `(${imageProfile.crop.x},${imageProfile.crop.y},${imageProfile.crop.width},${imageProfile.crop.height})`;
    return `${name}_${cropSuffix}${extension}`;
};

export const blobToArrayBuffer = (blob: Blob): Promise<{ data: ArrayBuffer; type: string }> =>

    new Promise((resolve, reject) => {

        const fileReader = new FileReader();

        fileReader.onabort = fileReader.onerror = reject;

        fileReader.onload = e => {
            resolve({ data: (e.target as FileReader).result as ArrayBuffer, type: blob.type });
        };

        fileReader.readAsArrayBuffer(blob);
    });

/**
 * Map subProgress to a new progress within the fromPercentage and toPercentage range
 */
export const getStepDones = (name: string, fromPercentage: number, toPercentage: number, subProgress: Progress = { total: 1, done: 1 }): Progress => {

    const total = 1000;
    const allocatedPercentage = toPercentage - fromPercentage;
    const stepAvailables = Math.floor(total / 100 * allocatedPercentage);
    const stepDones = Math.floor(stepAvailables * (subProgress.done / subProgress.total));

    return {
        id: name,
        total,
        done: Math.min(((total / 100 * fromPercentage) + stepDones), total)
    };
};

/**
 * key used as key to in the tenantDb.Versions store
 * db used as project db name
 */
export const getProjectNames = (info: ContentInfo): { key: string; db: string } => {

    const key = `${info.projectId}-${info.name}`;

    return {
        key,
        db: `content-${info.tenant}-${key}`
    };
};

/**
 * @param from active content
 * @param to possible update content
 * @param isPreview application mode
 * @returns null no valid update, false diff update, true full update
 *
 *  Check logic:
 *  1   TO null => null
 *  2   TO is Preview and App is Stable (not compatible) => null
 *  3   FROM is null => (Full)
 *  4   App Stable and FROM is Preview (FROM not compatible) => (Full)
 *  5   Both are Stable and TO is major => (Diff)
 *  6   Both are Preview and TO is major => (Diff)
 *  7   Differents => (Full)
 *  *   => null
 */
export const checkUpdateType = (from: ContentInfo, to: ContentInfo, isPreview: boolean): boolean | null => {

    // Condition 1
    if (!to) {
        return null;
    }

    // Condition 2
    if (to.preview && !isPreview) {
        return null;
    }

    // Condition 3
    if (!from) {
        return true;
    }

    // Condition 4
    if (!isPreview && from.preview) {
        return true;
    }

    // Condition 5
    if ((!from.preview && !to.preview) && to.version > from.version) {
        return false;
    }

    // Condition 6
    if ((from.preview && to.preview) && (
        (to.version > from.version) || (to.version === from.version && to.preview > from.preview)
    )) {
        return false;
    }

    // Condition 7
    if ((from.preview && !to.preview) || (!from.preview && to.preview)) {

        const fromCompare = Object.assign(from);
        fromCompare.preview = fromCompare.preview || Number.MAX_VALUE;

        const toCompare = Object.assign(to);
        toCompare.preview = toCompare.preview || Number.MAX_VALUE;

        if ((toCompare.version > fromCompare.version) ||
            (toCompare.version === fromCompare.version && toCompare.preview > fromCompare.preview)) {
            return false;
        }
    }

    return null;
};
