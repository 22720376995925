import { DataPropertyDescriptor, FieldDisplayPipe, FormDefinitionMetadataIdentifiers } from '@unifii/library/common';
import { FormData, ParentFormData } from '@unifii/sdk';


export const getColumnRenderableValue = (
    formData: FormData,
    dataPropertyDescriptor: DataPropertyDescriptor,
    fieldDisplayPipe: FieldDisplayPipe
): any => {

    const fieldIdentifier = dataPropertyDescriptor.identifier;
    let value: any;

    if (fieldIdentifier.startsWith(FormDefinitionMetadataIdentifiers.Parent) && formData._parent) {
        value = formData._parent[fieldIdentifier.substring(FormDefinitionMetadataIdentifiers.Parent.length) as keyof ParentFormData];
    } else {
        value = formData[fieldIdentifier];
    }

    return fieldDisplayPipe.transform(value, dataPropertyDescriptor.type, dataPropertyDescriptor.options);
};
