import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

import { IFrameComponent } from 'shell/content/iframe.component';

import { UserDetailsComponent } from 'discover/user-management/user-details.component';

import { ContentNodeComponent } from './content/content-node.component';
import { FormComponent } from './form/form.component';
import { TableDetailComponent } from './table-detail/table-detail.component';


@Injectable()
export class ShellRouteReuseStrategy extends RouteReuseStrategy {


    shouldDetach(_route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(_route: ActivatedRouteSnapshot, _handle: DetachedRouteHandle): void {
    }

    shouldAttach(_route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    shouldReuseRoute(active: ActivatedRouteSnapshot, next: ActivatedRouteSnapshot): boolean {

        switch (next.component) {
            case IFrameComponent:
                return false;
            case FormComponent:
                return false;
            case UserDetailsComponent:
                return false;
            case TableDetailComponent:
                if (active.params.id && next.params.id) {
                    return active.params.id === next.params.id;
                }
                return false;
            case ContentNodeComponent: {
                /**
                 * Can reuse if identifiers match, unless they are direct links
                 * such as page and form links
                 */
                if (
                    active.params.identifier &&
                    next.params.identifier &&
                    active.params.identifier === next.params.identifier) {
                    return true;
                }
                return false;
            }
            default: return active.routeConfig === next.routeConfig;
        }
    }

}
