import { merge, Observable, Subject, timer } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DescriptionListItem, ModalService, SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';
import { Progress, ProjectInfo, UserInfo } from '@unifii/sdk';

import { Mode, ShellService } from 'shell/core/shell.service';
import { Features } from 'shell/features';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { OfflineManager } from 'shell/offline/offline-manager';
import { ContentInfo } from 'shell/offline/offline-model';
import { Authentication } from 'shell/services/authentication';
import { TranslationsService } from 'shell/services/translations.service';
import { ShellTranslationKey } from 'shell/shell.tk';
import { ChangeLanguageComponent, ChangeLanguageModalData } from 'shell/translations/change-language.component';

import { UserProfilePath } from 'discover/discover-constants';
import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { UpdateProgressComponent, UpdateProgressData } from 'discover/offline/update-progress.component';
import { SettingsConfig } from 'discover/settings/settings-options';

import { Config, Environment } from 'config';


@Component({
    selector: 'ud-settings',
    templateUrl: './settings.html',
    styleUrls: ['./settings.less']
})
export class DiscoverSettingsComponent implements OnInit {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly shellTK = ShellTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    preview: boolean;
    contentInfo: ContentInfo;
    versionInfo: DescriptionListItem[];
    updateAvailable: ContentInfo | null;
    previewAvailable: boolean;
    offlineSyncRequired: boolean;
    userProfilePath = UserProfilePath;

    private destroyed = new Subject<void>();

    constructor(
        public context: DiscoverContext,
        @Inject(Config) public config: Config,
        @Inject(Environment) private env: Config,
        @Inject(WindowWrapper) private window: Window,
        @Inject(Authentication) public auth: Authentication,
        public translations: TranslationsService,
        private modalService: ModalService,
        private shell: ShellService,
        private offlineManager: OfflineManager,
        private features: Features,
        private offlineQ: OfflineQueue,
        private translate: TranslateService,
        @Inject(SettingsConfig) @Optional() public settingsConfig: SettingsConfig
    ) {
        this.settingsConfig = this.settingsConfig || {};
        this.versionInfo = this.getVersionInfo();
    }

    get user(): UserInfo {
        return this.auth.userInfo as UserInfo;
    }

    get availableLanguages() {
        /**
         * Can't guarantee that project exists, if logout init is triggered project will be null
         */
        if (!this.context.project) {
            return [];
        }
        return this.translations.availableProjectLanguages(this.context.project as ProjectInfo);
    }

    get showChangeProject() {
        return this.settingsConfig.hideChangeProject !== true && this.auth.allowedProjects?.length > 1;
    }

    ngOnInit() {

        // Check content update immediatly and every 90sec
        timer(0, 90000).subscribe(() => this.checkForUpdate());

        if (this.config.unifii.offline) {
            try {
                this.offlineManager.getContentInfo().then(info => this.contentInfo = info);
            } catch (e) {
                console.log(`We're online! No offline version`);
            }
        }

        // An hard-coded preview is provided by the environment.ts, override Context one
        if (this.env.unifii.preview != null) {
            this.previewAvailable = false;
            this.preview = this.env.unifii.preview;
            this.shell.mode = this.env.unifii.preview ? Mode.Preview : Mode.Stable;
        } else {
            this.previewAvailable = this.auth.canAccessPreview;
            this.preview = this.context.preview;
            this.shell.mode = this.context.preview ? Mode.Preview : Mode.Stable;

            if (!this.previewAvailable && this.context.preview) {
                this.context.preview = false;
                this.reload();
            }
        }

        this.initOfflineSync();
        this.shell.toggleMode.subscribe(() => this.toggle());
    }

    /**
     * toggleMode  => request triggered from preview/stable checkbox
     * !toggleMode => request to update to next version available
     */
    async toggle() {

        try {
            // Confirm for switch mode
            const change = await this.modalService.openConfirm({
                title: this.translate.instant(DiscoverTranslationKey.SettingsModalChangeModeTitle),
                message: this.context.preview ?
                    this.translate.instant(DiscoverTranslationKey.SettingsModalChangeModeProductionMessage) :
                    this.translate.instant(DiscoverTranslationKey.SettingsModalChangeModePreviewMessage)
            });

            // User refused to switch
            if (!change) {
                return;
            }

            // Change mode
            this.context.preview = !this.context.preview;

            // For online application just restart
            if (!this.features.offline || !this.config.unifii.offline) {
                this.reload();
                return;
            }

            // Switch mode
            const next = await this.offlineManager.updateAvailable();

            if (!next) {
                // No version available, reload needed to show content and data in sync with the mode
                this.reload();
                return;
            }

            this.updateOfflineContent().subscribe({
                error: err => {
                    console.warn('Error updating content', err);
                    // Restore toggled mode
                    this.context.preview = !this.context.preview;
                    // Nothing else to do
                },
                complete: () => {
                    // Update completed
                    this.reload();
                }
            });

        } catch (e) {
            this.context.preview = !this.context.preview;
            this.modalService.openAlert({
                title: this.translate.instant(this.sharedTermsTK.ErrorUnknown),
                message: this.context.preview ?
                    this.translate.instant(DiscoverTranslationKey.SettingsModalChangeProductionFailMessage) :
                    this.translate.instant(DiscoverTranslationKey.SettingsModalChangePreviewFailMessage)
            });
        }
    }

    async update() {
        const next = await this.offlineManager.updateAvailable();

        if (!next) {
            console.warn('SettingsComponent: Update, no available version!');
            return;
        }

        const proceed = await this.modalService.openConfirm({
            title: this.translate.instant(DiscoverTranslationKey.SettingsModalUpdateVersionTitle),
            message: this.translate.instant(DiscoverTranslationKey.SettingsModalUpdateVersionMessage, { version: next.name })
        });

        if (proceed) {
            this.updateOfflineContent().subscribe({
                error: err => {
                    console.warn('Error updating content', err);
                },
                complete: () => {
                    // Update completed
                    this.reload();
                }
            });
        }
    }

    error() {
        const test: any = {};
        console.log(test.test.test);
    }

    reload() {
        this.window.location.reload();
    }

    close() {
        this.shell.closeRightDrawer();
    }

    showChangeLanguage() {

        const data: ChangeLanguageModalData = {
            languages: this.availableLanguages,
            current: this.translations.currentLanguage
        };

        this.modalService.openFit<ChangeLanguageModalData, boolean>(ChangeLanguageComponent, data);
    }

    private checkForUpdate() {

        if (this.features.offline && this.config.unifii.offline) {

            this.offlineManager.updateAvailable().then(info => {

                this.updateAvailable = info;

                if (info != null) {
                    this.shell.notify('OfflineContent');
                } else {
                    this.shell.done('OfflineContent');
                }
            });
        }
    }

    /** Update offline content to the available version, show the progress dialog */
    private updateOfflineContent(): Observable<Progress> {
        // Update content
        const updateObs = this.offlineManager.updateContent().pipe(share());

        this.modalService.openFit<UpdateProgressData, boolean>(
            UpdateProgressComponent,
            { progress: updateObs } as UpdateProgressData
        );

        return updateObs;
    }

    private initOfflineSync() {

        this.offlineQ.count().then(count => this.offlineSyncRequired = count > 0);

        const additions = this.offlineQ.additions.pipe(takeUntil(this.destroyed));
        const deletions = this.offlineQ.deletions.pipe(takeUntil(this.destroyed));

        merge(additions, deletions).subscribe(async () => {
            const count = await this.offlineQ.count();
            console.log('Update offline counts', count);
            this.offlineSyncRequired = count > 0;
        });
    }

    private getVersionInfo(): DescriptionListItem[] {

        const info = [{ term: this.translate.instant(DiscoverTranslationKey.VersionLabel), description: this.config.version }];

        if (this.config.productVersion != null) {
            info.push({ term: this.translate.instant(DiscoverTranslationKey.ProductVersionLabel), description: this.config.productVersion });
        }

        return info;
    }

}
