import { FilterEntry, FilterType, FilterValue, UfFilterQueryBuilder } from '@unifii/components';
import { UserInfoIdentifiers } from '@unifii/library/common';
import { HierarchyUnitInfo, Q, Query, UserStatus } from '@unifii/sdk';


export class UsersFilterQueryBuilder extends UfFilterQueryBuilder {

    constructor() {
        super();
    }

    createQuery(value: FilterValue, entry: FilterEntry): Query | null {

        if (entry.identifier === UserInfoIdentifiers.Units) {
            const id = (value as HierarchyUnitInfo)?.id;
            if (id == null) {
                return null;
            }
            return new Query().in(entry.identifier, [id]);
        }

        if (entry.identifier === 'status') {
            return this.getStatusQuery(value as UserStatus);
        }
        /**
         * Bool claims are stored as strings on the backend,
         * so require a query without type bool
         */
        if (
            entry.identifier.includes('claims.') &&
            entry.type === FilterType.Choice &&
            (value === 'true' || value === 'false')
        ) {
            return new Query().eq(entry.identifier, value);
        }

        if (entry.identifier === UserInfoIdentifiers.Company) {
            return super.createQuery(value, {
                identifier: `${UserInfoIdentifiers.Company}.id`,
                type: entry.type,
                loader: entry.loader
            });
        }

        return super.createQuery(value, entry);
    }

    private getStatusQuery(value: UserStatus): Query | null {
        const query = new Query();

        switch (value) {
            case UserStatus.Active:
                return query.and(Q.eq('isActive', true));
            case UserStatus.Pending:
                query.and(Q.eq('hasPassword', false));
                query.and(Q.eq('isActive', false));
                query.and(Q.eq('isExternal', false));
                return query;
            case UserStatus.Inactive:
                query.and(Q.eq('hasPassword', true));
                query.and(Q.eq('isExternal', false));
                query.and(Q.eq('isActive', false));
                return query;
            default: return null;
        }
    }

}
