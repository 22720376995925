import { format, isAfter, set } from 'date-fns';

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContextProvider } from '@unifii/library/common';
import { FormData, Query, SortDirection } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { BetterFormService } from 'shell/form/better-form.service';

import { BucketIdentifier } from 'discover/components/sign-in/sign-in-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';


interface HistoryItem {
    signIn: string;
    month: string;
    signOut?: string;
    location?: string;
    id: string;
    state: 'SignedOut' | 'SignedIn';
    multiday: boolean;
}


@Component({
    templateUrl: './sign-in-history.html',
    providers: [BetterFormService],
    styleUrls: ['./sign-in-history.less']
})
export class SignInHistoryComponent implements OnInit {

    readonly discoverTK = DiscoverTranslationKey;
    readonly bucket = BucketIdentifier;

    history: HistoryItem[] = [];
    activeSignIn?: FormData;
    error?: AppError;
    busy?: boolean;

    private location?: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formService: BetterFormService,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        private errorService: ErrorService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.location = this.route.snapshot.params.location;
        this.update();
    }

    async update(): Promise<void> {
        try {
            this.busy = true;

            const { user } = this.contextProvider.get();

            if (!user) {
                throw new Error();
            }

            const query = new Query()
                .eq('_createdBy', user.username)
                .sort('_createdAt', SortDirection.Descending)
                .limit(30);
            this.formService.bucket = BucketIdentifier;

            const forms = await this.formService.query(query);
            this.history = forms.map(f => this.signInMapper(f));

            this.activeSignIn = this.history.find(data => data.state !== 'SignedOut');

            if (this.location && !this.activeSignIn) {
                this.router.navigate(['new', { location: this.location }], { relativeTo: this.route });
            }

        } catch (e) {
            this.error = this.errorService.mergeError(e, this.translate.instant(this.discoverTK.SignInErrorLoadingHistory));
        } finally {
            this.busy = false;
        }
    }

    signIn() {
        const params: { location?: string } = {};
        if (this.location) {
            params.location = this.location;
        }
        this.router.navigate(['new', params], { relativeTo: this.route });
    }

    private signInMapper({ location, signIn, signOut, id, _state }: FormData): HistoryItem {

        return {
            location: location?._display,
            month: format(new Date(signIn as string), 'MMMM'),
            signIn,
            signOut,
            id: id as string,
            state: _state as 'SignedIn' | 'SignedOut',
            multiday: this.isMultiDay(signIn, signOut)
        };
    }

    private isMultiDay(signIn: string, signOut?: string): boolean {

        if (!signOut) {
            return false;
        }

        const signInDate = set(new Date(signIn), { minutes: 0, hours: 0, seconds: 0 });
        const signOutDate = set(new Date(signOut), { minutes: 0, hours: 0, seconds: 0 });

        return isAfter(signOutDate, signInDate);
    }


}
