import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContextProvider, ExpressionParser } from '@unifii/library/common';
import { Client, Dictionary, StructureNode, StructureNodeArg, TokenStorage } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { NavigationService } from 'shell/nav/navigation.service';


@Component({
    templateUrl: './iframe.html',
    styleUrls: ['./iframe.less']
})
export class IFrameComponent implements OnInit {

    error: AppError;
    url: SafeResourceUrl | null;

    constructor(
        private navService: NavigationService,
        private sanitizer: DomSanitizer,
        private expressionParser: ExpressionParser,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        @Inject(TokenStorage) private tokenStorage: TokenStorage,
        private client: Client,
        private errorService: ErrorService
    ) { }

    async ngOnInit() {

        try {
            const node = this.navService.current as StructureNode;
            this.url = await this.createUrl(node);
        } catch (error) {
            this.error = error;
        }
    }

    private async createUrl(node: StructureNode): Promise<SafeResourceUrl | null> {

        let url = node.url;

        const { featureId, integrationId, ticketArgs, args } = node;
        const params = this.createQueryParams(args);
        const error = this.errorService.createNotFoundError('URL');

        if (featureId && integrationId) {
            try {
                const ticketParams = this.createQueryParams(ticketArgs);
                const response = await this.client.getTicket(integrationId, featureId, ticketParams);
                url = (url || '').replace('{{$ticket}}', response.ticket);
            } catch (er) {
                console.warn('Error', er);
                throw this.errorService.mergeError(er, er.message);
            }
        }

        if (!url) {
            throw error;
        }

        if (params != null) {
            url += '?' + this.stringfyParams(params);
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    private createQueryParams(args: StructureNodeArg[] = []): Record<string, any> {

        return args.reduce((result, arg) => {

            let { value } = arg;

            if (arg.isExpression) {
                value = this.parseExpression(value);
            }

            if (value !== undefined) {
                result[arg.key] = value;
            }

            return result;
        }, {} as Dictionary<string>);
    }

    private stringfyParams(params: Record<string, any> = {}): string {

        const searchParams = new URLSearchParams(params as any);
        return searchParams.toString();
    }

    private parseExpression(value: string): string | undefined {
        /**
         * Convert expressions to values with app context
         */
        const context = this.contextProvider.get();
        // Access token added to context
        context.accessToken = this.tokenStorage.token;

        return this.expressionParser.resolve(value, context as any);
    }

}
