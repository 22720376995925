import { Inject, Injectable } from '@angular/core';
import {
    claimReducer, DataPropertyDescriptor, ExpressionParser, FieldDisplayPipe, HierarchyFunctions, TableConfigColumn, UserInfoIdentifiers,
    WindowWrapper
} from '@unifii/library/common';
import { ColumnDescriptor, FieldType, Option, ProvisioningFunctions, TableSourceType, UserInfo } from '@unifii/sdk';
import { UserInfoKey } from '@unifii/user-provisioning';

import { MobileScreenWidth } from 'shell/shell-constants';


/**
 * @description
 * TableManagerUtils is a colleciton
 * used by TableManager classes
 */
@Injectable({
    providedIn: 'root'
})
export class TableColumnFactory<T> {

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private displayPipe: FieldDisplayPipe,
        private expressionParser: ExpressionParser
    ) {
    }

    create(
        columnDescriptors: ColumnDescriptor[],
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        sourceType: TableSourceType
    ): TableConfigColumn<T>[] {
        return columnDescriptors.map(column => {

            const config: TableConfigColumn<T> = {
                name: column.identifier,
                hidden: this.isMobileScreen ? column.hideOnMobile : column.hideOnDesktop
            };

            if (column.heading != null) {
                config.label = column.heading;
                config.sortable = false;
                return config;
            }

            const dataDescriptor = propertyDescriptors.get(column.identifier);
            if (dataDescriptor == null || !dataDescriptor.asDisplay) {
                return;
            }

            config.label = dataDescriptor?.label;
            config.sortable = dataDescriptor?.asSort === true;
            config.value = (item: T) => this.getCellValue(item, column.identifier, dataDescriptor.type, sourceType, dataDescriptor.options);

            return config;
        }).filter(config => config != null) as TableConfigColumn<T>[];
    }

    private getCellValue(
        data: T,
        identifier: string,
        type: FieldType,
        sourceType: TableSourceType,
        options?: Option[],
    ): string | null | undefined {

        if ((sourceType === TableSourceType.Company || sourceType === TableSourceType.Users) && identifier.startsWith('claims.')) {
            const claimValues = claimReducer((data as any)?.claims);
            const claimType = identifier.replace('claims.', '');
            return this.displayPipe.transform(claimValues[claimType], type, options);
        }

        if (sourceType === TableSourceType.Users) {
            return this.getUserInfoCellValue(data as any as UserInfo, identifier, type, options);
        }

        let value: any = data[identifier as keyof T];

        if (sourceType === TableSourceType.Bucket && this.expressionParser != null) {
            value = this.expressionParser.resolve(identifier, { self: null, root: data }, data, `BucketTable: failed to parse ${identifier}`);
        }

        return this.displayPipe.transform(value, type, options);
    }

    private getUserInfoCellValue(userInfo: UserInfo, identifier: string, type: FieldType, options?: Option[]): string | null | undefined {
        if (identifier === UserInfoIdentifiers.CompanyName) {
            return userInfo.company?.name;
        }

        if (identifier === UserInfoIdentifiers.CompanyId) {
            return userInfo.company?.id;
        }

        if (identifier === 'status') {
            return ProvisioningFunctions.getUserStatus(userInfo);
        }

        if (identifier === UserInfoIdentifiers.UnitPaths) {
            const units = userInfo?.unitPaths ?? [] as { id: string; label: string }[][];
            return (units).map(unitPath => HierarchyFunctions.pathToDisplay(unitPath)).join(', ');
        }

        let value = (userInfo as any)[identifier];

        if (identifier === UserInfoKey.IsActive) {
            value = ProvisioningFunctions.getUserStatus(userInfo);
        }

        return this.displayPipe.transform(value, type, options);
    }

    private get isMobileScreen(): boolean {
        return this.window.innerWidth <= MobileScreenWidth;
    }

}
