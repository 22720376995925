import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    ContextProvider, GoogleLocationProvider, GoogleWindow, LocationProvider, ModalService, SharedTermsTranslationKey, ThemeProvider, ThemeService,
    UfLocationProvider, WindowWrapper
} from '@unifii/library/common';
import { Client, RouteInfo, Theme } from '@unifii/sdk';

import { ShellService } from 'shell/core/shell.service';
import { InitStep } from 'shell/init-step';
import { NavigationService } from 'shell/nav/navigation.service';

import { ExternalPath, FormPath } from 'discover/discover-constants';

import { DeviceService } from 'capacitor/device.service';
import { PushNotificationService } from 'capacitor/push-notification.service';
import { VibrateService } from 'capacitor/vibrate.service';

import { Config } from 'config';


const createMapProvider = (
    config: Config,
    window: GoogleWindow,
    translateService: TranslateService,
    contextProvider: ContextProvider
): LocationProvider => {

    if (config.unifii && config.unifii.tenantSettings && config.unifii.tenantSettings.googleMapsApiKey) {
        return new GoogleLocationProvider(translateService, window, config.unifii.tenantSettings.googleMapsApiKey, contextProvider);
    }
    return new UfLocationProvider(translateService, window);
};


@Component({
    selector: 'us-app',
    templateUrl: './app.html',
    styles: [`:host { background: white; }`],
    providers: [
        {
            provide: LocationProvider, useFactory: createMapProvider,
            deps: [Config, WindowWrapper, TranslateService, ContextProvider]
        },
    ]
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router,
        private client: Client,
        private shell: ShellService,
        private nav: NavigationService,
        private deviceService: DeviceService,
        private notificationService: PushNotificationService,
        private translate: TranslateService,
        private modalService: ModalService,
        private vibrateService: VibrateService,
        @Optional() @Inject(InitStep) private initSteps: InitStep[],
        @Inject(Config) private config: Config,
        @Inject(ThemeProvider) private themeService: ThemeService
    ) {
        this.initTheme(this.config.themeConfig?.cssVariables ?? this.config?.theme, this.config.unifii?.productBackground);

        this.nav.init();
    }

    ngOnInit() {
        this.client.start = () => this.shell.busy = true;
        this.client.end = () => this.shell.busy = false;

        // init notifications
        this.notificationService.notifications.subscribe(n => {

            // Route info
            const routeCommands = this.nav.routeInfoToCommands(n.route);
            const matchTenant = routeCommands && this.config.unifii.tenant === (n.route as RouteInfo).tenant;
            const matchProject = routeCommands && this.config.unifii.projectId === (n.route as RouteInfo).projectId;
            const sameRoute = routeCommands && this.nav.getCommandsFromSnapshot(this.router.routerState.snapshot).join('/') === routeCommands.join('/');

            // Can not redirect, routeInfo is missing or invalid or match current position
            if (!routeCommands || (matchTenant && matchProject && sameRoute)) {
                // show notification as toast if not action
                if (!n.action) {
                    this.vibrateService.vibrate(500);
                    this.modalService.openAlert({
                        title: n.title,
                        message: n.message
                    });
                }
                // App lunched by this notification, nothing to do
                return;
            }

            // Redirect
            let userConsent = Promise.resolve(true);
            if (!n.action) {
                // ask user for redirect consent
                // TODO Improve user information for next steps
                this.vibrateService.vibrate(500);
                userConsent = this.modalService.openConfirm({
                    title: n.title,
                    message: n.message,
                    confirmLabel: this.translate.instant(SharedTermsTranslationKey.ActionView),
                    cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel)
                });
            }

            userConsent.then(result => {
                if (result && n.route) {
                    const { tenant, projectId, nodeId, bucket, id } = n.route;
                    const redirectCommands: any[] = [ExternalPath,tenant, projectId];

                    if (nodeId) {
                        redirectCommands.push('n', nodeId);
                    } else if (bucket) {
                        const params: { bucket: string; id?: string} = { bucket };
                        if (id) {
                            params.id = id;
                        }
                        redirectCommands.push(FormPath, params);
                    }
                    this.router.navigate(redirectCommands);
                }
            });
        });

        // setup push notifications
        this.notificationService.init().then(token => {

            if (!token) {
                console.warn('NotificationService no token found!');
                return;
            }

            this.deviceService.notificationToken = token;

            this.deviceService.device().then(device => {

                // guard if we have no tenant or device
                // we will retry again after we have a tenant
                if (!this.config.unifii.tenant || !device) {
                    return;
                }

                try {
                    this.client.registerDevice(device);
                } catch (error) {
                    // Fail silently
                }
            });

        });

        if (this.initSteps == null) {
            return;
        }

        for (const step of this.initSteps) {
            step.run();
        }

        // mobile deeplinks
        this.deviceService.initDeepLinks();

        // splashscreen
        this.deviceService.hideSplashScreen();

    }

    private initTheme(theme?: Theme, productBackground?: string) {
        if (theme) {
            this.themeService.theme = theme;
        }

        if (productBackground) {
            this.themeService.setStyle('ui-main-background-image', `url("${productBackground}")`);
        }
    }

}
