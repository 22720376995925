import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Error, ErrorType, PublishedContent, Structure, StructureNodeType } from '@unifii/sdk';

import { NavigationService } from 'shell/nav/navigation.service';
import { Authentication } from 'shell/services/authentication';


@Injectable()
export class StructureGuard implements CanActivate {

    constructor(
        @Inject(PublishedContent) private content: PublishedContent,
        @Inject(Authentication) private authentication: Authentication,
        private nav: NavigationService,
    ) { }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {

        const emptyStructure: Structure = { rev: 0, nodeId: '0', lastNodeId: '0', children: [], type: StructureNodeType.Empty };

        try {
            // Override missing Structure with a default empty one
            const s: Structure = await this.content.getStructure() || emptyStructure;

            // Store Structure
            this.nav.structure = s;

            // Succees
            return true;

        } catch (e) {

            // todo: remove when this is handled in the client
            if (e.type === ErrorType.NotFound) {
                this.nav.structure = emptyStructure;
                return true;
            }

            this.authentication.logout({ error: this.getErrorMessage(e) });
            return false;
        }
    }

    private getErrorMessage(error: Error): string | undefined {

        if (error.type === ErrorType.Unauthorized || !error.message) {
            return;
        }
        return error.message;
    }

}
