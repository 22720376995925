import { Inject, Injectable } from '@angular/core';
import { FilterEntry, FilterEntryFactory, FilterLoader, FilterType } from '@unifii/components';
import { DataPropertyDescriptor, FormDefinitionMetadataIdentifiers, HierarchyUnitProvider, UserInfoIdentifiers } from '@unifii/library/common';
import { DataLoaderFactory, DataSourceLoader } from '@unifii/library/smart-forms';
import { FieldType, Provisioning, TableSourceType, UserFilterOptions } from '@unifii/sdk';

import { UsersUsernameLoader } from 'shell/table/users-username-loader';


@Injectable()
export class TableFilterEntryFactory {

    constructor(
        private filterEntryFactory: FilterEntryFactory,
        private dataLoaderFactory: DataLoaderFactory,
        private provisioning: Provisioning,
        @Inject(HierarchyUnitProvider) private unitProvider: HierarchyUnitProvider
    ) { }

    create(filter: UserFilterOptions, propertyDescriptors: Map<string, DataPropertyDescriptor>, source: TableSourceType): FilterEntry | null {

        if (source === TableSourceType.Bucket) {
            return this.createBucketFilter(filter, propertyDescriptors);
        }

        let descriptor = propertyDescriptors.get(filter.identifier);
        if (descriptor == null || !descriptor.asInputFilter) {
            return null;
        }

        descriptor = { ...descriptor };

        // Modify claims choice descriptors
        const claimsRegex = /^claims\.*./;
        if (claimsRegex.test(descriptor.identifier) && (source === TableSourceType.Company || source === TableSourceType.Users)) {
            if (descriptor.type === FieldType.Choice) {
                descriptor.type = FieldType.MultiChoice;
            } else if ([FieldType.Date, FieldType.DateTime].includes(descriptor.type)) {
                return this.createDateEntry(descriptor);
            }
        }

        let loader: DataSourceLoader | undefined;
        if (source === TableSourceType.Users) {
            if (UserInfoIdentifiers.Units === filter.identifier) {
                descriptor.type = FieldType.Hierarchy;
            }
            else if (UserInfoIdentifiers.Company === filter.identifier) {
                descriptor.type = FieldType.Lookup;
                loader = this.dataLoaderFactory.create(descriptor.dataSource) ?? undefined;
            }
        }

        try {
            return this.filterEntryFactory.create(descriptor, loader);
        } catch (e) {
            /**
             * the filter factory will throw an error if it encounters an incompatible type, its important to catch the errors
             * and fail silently so the table will still render. The factory will display an error in console
             */
            return null;
        }
    }

    private createBucketFilter(filter: UserFilterOptions, propertyDescriptors: Map<string, DataPropertyDescriptor>): FilterEntry | null {
        let descriptor = this.getDataDescriptor(filter.identifier, propertyDescriptors);
        if (descriptor == null || !descriptor.asInputFilter) {
            return null;
        }

        descriptor = { ...descriptor };

        const loader: FilterLoader | null = this.createFilterLoader(descriptor, filter.roles);

        // Modify filter identifer to include ._id for fields with loaders
        if (
            descriptor.type === FieldType.Choice &&
            loader != null &&
            !descriptor.identifier.endsWith('._id')
        ) {
            descriptor.type = FieldType.Lookup; // Force choice's to type lookup
        }

        // Switch descriptor to multichoice so it will create the correct filter type
        if (descriptor.type === FieldType.Choice) {
            descriptor.type = FieldType.MultiChoice;
        }

        // Change fields to lookup that have a loader. eg: _createdBy, ...
        if (descriptor.type === FieldType.Text && loader != null) {
            descriptor.type = FieldType.Lookup;
        }

        return this.filterEntryFactory.create(descriptor, loader ?? undefined);
    }

    private createDateEntry({ type, identifier, label }: DataPropertyDescriptor): FilterEntry {
        return {
            identifier, label,
            type: type === FieldType.Date ? FilterType.Date : FilterType.Datetime
        };
    }

    // DataDescriptors will need to be modified to suit the correct FilterType
    private getDataDescriptor(identifier: string, propertyDescriptors: Map<string, DataPropertyDescriptor>): DataPropertyDescriptor | undefined {
        /**
         * Check for identitiers that match [identifier]._id
         * for the filter entry to work the data descriptor of the parent lookup field needs to be returned
         */
        const dataSourceRegex = /._id$/;
        if (dataSourceRegex.test(identifier)) {
            const dataSourceIdentifier = identifier.replace(dataSourceRegex, '');
            const dataSourceDescriptor = propertyDescriptors.get(dataSourceIdentifier);
            if (dataSourceDescriptor == null) {
                return;
            }
            // return descriptor for data source field with identifier that includes ._id eg: [identifier]._id
            return dataSourceDescriptor;
        }

        return propertyDescriptors.get(identifier);
    }

    private createFilterLoader({ identifier, type, dataSource }: DataPropertyDescriptor, roles?: string[]): FilterLoader | null {
        if (type === FieldType.Hierarchy) {
            return this.unitProvider;
        }
        // createdBy and lastModifiedBy are username values so require a the loader to use usernames exclusively
        if ([FormDefinitionMetadataIdentifiers.CreatedBy, FormDefinitionMetadataIdentifiers.LastModifiedBy].includes(identifier as FormDefinitionMetadataIdentifiers)) {
            return new UsersUsernameLoader(this.provisioning, roles);
        }
        return this.dataLoaderFactory.create(dataSource);
    }

}


