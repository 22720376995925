import { Inject, Injectable, ViewContainerRef } from '@angular/core';
import { ContentType, StructureNodeType } from '@unifii/sdk';

import { ContentComponentFactory, ContentFactoryArgs } from 'shell/content/content-component-factory';
import { ContentComponentSelector } from 'shell/content/content-component-selector';
import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { ShellContentComponentFactory } from 'shell/content/shell-content-component-factory';

import { DirectoryTemplateComponent } from 'discover/components/directory-template/directory-template.component';

import { ReportPageComponent } from './reports/report-page.component';


@Injectable()
export class DiscoverContentComponentFactory implements ContentComponentFactory {

    shellFactory: ShellContentComponentFactory;

    constructor(
        @Inject(ContentDataResolver) private dataResolver: ContentDataResolver,
        @Inject(ContentComponentSelector) public contentComponentSelector: ContentComponentSelector,
    ) {
        this.shellFactory = new ShellContentComponentFactory();
    }

    async create(
        container: ViewContainerRef,
        type: ContentType | StructureNodeType,
        { identifier, id, tags, bucket, hasRollingVersion }: ContentFactoryArgs,
    ): Promise<any> {

        if (identifier === 'directory-template' || (identifier && (tags || []).includes('directory'))) {
            const { compound } = await this.dataResolver.getView(identifier);
            const { instance } = container.createComponent(DirectoryTemplateComponent, { index: 0, injector: container.injector });
            instance.compound = compound;
            return instance;
        }

        if (identifier === 'custom-report' || (identifier && (tags || []).includes('custom-report'))) {
            const { instance } = container.createComponent(ReportPageComponent, { index: 0, injector: container.injector });
            return instance;
        }

        return this.shellFactory.create(container, type, { identifier, id, bucket, tags, hasRollingVersion });
    }


}
