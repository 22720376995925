import { Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProgressComponent, ThemeProvider, ThemeService, WindowWrapper } from '@unifii/library/common';
import { StructureNode, Theme } from '@unifii/sdk';

import { ShellService } from 'shell/core/shell.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { OfflineManager } from 'shell/offline/offline-manager';
import { ComponentTitleRouteData } from 'shell/shell-model';

import { DiscoverContext } from 'discover/discover-context';
import { PinService } from 'discover/pin/pin-types';

import { Config } from 'config';

import { AppUpdateService } from './app-update/app-update.service';


@Component({
    selector: 'ud-main',
    templateUrl: './main.html'
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(ProgressComponent) private progressBar: ProgressComponent;

    imgUrl: string;
    navigationTrigger: 'imperative' | 'popstate' | 'hashchange' | undefined;

    private destroyed = new Subject<void>();
    private subscriptions: Subscription[] = [];

    constructor(
        private title: Title,
        @Inject(Config) private config: Config,
        private nav: NavigationService,
        private context: DiscoverContext,
        public shell: ShellService,
        private offlineQ: OfflineQueue,
        private offlineManager: OfflineManager,
        @Inject(WindowWrapper) private window: Window,
        private router: Router,
        @Inject(PinService) private pinService: PinService,
        private appUpdate: AppUpdateService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        @Inject(ThemeProvider) private themeService: ThemeService
    ) { }

    ngOnInit() {

        if (this.theme && !this.config.themeConfig?.disableProjectTheme) {
            this.themeService.theme = this.theme;
        }

        if (this.config.unifii.projectLogoUrl) {
            this.imgUrl = this.config.unifii.projectLogoUrl;
        }

        this.pinService.init();
        this.appUpdate.init();
        this.initTitles();
        this.initOfflineQ();

        // clean up offline content (background async run)
        this.offlineManager.cleanUp();

        // reset offline content notifications
        this.shell.reset('OfflineContent');

        // Update app progress
        this.subscriptions.push(this.shell.busyEvents.subscribe(e => {

            if (this.progressBar != null && e) {
                this.progressBar.start();
            } else if (this.progressBar != null) {
                this.progressBar.complete();
            }
        }));
    }

    ngAfterViewInit(): void {

        /** Guard incase for angular universal */
        if (!this.window.document) {
            return;
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();

        this.themeService.theme = this.config?.themeConfig?.cssVariables ?? this.config?.theme ?? {};

        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }

        this.pinService.reset();

        this.setTitles(); // Logout from project, reset Titlte
    }

    /** Look up for navigation Title by following priority list:
     * 1. The StructureNode.name
     * 2. The Route data
     */
    private initTitles(): void {

        this.setTitles(this.nav.current as StructureNode);

        this.subscriptions.push(this.router.events.pipe(
            tap(event => {
                if (event instanceof NavigationStart) {
                    this.navigationTrigger = event.navigationTrigger;
                }
            }),
            filter(event => event instanceof NavigationEnd),
            map(() => this.nav.getNodeFromSnapshot(this.router.routerState.snapshot)))
            .subscribe(node => this.setTitles(node as StructureNode)));
    }

    private setTitles(node?: StructureNode) {

        let title: string | undefined;

        if (node && node.nodeId) {
            title = node.name;
        }

        if (!title) {
            let child = this.route.firstChild;

            while (child) {
                // Store title
                if (child.snapshot.data && (child.snapshot.data).titleTranslationKey as ComponentTitleRouteData) {
                    title = this.translate.instant(
                        (child.snapshot.data as ComponentTitleRouteData).titleTranslationKey as string,
                        (child.snapshot.data as ComponentTitleRouteData).titleParams
                    );
                }
                // Look for deeper route title (more specific)
                if (child.firstChild) {
                    child = child.firstChild;
                } else {
                    child = null;
                }
            }
        }

        if (!title) {
            title = this.context.project ? this.context.project.name : 'Unifii';
        }

        const prefix = this.config.env === 'prod' ? '' : this.config.env.toUpperCase() + ': ';

        this.shell.setTitle(title);
        this.title.setTitle(prefix + title);
    }

    private initOfflineQ() {

        this.offlineQ.additions.pipe(takeUntil(this.destroyed))
            .subscribe(() => this.shell.notify('OfflineQ'));

        this.offlineQ.deletions.pipe(takeUntil(this.destroyed))
            .subscribe(() => this.shell.done('OfflineQ'));

        this.offlineQ.count().then(count => this.shell.reset('OfflineQ', count));
        this.offlineQ.prune();
    }

    private get theme(): Theme | undefined {

        if (this.context.project) {
            return this.context.project.theme;
        }
        return;
    }

}
