import { InjectionToken, ViewContainerRef } from '@angular/core';
import { ContentType, StructureNodeType } from '@unifii/sdk';

import { DiscoverContentType } from 'shell/content/content-types';


export interface ContentFactoryArgs {
    identifier?: string;
    id?: string | number;
    bucket?: string;
    hasRollingVersion?: boolean;
    tags?: string[];
};

export interface ContentComponentFactory {
    create: <T>(container: ViewContainerRef, type: ContentType | StructureNodeType | DiscoverContentType, args: ContentFactoryArgs) => Promise<T>;
}

export const ContentComponentFactory = new InjectionToken<ContentComponentFactory>('ContentComponentFactory');
