import { DataSourceLoader } from '@unifii/library/smart-forms';
import { Compound, DataSeed, DataSource, Query } from '@unifii/sdk';

import { DiscoverContent } from 'discover/discover-content.service';

/**
 * This creates a simple collection datasource loader using parameters supplied in constructor.
 * Covers a common use case for filters with datasources
 */

export class CollectionOptionsLoader implements DataSourceLoader {

    dataSource: DataSource;

    // private options: Option[];
    private seeds: DataSeed[];

    constructor(
        private collectionId: string,
        private identifier: string,
        private name: string,
        private content: DiscoverContent,
    ) { }

    async getOptions(): Promise<DataSeed[]> {
        return [...this.seeds];
    }

    async search(query?: string): Promise<DataSeed[]> {
        await this.load(query);
        return this.seeds;
    }


    async get(id: string): Promise<DataSeed | null> {
        const collectionItem = await this.content.getCollectionItem(this.collectionId, id);
        return this.resultToSeed(collectionItem);
    }

    mapToSeed(compound?: Compound): DataSeed | null {
        if (!compound) {
            return null;
        }

        return this.resultToSeed(compound);
    }

    private async load(q?: string) {

        this.seeds = [];
        // this.options = [];

        const query = new Query();
        if (q && q.length) {
            query.q(q);
        }

        const results = await this.content.queryCollection(this.collectionId, query);

        this.seeds = results.map(result => this.resultToSeed(result));
        // this.options = results.map(result => this.resultToOption(result));
    }

    private resultToSeed(result: Compound) {
        return {
            _id: result[this.identifier],
            _display: result[this.name]
        };
    }
/*
    private resultToOption(result: Compound) {
        return {
            identifier: result[this.identifier],
            name: result[this.name]
        };
    }
*/
}